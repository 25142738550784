import axios from "axios";
import {System_URL, SystemService_URL, DataConnection_URL } from "../Url";
import HashMap from "hashmap";
import { clearLogoutData } from "../Logout/actions";
var map = new HashMap();

function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

// Save AssetType

export const SaveScanDataActions = {
  SAVE_SCAN_DATA: "SAVE_SCAN_DATA",
  SAVE_SCAN_DATA_STATUS: "SAVE_SCAN_DATA_STATUS",
  SAVE_SCAN_DATA_SUCCESS: "SAVE_SCAN_DATA_SUCCESS",
  SAVE_SCAN_DATA_FAILURE: "SAVE_SCAN_DATA_FAILURE",
  LOGOUT: "LOGOUT",
};
export function logout() {
  return {
    type: SaveScanDataActions.LOGOUT,
    payload: "Session Expired. Please Login Again",
  };
}
export function SaveScanDataRequest() {
  return {
    type: SaveScanDataActions.SAVE_SCAN_DATA,
    isPosting: true,
  };
}

export function SaveScanDataSuccess(props) {
  return (dispatch) => {
    dispatch({
      type:SaveScanDataActions.SAVE_SCAN_DATA_SUCCESS,
      payload: props,
    });
  };
}

export function SaveScanDataFailure(error) {
  return {
    type: SaveScanDataActions.SAVE_SCAN_DATA_FAILURE,
    payload: error,
  };
}

export function SaveScanDataError(error) {
  return {
    type: SaveScanDataActions.SAVE_SCAN_DATA_FAILURE,
    payload: error,
  };
}

export function saveScanData(props) {
  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${DataConnection_URL}/schedule/scan`,
      headers: {
        authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        dispatch(SaveScanDataRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveScanDataSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(SaveScanDataError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(SaveScanDataError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(SaveScanDataError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(SaveScanDataError(err));
              break;
            case 500:
              dispatch(SaveScanDataError("Server Error"));
              break;
            default:
              dispatch(SaveScanDataError("Unknown Error"));
              break;
          }
        } else {
          //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}


// get Data Element Details 

export const getDataElementDetailsActions = {
  FETCH_DATA_ELEMENT: "FETCH_DATA_ELEMENT",
  FETCH_DATA_ELEMENT_STATUS: "FETCH_DATA_ELEMENT_STATUS",
  FETCH_DATA_ELEMENT_FULFILLED: "FETCH_DATA_ELEMENT_FULFILLED",
  FETCH_DATA_ELEMENT_REJECTED: "FETCH_DATA_ELEMENT_REJECTED",
};
export function getDataElementDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      url: `${System_URL}/Apc_View/Amc_16/findDataElementBy/NameIn?name=${params.enteredName}&companyId=${params.companyId}`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getDataElementDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getDataElementDetailsSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getDataElementDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getDataElementDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getDataElementDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getDataElementDetailsError(err));
              break;
            case 500:
              dispatch(getDataElementDetailsError("Server Error"));
              break;
            default:
              dispatch(getDataElementDetailsError("Unknown Error"));
              break;
          }
        } else {
          //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getDataElementDetailsRequest() {
  return {
    type: getDataElementDetailsActions.FETCH_DATA_ELEMENT,
    isPosting: true,
  };
}
export function getDataElementDetailsError(error) {
  return {
    type: getDataElementDetailsActions.FETCH_DATA_ELEMENT_REJECTED,
    payload: error,
  };
}

export function getDataElementDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: getDataElementDetailsActions.FETCH_DATA_ELEMENT_FULFILLED,
      payload: props,
    });
  };
}

export function getDataElementDetailsStatus() {
  return (dispatch) => dispatch(getDataElementDetailsStatusRequest());
}
export function getDataElementDetailsStatusRequest() {
  return {
    type: getDataElementDetailsActions.FETCH_DATA_ELEMENT_STATUS,
  };
}

// get schema details ny id
export const getSchemaDetailsActions = {
  FETCH_SCHEMA_DETAILS: "FETCH_SCHEMA_DETAILS",
  FETCH_SCHEMA_DETAILS_STATUS: "FETCH_SCHEMA_DETAILS_STATUS",
  FETCH_SCHEMA_DETAILS_FULFILLED: "FETCH_SCHEMA_DETAILS_FULFILLED",
  FETCH_SCHEMA_DETAILS_REJECTED: "FETCH_SCHEMA_DETAILS_REJECTED",
};
export function getSchemaDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      url: `${DataConnection_URL}/get/listOfSchemaNames/ByNamesInAndRelationalDataCredentialsId?relationalDataCredentialsId=${params.credId}&schemaName=${params.enteredName}`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getSchemaDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getSchemaDetailsSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getSchemaDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getSchemaDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getSchemaDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getSchemaDetailsError(err));
              break;
            case 500:
              dispatch(getSchemaDetailsError("Server Error"));
              break;
            default:
              dispatch(getSchemaDetailsError("Unknown Error"));
              break;
          }
        } else {
          //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getSchemaDetailsRequest() {
  return {
    type: getSchemaDetailsActions.FETCH_SCHEMA_DETAILS,
    isPosting: true,
  };
}
export function getSchemaDetailsError(error) {
  return {
    type: getSchemaDetailsActions.FETCH_SCHEMA_DETAILS_REJECTED,
    payload: error,
  };
}

export function getSchemaDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: getSchemaDetailsActions.FETCH_SCHEMA_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getSchemaDetailsStatus() {
  return (dispatch) => dispatch(getSchemaDetailsStatusRequest());
}
export function getSchemaDetailsStatusRequest() {
  return {
    type: getSchemaDetailsActions.FETCH_SCHEMA_DETAILS_STATUS,
  };
}

// get table list details by id
export const getTableListDetailsActions = {
  FETCH_TABLE_LIST_DETAILS: "FETCH_TABLE_LIST_DETAILS",
  FETCH_TABLE_LIST_DETAILS_STATUS: "FETCH_TABLE_LIST_DETAILS_STATUS",
  FETCH_TABLE_LIST_DETAILS_FULFILLED: "FETCH_TABLE_LIST_DETAILS_FULFILLED",
  FETCH_TABLE_LIST_DETAILS_REJECTED: "FETCH_TABLE_LIST_DETAILS_REJECTED",
};
export function getTableListDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      url: `${DataConnection_URL}/get/listOfTableNames/BySchemaName/AndTableNameIn/AndRelationalDataCredentialsId?relationalDataCredentialsId=${params.credId}&schemaName=${params.schemaName}&tableName=${params.tableName}`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getTableListDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getTableListDetailsSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getTableListDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getTableListDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getTableListDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getTableListDetailsError(err));
              break;
            case 500:
              dispatch(getTableListDetailsError("Server Error"));
              break;
            default:
              dispatch(getTableListDetailsError("Unknown Error"));
              break;
          }
        } else {
          //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getTableListDetailsRequest() {
  return {
    type: getTableListDetailsActions.FETCH_TABLE_LIST_DETAILS,
    isPosting: true,
  };
}
export function getTableListDetailsError(error) {
  return {
    type: getTableListDetailsActions.FETCH_TABLE_LIST_DETAILS_REJECTED,
    payload: error,
  };
}

export function getTableListDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: getTableListDetailsActions.FETCH_TABLE_LIST_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getTableListDetailsStatus() {
  return (dispatch) => dispatch(getTableListDetailsStatusRequest());
}
export function getTableListDetailsStatusRequest() {
  return {
    type: getTableListDetailsActions.FETCH_TABLE_LIST_DETAILS_STATUS,
  };
}

// get table list details by id
export const getDBSystemActions = {
  FETCH_DB_SYSTEM_DETAILS: "FETCH_DB_SYSTEM_DETAILS",
  FETCH_DB_SYSTEM_DETAILS_STATUS: "FETCH_DB_SYSTEM_DETAILS_STATUS",
  FETCH_DB_SYSTEMT_DETAILS_FULFILLED: "FETCH_DB_SYSTEMT_DETAILS_FULFILLED",
  FETCH_DB_SYSTEM_DETAILS_REJECTED: "FETCH_DB_SYSTEM_DETAILS_REJECTED",
};
export function getDBSystemDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      url: `${DataConnection_URL}/get/DatabaseConnectionsListFromRelationalCredentials?databaseName=${params.DbName}`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getDBSystemRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getDBSystemSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getDBSystemError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getDBSystemError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getDBSystemError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getDBSystemError(err));
              break;
            case 500:
              dispatch(getDBSystemError("Server Error"));
              break;
            default:
              dispatch(getDBSystemError("Unknown Error"));
              break;
          }
        } else {
          //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getDBSystemRequest() {
  return {
    type: getDBSystemActions.FETCH_DB_SYSTEM_DETAILS,
    isPosting: true,
  };
}
export function getDBSystemError(error) {
  return {
    type: getDBSystemActions.FETCH_DB_SYSTEM_DETAILS_REJECTED,
    payload: error,
  };
}

export function getDBSystemSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: getDBSystemActions.FETCH_DB_SYSTEMT_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getDBSystemStatus() {
  return (dispatch) => dispatch(getDBSystemStatusRequest());
}
export function getDBSystemStatusRequest() {
  return {
    type: getDBSystemActions.FETCH_DB_SYSTEM_DETAILS_STATUS,
  };
}

// get DB connection  details by id
export const getDBConnectionActions = {
  FETCH_DB_CONNECTION_DETAILS: "FETCH_DB_CONNECTION_DETAILS",
  FETCH_DB_CONNECTION_DETAILS_STATUS: "FETCH_DB_CONNECTION_DETAILS_STATUS",
  FETCH_DB_CONNECTION_DETAILS_FULFILLED: "FETCH_DB_CONNECTION_DETAILS_FULFILLED",
  FETCH_DB_CONNECTION_DETAILS_REJECTED: "FETCH_DB_CONNECTION_DETAILS_REJECTED",
};
export function getDBConnectionDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      url: `${DataConnection_URL}/get/ConnectionsListFromRelationalCredentials?relationalId=${params.DbName}&connectioneName=${params.enteredName}`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getDBConnectionRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getDBConnectionSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getDBConnectionError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getDBConnectionError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getDBConnectionError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getDBConnectionError(err));
              break;
            case 500:
              dispatch(getDBConnectionError("Server Error"));
              break;
            default:
              dispatch(getDBConnectionError("Unknown Error"));
              break;
          }
        } else {
          //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getDBConnectionRequest() {
  return {
    type: getDBConnectionActions.FETCH_DB_CONNECTION_DETAILS,
    isPosting: true,
  };
}
export function getDBConnectionError(error) {
  return {
    type: getDBConnectionActions.FETCH_DB_CONNECTION_DETAILS_REJECTED,
    payload: error,
  };
}

export function getDBConnectionSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: getDBConnectionActions.FETCH_DB_CONNECTION_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getDBConnectionStatus() {
  return (dispatch) => dispatch(getDBConnectionStatusRequest());
}
export function getDBConnectionStatusRequest() {
  return {
    type: getDBConnectionActions.FETCH_DB_CONNECTION_DETAILS_STATUS,
  };
}

export const ClearFormDetailsActions = {
  CLEAR_FORM_DETAILS: "CLEAR_FORM_DETAILS",
};
export function clearFormDetails() {
  return {
    type: ClearFormDetailsActions.CLEAR_FORM_DETAILS,
  };
}