import {
  ClearFormDetailsActions,
  getDataElementDetailsActions,
  getSchemaDetailsActions,
  getTableListDetailsActions,
  SaveScanDataActions,
  getDBSystemActions,
  getDBConnectionActions
} from "./actions";

const initState = {
  dataElementList: null,
  dataElementErrorMessage: null,
  schemaDetailsList:null,
  tableListDetailsList:null,
  scanDataSuccessMessage: null,
  scanDataStatus: null,
  scanDataErrorMessage: null,
  DBSystemDetailsList:null,
  DBConnectionDetailsList:null
};

export default function DataDiscovery(state = initState, { type, payload }) {
  switch (type) {
    case SaveScanDataActions.SAVE_SCAN_DATA:
      return {
        ...state,
        isLoading: true,
        scanDataErrorMessage: null,
      };
    case SaveScanDataActions.SAVE_SCAN_DATA_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        scanDataStatus: payload.status,
        scanDataErrorMessage: null,
        scanDataSuccessMessage: "Scan Data Saved successfully!"
      };

    case SaveScanDataActions.SAVE_SCAN_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        scanDataStatus: null,
        scanDataErrorMessage: payload
      };
    case SaveScanDataActions.SAVE_SCAN_DATA_STATUS:
      return {
        ...state,
        scanDatalStatus: null,
        scanDataErrorMessage: null
      };

    ///////////////////////////////////////////////////////
    // GET get DataElement DetailsActions  BY name
    case getDataElementDetailsActions.FETCH_DATA_ELEMENT:
      return {
        ...state,
        isLoading: true,
        dataElementErrorMessage: null,
        modalActive: false,
        dataElementList: null
      };
    case getDataElementDetailsActions.FETCH_DATA_ELEMENT_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        dataElementList: payload.data,
        dataElementErrorMessage: null
      };
    case getDataElementDetailsActions.FETCH_DATA_ELEMENT_REJECTED:
      return {
        ...state,
        isLoading: false,
        dataElementErrorMessage: payload,
        dataElementList: null
      };

      case  getDataElementDetailsActions.FETCH_DATA_ELEMENT_STATUS:
      return {
        ...state,
        dataElementList: null
      };

      // GET get schema Details Actions
    case getSchemaDetailsActions.FETCH_SCHEMA_DETAILS:
      return {
        ...state,
        isLoading: true,
        dataElementErrorMessage: null,
        schemaDetailsList: null
      };
    case getSchemaDetailsActions.FETCH_SCHEMA_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        schemaDetailsList: payload.data,
        dataElementErrorMessage: null
      };
    case getSchemaDetailsActions.FETCH_SCHEMA_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        dataElementErrorMessage: payload,
        schemaDetailsList: null
      };

      case  getSchemaDetailsActions.FETCH_SCHEMA_DETAILS_STATUS:
      return {
        ...state,
        schemaDetailsList: null
      };

      // GET get table list  Details 
    case getTableListDetailsActions.FETCH_TABLE_LIST_DETAILS:
      return {
        ...state,
        isLoading: true,
        dataElementErrorMessage: null,
        tableListDetailsList: null
      };
    case getTableListDetailsActions.FETCH_TABLE_LIST_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        tableListDetailsList: payload.data,
        dataElementErrorMessage: null
      };
    case getTableListDetailsActions.FETCH_TABLE_LIST_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        dataElementErrorMessage: payload,
        tableListDetailsList: null
      };

      case  getTableListDetailsActions.FETCH_TABLE_LIST_DETAILS_STATUS:
      return {
        ...state,
        tableListDetailsList: null
      };
 // GET DB system  Details 
    case getDBSystemActions.FETCH_DB_SYSTEM_DETAILS:
      return {
        ...state,
        isLoading: true,
        dataElementErrorMessage: null,
        DBSystemDetailsList: null
      };
    case getDBSystemActions.FETCH_DB_SYSTEMT_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        DBSystemDetailsList: payload.data,
        dataElementErrorMessage: null
      };
    case getDBSystemActions.FETCH_DB_SYSTEM_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        dataElementErrorMessage: payload,
        DBSystemDetailsList: null
      };

      case  getDBSystemActions.FETCH_DB_SYSTEM_DETAILS_STATUS:
      return {
        ...state,
        DBSystemDetailsList: null
      };

      // GET DB connection  Details 
    case getDBConnectionActions.FETCH_DB_CONNECTION_DETAILS:
      return {
        ...state,
        isLoading: true,
        dataElementErrorMessage: null,
        DBConnectionDetailsList: null
      };
    case getDBConnectionActions.FETCH_DB_CONNECTION_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        DBConnectionDetailsList: payload.data,
        dataElementErrorMessage: null
      };
    case getDBConnectionActions.FETCH_DB_CONNECTION_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        dataElementErrorMessage: payload,
        DBConnectionDetailsList: null
      };

      case  getDBConnectionActions.FETCH_DB_CONNECTION_DETAILS_STATUS:
      return {
        ...state,
        DBConnectionDetailsList: null
      };
    // clear assetType details
    case ClearFormDetailsActions.CLEAR_FORM_DETAILS:
      return {
        ...state,
        dataElementList: null,
        dataElementErrorMessage: null,
        schemaDetailsList:null,
        tableListDetailsList:null,
        scanDataSuccessMessage: null,
        scanDataStatus: null,
        scanDataErrorMessage: null,
        DBSystemDetailsList:null,
        DBConnectionDetailsList:null
      };

    default:
      return state;
  }
}
