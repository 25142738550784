import axios from "axios";
import { RiskManagementService_URL, SurveyService_URL } from "../Url";
import HashMap from "hashmap";
import { clearLogoutData } from "../Logout/actions";

var map = new HashMap();

function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

// Save RiskRegister

export const saveRiskRegisterActions = {
  SAVE_RISK_REGISTER: "SAVE_RISK_REGISTER",
  SAVE_RISK_REGISTER_STATUS: "SAVE_RISK_REGISTER_STATUS",
  SAVE_RISK_REGISTER_SUCCESS: "SAVE_RISK_REGISTER_SUCCESS",
  SAVE_RISK_REGISTER_FAILURE: "SAVE_RISK_REGISTER_FAILURE",
};

export function saveRiskRegisterRequest() {
  return {
    type: saveRiskRegisterActions.SAVE_RISK_REGISTER,
    isPosting: true,
  };
}

export function saveRiskRegisterSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: saveRiskRegisterActions.SAVE_RISK_REGISTER_SUCCESS,
      payload: props,
    });
  };
}

export function saveRiskRegisterFailure(error) {
  return {
    type: saveRiskRegisterActions.SAVE_RISK_REGISTER_FAILURE,
    payload: error,
  };
}

export function saveRiskRegisterError(error) {
  return {
    type: saveRiskRegisterActions.SAVE_RISK_REGISTER_FAILURE,
    payload: error,
  };
}

export function saveRiskRegister(props, access_token) {
  // var value = {
  //   ropaTypeId: props.ropaTypeId || null,
  //   ropaTypeName: props.ropaTypeName
  // };

  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${RiskManagementService_URL}/Apc_View/Amc_9/saveRiskMaster`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        dispatch(saveRiskRegisterRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(saveRiskRegisterSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(saveRiskRegisterError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(saveRiskRegisterError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(saveRiskRegisterError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(saveRiskRegisterError(err));
              break;
            case 500:
              dispatch(saveRiskRegisterError("Server Error"));
              break;
            default:
              dispatch(saveRiskRegisterError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// Delete RiskRegister

export const deleteRiskRegisterActions = {
  DELETE_RISK_REGISTER: "DELETE_RISK_REGISTER",
  DELETE_RISK_REGISTER_STATUS: "DELETE_RISK_REGISTER_STATUS",
  DELETE_RISK_REGISTER_SUCCESS: "DELETE_RISK_REGISTER_SUCCESS",
  DELETE_RISK_REGISTER_FAILURE: "DELETE_RISK_REGISTER_FAILURE",
};

export function deleteRiskRegisterRequest() {
  return {
    type: deleteRiskRegisterActions.DELETE_RISK_REGISTER,
    isPosting: true,
  };
}

export function deleteRiskRegisterSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: deleteRiskRegisterActions.DELETE_RISK_REGISTER_SUCCESS,
      payload: props,
    });
  };
}

export function deleteRiskRegisterFailure(error) {
  return {
    type: deleteRiskRegisterActions.DELETE_RISK_REGISTER_FAILURE,
    payload: error,
  };
}

export function deleteRiskRegisterError(error) {
  return {
    type: deleteRiskRegisterActions.DELETE_RISK_REGISTER_FAILURE,
    payload: error,
  };
}

export function deleteRiskRegister(params) {
  return (dispatch) =>
    axios({
      method: "post",
      url:
        `${RiskManagementService_URL}/Apc_State/Amc_19/deleteRiskRegister?templateId=` +
        params.ropaId,
      headers: {
        authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(deleteRiskRegisterRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(deleteRiskRegisterSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(deleteRiskRegisterError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        
        if (err.response) {
          // switch (err.response.status) {
          //   case 400:
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;

          //   dispatch(deleteRiskRegisterError(err));
          //   break;
          // case 404:
          //   map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   map.forEach(ele => (err = ele));

          //   dispatch(deleteRiskRegisterError(err));
          //   break;
          // case 406:
          //   map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //   map.forEach(ele => (err = ele));

          //   dispatch(deleteRiskRegisterError(err));
          //   break;
          // case 500:
          //   dispatch(deleteRiskRegisterError("Internal Server Error"));
          //   break;
          // default:
          //   dispatch(deleteRiskRegisterError("Unknown Error"));
          //   break;
          // }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// clear  details
export const ClearFormDetailsActions = {
  CLEAR_FORM_DETAILS: "CLEAR_FORM_DETAILS",
};

export function clearFormData() {
  return (dispatch) => dispatch(clearFormDetails());
}

export function clearFormDetails() {
  return {
    type: ClearFormDetailsActions.CLEAR_FORM_DETAILS,
  };
}

// get  details by id

///////GET LOCATION BY ID

export const GetRiskRegisterDetailsActions = {
  FETCH_RISK_REGISTER_DETAILS: "FETCH_RISK_REGISTER_DETAILS",
  FETCH_RISK_REGISTER_DETAILS_STATUS: "FETCH_RISK_REGISTER_DETAILS_STATUS",
  FETCH_RISK_REGISTER_DETAILS_FULFILLED: "FETCH_RISK_REGISTER_DETAILS_FULFILLED",
  FETCH_RISK_REGISTER_DETAILS_REJECTED: "FETCH_RISK_REGISTER_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getRiskRegisterDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${RiskManagementService_URL}/getByIdRiskMaster?riskId=` + params.riskId,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        // dispatch(getRiskRegisterDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getRiskRegisterDetailsSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getRiskRegisterDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getRiskRegisterDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getRiskRegisterDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getRiskRegisterDetailsError(err));
              break;
            case 500:
              dispatch(getRiskRegisterDetailsError("Server Error"));
              break;
            default:
              dispatch(getRiskRegisterDetailsError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getRiskRegisterDetailsRequest() {
  return {
    type: GetRiskRegisterDetailsActions.FETCH_RISK_REGISTER_DETAILS,
    isPosting: true,
  };
}
export function getRiskRegisterDetailsError(error) {
  return {
    type: GetRiskRegisterDetailsActions.FETCH_RISK_REGISTER_DETAILS_REJECTED,
    payload: error,
  };
}

export function getRiskRegisterDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetRiskRegisterDetailsActions.FETCH_RISK_REGISTER_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getRiskRegisterDetailsStatus() {
  return (dispatch) => dispatch(getRiskRegisterDetailsStatusRequest());
}
export function getRiskRegisterDetailsStatusRequest() {
  return {
    type: GetRiskRegisterDetailsActions.FETCH_RISK_REGISTER_DETAILS_STATUS,
  };
}

export function setDetailsError() {
  return (dispatch) => dispatch(getRiskRegisterDetailsErrorRequest());
}
export function getRiskRegisterDetailsErrorRequest() {
  return {
    type: GetRiskRegisterDetailsActions.SET_EDIT_ERROR,
  };
}

//////////////////////////////////////////////////////////////////////////////////////////
export const UploadexcelActions = {
  CREATE_UPLOAD_EXCEL: "CREATE_UPLOAD_EXCEL",
  CREATE_UPLOAD_EXCEL_SUCCESS: "CREATE_UPLOAD_EXCEL_SUCCESS",
  CREATE_UPLOAD_EXCEL_FAILURE: "CREATE_UPLOAD_EXCEL_FAILURE",
};

export function UploadexcelRequest() {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL,
    isPosting: true,
  };
}

export function UploadexcelSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS,
      payload: props,
    });
  };
}

export function UploadexcelFailure(error) {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE,
    payload: error,
  };
}

export function UploadExcel(props) {
  
  let data = new FormData();
  data.append("ropaTypes", props.Excel);

  return (dispatch) =>
    axios({
      method: "post",
      data: data,
      url:
        `${RiskManagementService_URL}/bulkUploadExcel`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.access_token,
        transactionId: "1",
      },
    })
      .then((response) => {
        dispatch(UploadexcelRequest());
        if (response.status === 200) {
          dispatch(UploadexcelSuccess(response));
        } else {
          
          const error = new Error(response.statusText);
          dispatch(UploadexcelFailure(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(UploadexcelFailure(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(UploadexcelFailure(err));
              break;
            case 500:
              dispatch(UploadexcelFailure("Server Error"));
              break;
            default:
              dispatch(UploadexcelFailure("Unknown Error"));
              break;
          }
        } else {
          dispatch(UploadexcelFailure("Internal Server Error"));
          dispatch(clearLogoutData());
        }
      });
}
///ACTIVE RISK_REGISTERS :LIST

export const GetActiveRiskRegisterActions = {
  FETCH_ACTIVE_RISK_REGISTERS: "FETCH_ACTIVE_RISK_REGISTERS",
  FETCH_ACTIVE_RISK_REGISTERS_STATUS: "FETCH_ACTIVE_RISK_REGISTERS_STATUS",
  FETCH_ACTIVE_RISK_REGISTERS_FULFILLED: "FETCH_ACTIVE_RISK_REGISTERS_FULFILLED",
  FETCH_ACTIVE_RISK_REGISTERS_REJECTED: "FETCH_ACTIVE_RISK_REGISTERS_REJECTED",
};

export function getActiveRiskRegisterList(params) {
  return (dispatch) =>
  axios({
    method: "post",
    // data: JSON.stringify( {
    //           ...params
    //         }),
    url:
      `${RiskManagementService_URL}/getAllRiskMasterPagination?length=0&sortOrder=desc&sortField=riskID&status=true`, 
    headers: {
      transactionId: "1",
      Authorization: "Bearer " + params.access_token,
    },
  })
      .then((response) => {
        dispatch(getActiveRiskRegisterListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveRiskRegisterListSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getActiveRiskRegisterListError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveRiskRegisterListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveRiskRegisterListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveRiskRegisterListError(err));
              break;
            case 500:
              dispatch(getActiveRiskRegisterListError("Server Error"));
              break;
            default:
              dispatch(getActiveRiskRegisterListError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveRiskRegisterListRequest() {
  return {
    type: GetActiveRiskRegisterActions.FETCH_ACTIVE_RISK_REGISTERS,
    isPosting: true,
  };
}
export function getActiveRiskRegisterListError(error) {
  return {
    type: GetActiveRiskRegisterActions.FETCH_ACTIVE_RISK_REGISTERS_REJECTED,
    payload: error,
  };
}

export function getActiveRiskRegisterListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveRiskRegisterActions.FETCH_ACTIVE_RISK_REGISTERS_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveRiskRegisterListStatus() {
  return (dispatch) => dispatch(getActiveRiskRegisterListStatusRequest());
}
export function getActiveRiskRegisterListStatusRequest() {
  return {
    type: GetActiveRiskRegisterActions.FETCH_ACTIVE_RISK_REGISTERS_STATUS,
  };
}
// Save Doc

export const saveDocActions = {
  SAVE_DOC: "SAVE_DOC",
  SAVE_DOC_STATUS: "SAVE_DOC_STATUS",
  SAVE_DOC_SUCCESS: "SAVE_DOC_SUCCESS",
  SAVE_DOC_FAILURE: "SAVE_DOC_FAILURE",
};

export function saveDocRequest() {
  return {
    type: saveDocActions.SAVE_DOC,
    isPosting: true,
  };
}

export function saveDocSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: saveDocActions.SAVE_DOC_SUCCESS,
      payload: props,
    });
  };
}

export function saveDocFailure(error) {
  return {
    type: saveDocActions.SAVE_DOC_FAILURE,
    payload: error,
  };
}

export function saveDocError(error) {
  return {
    type: saveDocActions.SAVE_DOC_FAILURE,
    payload: error,
  };
}

export function saveDoc(base64, access_token) {
  // var value = {
  //   docTypeId: props.docTypeId || null,
  //   docTypeName: props.docTypeName
  // };

  return (dispatch) =>
    axios({
      method: "post",
      data: base64,
      url: `${RiskManagementService_URL}/Apc_Persist/Amc_19/saveDocumentDetails`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        dispatch(saveDocRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(saveDocSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(saveDocError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(saveDocError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(saveDocError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(saveDocError(err));
              break;
            case 500:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(saveDocError(err));
              break;
            default:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(saveDocError(err));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

/////////////EDIT DOC///////////////////////////////////////

export const EditDocActions = {
  EDIT_DOC: "EDIT_DOC",
  EDIT_DOC_STATUS: "EDIT_DOC_STATUS",
  EDIT_DOC_SUCCESS: "EDIT_DOC_SUCCESS",
  EDIT_DOC_FAILURE: "EDIT_DOC_FAILURE",
};

export function EditDocRequest() {
  return {
    type: EditDocActions.EDIT_DOC,
    isPosting: true,
  };
}

export function EditDocSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: EditDocActions.EDIT_DOC_SUCCESS,
      payload: props,
    });
  };
}

export function EditDocFailure(error) {
  return {
    type: EditDocActions.EDIT_DOC_FAILURE,
    payload: error,
  };
}

export function EditDocError(error) {
  return {
    type: EditDocActions.EDIT_DOC_FAILURE,
    payload: error,
  };
}

export function EditDoc(parms, access_token) {
  // var value = {
  //   docTypeId: props.docTypeId || null,
  //   docTypeName: props.docTypeName
  // };

  return (dispatch) =>
    axios({
      method: "post",
      //data: base64,
      url:
        `${RiskManagementService_URL}/Apc_View/Amc_19/getRiskRegisterDocumentDetails?ropaId=` +
        parms,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        dispatch(EditDocRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(EditDocSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(EditDocError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(EditDocError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(EditDocError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(EditDocError(err));
              break;
            case 500:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(EditDocError(err));
              break;
            default:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(EditDocError(err));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

///ACTIVE RISK_REGISTERS :LIST

export const updateStatusRiskRegisterActions = {
  UPDATE_STATUS_RISK_REGISTER: "UPDATE_STATUS_RISK_REGISTER",
  UPDATE_STATUS_RISK_REGISTER_STATUS: "UPDATE_STATUS_RISK_REGISTER_STATUS",
  UPDATE_STATUS_RISK_REGISTER_FULFILLED: "UPDATE_STATUS_RISK_REGISTER_FULFILLED",
  UPDATE_STATUS_RISK_REGISTER_REJECTED: "UPDATE_STATUS_RISK_REGISTER_REJECTED",
};

export function updateStatusRiskRegister(params) {
  return (dispatch) =>
  axios({
    method: "post",
    // data: JSON.stringify( {
    //           ...params
    //         }),
    url:
    `${RiskManagementService_URL}/activeInactiveRiskMaster?riskId=` +
    params.id +
    `&status=` +
    params.isActive,
    headers: {
      transactionId: "1",
      Authorization: "Bearer " + params.access_token,
    },
  })
      .then((response) => {
        dispatch(updateStatusRiskRegisterRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(updateStatusRiskRegisterSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(updateStatusRiskRegisterError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(updateStatusRiskRegisterError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(updateStatusRiskRegisterError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(updateStatusRiskRegisterError(err));
              break;
            case 500:
              dispatch(updateStatusRiskRegisterError("Server Error"));
              break;
            default:
              dispatch(updateStatusRiskRegisterError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function updateStatusRiskRegisterRequest() {
  return {
    type: updateStatusRiskRegisterActions.UPDATE_STATUS_RISK_REGISTER,
    isPosting: true,
  };
}
export function updateStatusRiskRegisterError(error) {
  return {
    type: updateStatusRiskRegisterActions.UPDATE_STATUS_RISK_REGISTER_REJECTED,
    payload: error,
  };
}

export function updateStatusRiskRegisterSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: updateStatusRiskRegisterActions.UPDATE_STATUS_RISK_REGISTER_FULFILLED,
      payload: props,
    });
  };
}

// drawer

export const saveSurveyRiskRegisterActions = {
  SAVE_SURVEY_RISK_REGISTER: "SAVE_SURVEY_RISK_REGISTER",
  SAVE_SURVEY_RISK_REGISTER_STATUS: "SAVE_SURVEY_RISK_REGISTER_STATUS",
  SAVE_SURVEY_RISK_REGISTER_SUCCESS: "SAVE_SURVEY_RISK_REGISTER_SUCCESS",
  SAVE_SURVEY_RISK_REGISTER_FAILURE: "SAVE_SURVEY_RISK_REGISTER_FAILURE",
};

export function saveSurveyRiskRegisterRequest() {
  return {
    type: saveSurveyRiskRegisterActions.SAVE_SURVEY_RISK_REGISTER,
    isPosting: true,
  };
}

export function saveSurveyRiskRegisterSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: saveSurveyRiskRegisterActions.SAVE_SURVEY_RISK_REGISTER_SUCCESS,
      payload: props,
    });
  };
}

export function saveSurveyRiskRegisterFailure(error) {
  return {
    type: saveSurveyRiskRegisterActions.SAVE_SURVEY_RISK_REGISTER_FAILURE,
    payload: error,
  };
}

export function saveSurveyRiskRegisterError(error) {
  return {
    type: saveSurveyRiskRegisterActions.SAVE_SURVEY_RISK_REGISTER_FAILURE,
    payload: error,
  };
}

export function saveSurveyRiskRegister(props, access_token) {
  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${RiskManagementService_URL}/Apc_View/Amc_9/saveRiskMaster`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        dispatch(saveSurveyRiskRegisterRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(saveSurveyRiskRegisterSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(saveSurveyRiskRegisterError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(saveSurveyRiskRegisterError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(saveSurveyRiskRegisterError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(saveSurveyRiskRegisterError(err));
              break;
            case 500:
              dispatch(saveSurveyRiskRegisterError("Server Error"));
              break;
            default:
              dispatch(saveSurveyRiskRegisterError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// automate risk
// from risk drawer

export const saveAutoRiskRegisterActions = {
  SAVE_AUTO_RISK_REGISTER: "SAVE_AUTO_RISK_REGISTER",
  SAVE_AUTO_RISK_REGISTER_STATUS: "SAVE_AUTO_RISK_REGISTER_STATUS",
  SAVE_AUTO_RISK_REGISTER_SUCCESS: "SAVE_AUTO_RISK_REGISTER_SUCCESS",
  SAVE_AUTO_RISK_REGISTER_FAILURE: "SAVE_AUTO_RISK_REGISTER_FAILURE",
};

export function saveAutoRiskRegisterRequest() {
  return {
    type: saveAutoRiskRegisterActions.SAVE_AUTO_RISK_REGISTER,
    isPosting: true,
  };
}

export function saveAutoRiskRegisterSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: saveAutoRiskRegisterActions.SAVE_AUTO_RISK_REGISTER_SUCCESS,
      payload: props,
    });
  };
}

export function saveAutoRiskRegisterFailure(error) {
  return {
    type: saveAutoRiskRegisterActions.SAVE_AUTO_RISK_REGISTER_FAILURE,
    payload: error,
  };
}

export function saveAutoRiskRegisterError(error) {
  return {
    type: saveAutoRiskRegisterActions.SAVE_AUTO_RISK_REGISTER_FAILURE,
    payload: error,
  };
}

export function saveAutoRiskRegister(props, access_token) {
  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${SurveyService_URL}/Apc_Persist/Amc_31/saveSurveyOwnRisk`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        dispatch(saveAutoRiskRegisterRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(saveAutoRiskRegisterSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(saveAutoRiskRegisterError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(saveAutoRiskRegisterError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(saveAutoRiskRegisterError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(saveAutoRiskRegisterError(err));
              break;
            case 500:
              dispatch(saveAutoRiskRegisterError("Server Error"));
              break;
            default:
              dispatch(saveAutoRiskRegisterError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}


// Automate risk
// from dropdowns

export const saveAutoRiskRegisterDropActions = {
  SAVE_AUTO_RISK_DROP_REGISTER: "SAVE_AUTO_RISK_DROP_REGISTER",
  SAVE_AUTO_RISK_REGISTER_DROP_STATUS: "SAVE_AUTO_RISK_REGISTER_DROP_STATUS",
  SAVE_AUTO_RISK_REGISTER_DROP_SUCCESS: "SAVE_AUTO_RISK_REGISTER_DROP_SUCCESS",
  SAVE_AUTO_RISK_REGISTER_DROP_FAILURE: "SAVE_AUTO_RISK_REGISTER_DROP_FAILURE",
};

export function saveAutoRiskRegisterDropRequest() {
  return {
    type: saveAutoRiskRegisterDropActions.SAVE_AUTO_RISK_DROP_REGISTER,
    isPosting: true,
  };
}

export function saveAutoRiskRegisterDropSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: saveAutoRiskRegisterDropActions.SAVE_AUTO_RISK_REGISTER_DROP_SUCCESS,
      payload: props,
    });
  };
}

export function saveAutoRiskRegisterDropFailure(error) {
  return {
    type: saveAutoRiskRegisterDropActions.SAVE_AUTO_RISK_REGISTER_DROP_FAILURE,
    payload: error,
  };
}

export function saveAutoRiskRegisterDropError(error) {
  return {
    type: saveAutoRiskRegisterDropActions.SAVE_AUTO_RISK_REGISTER_DROP_FAILURE,
    payload: error,
  };
}

export function saveAutoRiskDropRegister(props, access_token) {
  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${SurveyService_URL}/Apc_Persist/Amc_31/saveSurveyAutoRisk`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        dispatch(saveAutoRiskRegisterDropRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(saveAutoRiskRegisterDropSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(saveAutoRiskRegisterDropError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(saveAutoRiskRegisterDropError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(saveAutoRiskRegisterDropError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(saveAutoRiskRegisterDropError(err));
              break;
            case 500:
              dispatch(saveAutoRiskRegisterDropError("Server Error"));
              break;
            default:
              dispatch(saveAutoRiskRegisterDropError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}


// VENDOR AUTORISK ACTIONS

// Autorisk from dropdowns

export const saveAutoRiskRegisterVendorDropActions = {
  SAVE_AUTO_RISK_DROP_REGISTER: "SAVE_AUTO_RISK_DROP_REGISTER",
  SAVE_AUTO_RISK_REGISTER_DROP_STATUS: "SAVE_AUTO_RISK_REGISTER_DROP_STATUS",
  SAVE_AUTO_RISK_REGISTER_DROP_SUCCESS: "SAVE_AUTO_RISK_REGISTER_DROP_SUCCESS",
  SAVE_AUTO_RISK_REGISTER_DROP_FAILURE: "SAVE_AUTO_RISK_REGISTER_DROP_FAILURE",
};

export function saveAutoRiskRegisterDropVendorRequest() {
  return {
    type: saveAutoRiskRegisterVendorDropActions.SAVE_AUTO_RISK_DROP_REGISTER,
    isPosting: true,
  };
}

export function saveAutoRiskRegisterDropVendorSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: saveAutoRiskRegisterVendorDropActions.SAVE_AUTO_RISK_REGISTER_DROP_SUCCESS,
      payload: props,
    });
  };
}

export function saveAutoRiskRegisterDropVendorFailure(error) {
  return {
    type: saveAutoRiskRegisterVendorDropActions.SAVE_AUTO_RISK_REGISTER_DROP_FAILURE,
    payload: error,
  };
}

export function saveAutoRiskRegisterDropVendorError(error) {
  return {
    type: saveAutoRiskRegisterVendorDropActions.SAVE_AUTO_RISK_REGISTER_DROP_FAILURE,
    payload: error,
  };
}

export function saveAutoRiskDropVendorRegister(props, access_token) {
  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${SurveyService_URL}/Apc_Persist/Amc_31/saveSurveyAutoRisk`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        dispatch(saveAutoRiskRegisterDropVendorRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(saveAutoRiskRegisterDropVendorSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(saveAutoRiskRegisterDropVendorError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(saveAutoRiskRegisterDropVendorError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(saveAutoRiskRegisterDropVendorError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(saveAutoRiskRegisterDropVendorError(err));
              break;
            case 500:
              dispatch(saveAutoRiskRegisterDropVendorError("Server Error"));
              break;
            default:
              dispatch(saveAutoRiskRegisterDropVendorError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

//  From Risk Drawer

export const saveAutoRiskRegisterVendorActions = {
  SAVE_AUTO_RISK_REGISTER: "SAVE_AUTO_RISK_REGISTER",
  SAVE_AUTO_RISK_REGISTER_STATUS: "SAVE_AUTO_RISK_REGISTER_STATUS",
  SAVE_AUTO_RISK_REGISTER_SUCCESS: "SAVE_AUTO_RISK_REGISTER_SUCCESS",
  SAVE_AUTO_RISK_REGISTER_FAILURE: "SAVE_AUTO_RISK_REGISTER_FAILURE",
};

export function saveAutoRiskRegisterVendorRequest() {
  return {
    type: saveAutoRiskRegisterActions.SAVE_AUTO_RISK_REGISTER,
    isPosting: true,
  };
}

export function saveAutoRiskRegisterVendorSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: saveAutoRiskRegisterActions.SAVE_AUTO_RISK_REGISTER_SUCCESS,
      payload: props,
    });
  };
}

export function saveAutoRiskRegisterVendorFailure(error) {
  return {
    type: saveAutoRiskRegisterActions.SAVE_AUTO_RISK_REGISTER_FAILURE,
    payload: error,
  };
}

export function saveAutoRiskRegisterVendorError(error) {
  return {
    type: saveAutoRiskRegisterActions.SAVE_AUTO_RISK_REGISTER_FAILURE,
    payload: error,
  };
}

export function saveAutoRiskRegisterVendor(props, access_token) {
  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${SurveyService_URL}/Apc_Persist/Amc_31/saveSurveyOwnRisk`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        dispatch(saveAutoRiskRegisterVendorRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(saveAutoRiskRegisterVendorSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(saveAutoRiskRegisterVendorError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(saveAutoRiskRegisterVendorError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(saveAutoRiskRegisterVendorError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(saveAutoRiskRegisterVendorError(err));
              break;
            case 500:
              dispatch(saveAutoRiskRegisterVendorError("Server Error"));
              break;
            default:
              dispatch(saveAutoRiskRegisterVendorError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}