import axios from "axios";
import {
  SurveyService_URL,
  OTPService_URL,
  Server_IP,
  SystemService_URL,
  QueryService_URL,
} from "../Url";
import HashMap from "hashmap";
import { clearLogoutData } from "../Logout/actions";

var map = new HashMap();

function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

// Save AssetType

export const SaveFormBuilderActions = {
  SAVE_FORMBUILDER: "SAVE_FORMBUILDER",
  SAVE_FORMBUILDER_STATUS: "SAVE_FORMBUILDER_STATUS",
  SAVE_FORMBUILDER_SUCCESS: "SAVE_FORMBUILDER_SUCCESS",
  SAVE_FORMBUILDER_FAILURE: "SAVE_FORMBUILDER_FAILURE",
  LOGOUT: "LOGOUT",
};
export function logout() {
  return {
    type: SaveFormBuilderActions.LOGOUT,
    payload: "Session Expired. Please Login Again",
  };
}

export function SaveFormBuilderRequest() {
  return {
    type: SaveFormBuilderActions.SAVE_FORMBUILDER,
    isPosting: true,
  };
}

export function SaveFormBuilderSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveFormBuilderActions.SAVE_FORMBUILDER_SUCCESS,
      payload: props,
    });
  };
}

export function SaveFormBuilderFailure(error) {
  return {
    type: SaveFormBuilderActions.SAVE_FORMBUILDER_FAILURE,
    payload: error,
  };
}

export function SaveFormBuilderError(error) {
  return {
    type: SaveFormBuilderActions.SAVE_FORMBUILDER_FAILURE,
    payload: error,
  };
}

export function saveFormPages(props, access_token) {
  // var value = {
  //   id: props.legalBasisId || null,
  //   name: props.legalBasisName
  // };

  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      // url: `${SurveyService_URL}/Apc_Persist/Amc_30/saveSurveyPages`,
      url: `${SurveyService_URL}/Apc_Persist/Amc_30/saveAssessmentSurveyPages`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        dispatch(SaveFormBuilderRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveFormBuilderSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(SaveFormBuilderError(error));
          // return response;
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// clear  details
export const ClearFormDetailsActions = {
  CLEAR_FORM_DETAILS: "CLEAR_FORM_DETAILS",
};

export function clearFormData() {
  return (dispatch) => dispatch(clearFormDetails());
}

export function clearFormDetails() {
  return {
    type: ClearFormDetailsActions.CLEAR_FORM_DETAILS,
  };
}

////// COPY DETAILS/////////////
export const CopyFormDetailsActions = {
  COPY_FORM_DETAILS: "COPY_FORM_DETAILS",
};

export function copySurveyDetails(params) {
  // debugger;
  return (dispatch) => dispatch(copyFormDetails(params.isDraft));
}

export function copyFormDetails(isDraft) {
  return {
    type: CopyFormDetailsActions.COPY_FORM_DETAILS,
    payload: isDraft,
  };
}

////////COPY DETAILS END////////////

////// TEMPLATE  DETAILS/////////////
export const TemplateDetailsActions = {
  TEMPLATE_FORM_DETAILS: "TEMPLATE_FORM_DETAILS",
};

export function TemplateSelected() {
  return (dispatch) => dispatch(templateFormDetails());
}

export function templateFormDetails() {
  return {
    type: TemplateDetailsActions.TEMPLATE_FORM_DETAILS,
  };
}

////////TEMPLATE DETAILS END////////////

/////////////SAVE DATA IN PROPS/////////////

export const SaveFormDetailsProps = {
  SAVE_FORM_DETAILS: "SAVE_FORM_DETAILS",
};

export function SaveFormProps(Data) {
  return (dispatch) => dispatch(SaveFormDetails(Data));
}

export function SaveFormDetails(Data) {
  return (dispatch) => {
    dispatch({
      type: SaveFormDetailsProps.SAVE_FORM_DETAILS,
      payload: Data,
    });
  };
}

////////////SAVE DATA IN PROPS END//////////////

// get  details by id

///////GET LOCATION BY ID

export const GetSurveyDetailsActions = {
  FETCH_FORMBUILDER_DETAILS: "FETCH_FORMBUILDER_DETAILS",
  FETCH_FORMBUILDER_DETAILS_STATUS: "FETCH_FORMBUILDER_DETAILS_STATUS",
  FETCH_FORMBUILDER_DETAILS_FULFILLED: "FETCH_FORMBUILDER_DETAILS_FULFILLED",
  FETCH_FORMBUILDER_DETAILS_REJECTED: "FETCH_FORMBUILDER_DETAILS_REJECTED",
  MODIFY_TEMPLATE: "MODIFY_TEMPLATE",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getSurveyDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url: `${SurveyService_URL}/Apc_View/Amc_30/getSurvey/` + params.surveyId,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        // dispatch(getSurveyDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getSurveyDetailsSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getSurveyDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getSurveyDetailsRequest() {
  return {
    type: GetSurveyDetailsActions.FETCH_FORMBUILDER_DETAILS,
    isPosting: true,
  };
}
export function ModifyTemplateSurveyDetailsRequest(payload) {
  return {
    type: GetSurveyDetailsActions.MODIFY_TEMPLATE,
    payload: payload,
  };
}

export function getSurveyDetailsError(error) {
  return {
    type: GetSurveyDetailsActions.FETCH_FORMBUILDER_DETAILS_REJECTED,
    payload: error,
  };
}

export function getSurveyDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetSurveyDetailsActions.FETCH_FORMBUILDER_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getSurveyDetailsStatus() {
  return (dispatch) => dispatch(getSurveyDetailsStatusRequest());
}
export function getSurveyDetailsStatusRequest() {
  return {
    type: GetSurveyDetailsActions.FETCH_FORMBUILDER_DETAILS_STATUS,
  };
}

export function setDetailsError() {
  return (dispatch) => dispatch(getSurveyDetailsErrorRequest());
}
export function getSurveyDetailsErrorRequest() {
  return {
    type: GetSurveyDetailsActions.SET_EDIT_ERROR,
  };
}
//////EXternal SUrvey Details////

export const GetSurveyForExternalUserActions = {
  FETCH_EXTERNAL_FORMBUILDER_DATA: "FETCH_EXTERNAL_FORMBUILDER_DATA",
  FETCH_EXTERNAL_FORMBUILDER_DATA_STATUS:
    "FETCH_EXTERNAL_FORMBUILDER_DATA_STATUS",
  FETCH_EXTERNAL_FORMBUILDER_DATA_FULFILLED:
    "FETCH_EXTERNAL_FORMBUILDER_DATA_FULFILLED",
  FETCH_EXTERNAL_FORMBUILDER_DATA_REJECTED:
    "FETCH_EXTERNAL_FORMBUILDER_DATA_REJECTED",
  // SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getSurveyForExternalUser(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${SurveyService_URL}/Apc_View/Amc_30/getSurveyForExternalUser/` +
        params.surveyId,
      // headers: {
      //   transactionId: "1",
      //   Authorization: "Bearer " + params.access_token,
      // },
    })
      .then((response) => {
        // dispatch(getSurveyForExternalUserRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getSurveyForExternalUserSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getSurveyForExternalUserError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getSurveyForExternalUserRequest() {
  return {
    type: GetSurveyForExternalUserActions.FETCH_EXTERNAL_FORMBUILDER_DATA,
    isPosting: true,
  };
}
export function getSurveyForExternalUserError(error) {
  return {
    type: GetSurveyForExternalUserActions.FETCH_EXTERNAL_FORMBUILDER_DATA_REJECTED,
    payload: error,
  };
}

export function getSurveyForExternalUserSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetSurveyForExternalUserActions.FETCH_EXTERNAL_FORMBUILDER_DATA_FULFILLED,
      payload: props,
    });
  };
}

export function getSurveyForExternalUserStatus() {
  return (dispatch) => dispatch(getSurveyForExternalUserStatusRequest());
}
export function getSurveyForExternalUserStatusRequest() {
  return {
    type: GetSurveyForExternalUserActions.FETCH_EXTERNAL_FORMBUILDER_DATA_STATUS,
  };
}

//////////////////////////////////////////////////////////////////////////////////////////
export const UploadexcelActions = {
  CREATE_UPLOAD_EXCEL: "CREATE_UPLOAD_EXCEL",
  CREATE_UPLOAD_EXCEL_SUCCESS: "CREATE_UPLOAD_EXCEL_SUCCESS",
  CREATE_UPLOAD_EXCEL_FAILURE: "CREATE_UPLOAD_EXCEL_FAILURE",
};

export function UploadexcelRequest() {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL,
    isPosting: true,
  };
}

export function UploadexcelSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS,
      payload: props,
    });
  };
}

export function UploadexcelFailure(error) {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE,
    payload: error,
  };
}

export function UploadExcel(props) {
  let data = new FormData();
  data.append("hostingTypes", props.Excel);

  return (dispatch) =>
    axios({
      method: "post",
      data: data,
      url: `${SurveyService_URL}/bulkUploadExcel`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.access_token,
        transactionId: "1",
      },
    })
      .then((response) => {
        dispatch(UploadexcelRequest());
        if (response.status === 200) {
          dispatch(UploadexcelSuccess(response));
        } else {
          const error = new Error(response.statusText);
          dispatch(UploadexcelFailure(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export const GetActiveFormBuilderActions = {
  FETCH_ACTIVE_FORMBUILDER: "FETCH_ACTIVE_FORMBUILDER",
  FETCH_ACTIVE_FORMBUILDER_STATUS: "FETCH_ACTIVE_FORMBUILDER_STATUS",
  FETCH_ACTIVE_FORMBUILDER_FULFILLED: "FETCH_ACTIVE_FORMBUILDER_FULFILLED",
  FETCH_ACTIVE_FORMBUILDER_REJECTED: "FETCH_ACTIVE_FORMBUILDER_REJECTED",
};
export function getActiveFormBuilderList(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url: `${SurveyService_URL}/getAllFormBuilderPagination?start=0&length=0&sortOrder=asc&sortField=id&booleanfield=true`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveFormBuilderListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveFormBuilderListSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getActiveFormBuilderListError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveFormBuilderListRequest() {
  return {
    type: GetActiveFormBuilderActions.FETCH_ACTIVE_FORMBUILDER,
    isPosting: true,
  };
}
export function getActiveFormBuilderListError(error) {
  return {
    type: GetActiveFormBuilderActions.FETCH_ACTIVE_FORMBUILDER_REJECTED,
    payload: error,
  };
}

export function getActiveFormBuilderListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveFormBuilderActions.FETCH_ACTIVE_FORMBUILDER_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveFormBuilderListStatus() {
  return (dispatch) => dispatch(getActiveFormBuilderListStatusRequest());
}
export function getActiveFormBuilderListStatusRequest() {
  return {
    type: GetActiveFormBuilderActions.FETCH_ACTIVE_FORMBUILDER_STATUS,
  };
}

//Save Survey///
export const SaveSurveyActions = {
  SAVE_SURVEY: "SAVE_SURVEY",
  SAVE_SURVEY_STATUS: "SAVE_SURVEY_STATUS",
  SAVE_SURVEY_SUCCESS: "SAVE_SURVEY_SUCCESS",
  SAVE_SURVEY_FAILURE: "SAVE_SURVEY_FAILURE",
  LOGOUT: "LOGOUT",
};

export function SaveSurveyRequest() {
  return {
    type: SaveSurveyActions.SAVE_SURVEY,
    isPosting: true,
  };
}

export function SaveSurveySuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveSurveyActions.SAVE_SURVEY_SUCCESS,
      payload: props,
    });
  };
}

export function SaveSurveyFailure(error) {
  return {
    type: SaveSurveyActions.SAVE_SURVEY_FAILURE,
    payload: error,
  };
}

export function SaveSurveyError(error) {
  return {
    type: SaveSurveyActions.SAVE_SURVEY_FAILURE,
    payload: error,
  };
}

export function saveSurvey(props, access_token) {
  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${SurveyService_URL}/Apc_Persist/Amc_30/saveSurvey`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        // dispatch(SaveSurveyRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveSurveySuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(SaveSurveyError(error));
          // return response;
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

//Save Survey///
export const CloseSurveyActions = {
  CLOSE_SURVEY: "CLOSE_SURVEY",
  CLOSE_SURVEY_STATUS: "CLOSE_SURVEY_STATUS",
  CLOSE_SURVEY_SUCCESS: "CLOSE_SURVEY_SUCCESS",
  CLOSE_SURVEY_FAILURE: "CLOSE_SURVEY_FAILURE",
  LOGOUT: "LOGOUT",
};

export function CloseSurveyRequest() {
  return {
    type: CloseSurveyActions.CLOSE_SURVEY,
    isPosting: true,
  };
}

export function CloseSurveySuccess(props) {
  return (dispatch) => {
    dispatch({
      type: CloseSurveyActions.CLOSE_SURVEY_SUCCESS,
      payload: props,
    });
  };
}

export function CloseSurveyFailure(error) {
  return {
    type: CloseSurveyActions.CLOSE_SURVEY_FAILURE,
    payload: error,
  };
}

export function CloseSurveyError(error) {
  return {
    type: CloseSurveyActions.CLOSE_SURVEY_FAILURE,
    payload: error,
  };
}

export function CloseSurvey(props, access_token) {
  return (dispatch) =>
    axios({
      method: "post",
      //data: props,
      url:
        `${SurveyService_URL}/Apc_Persist/Amc_19/saveSurveyByStatus?surveyId=` +
        props.surveyId +
        `&status=6`,
      headers: {
        authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        dispatch(CloseSurveyRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(CloseSurveySuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(CloseSurveyError(error));
          // return response;
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

//Save PageElement///
export const SavePageElementActions = {
  SAVE_PAGE_ELEMENT: "SAVE_PAGE_ELEMENT",
  SAVE_PAGE_ELEMENT_STATUS: "SAVE_PAGE_ELEMENT_STATUS",
  SAVE_PAGE_ELEMENT_SUCCESS: "SAVE_PAGE_ELEMENT_SUCCESS",
  SAVE_PAGE_ELEMENT_FAILURE: "SAVE_PAGE_ELEMENT_FAILURE",
  LOGOUT: "LOGOUT",
};

export function SavePageElementRequest() {
  return {
    type: SavePageElementActions.SAVE_PAGE_ELEMENT,
    isPosting: true,
  };
}

export function SavePageElementSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SavePageElementActions.SAVE_PAGE_ELEMENT_SUCCESS,
      payload: props,
    });
  };
}

export function SavePageElementFailure(error) {
  return {
    type: SavePageElementActions.SAVE_PAGE_ELEMENT_FAILURE,
    payload: error,
  };
}

export function SavePageElementError(error) {
  return {
    type: SavePageElementActions.SAVE_PAGE_ELEMENT_FAILURE,
    payload: error,
  };
}

export function savePageElement(props, access_token) {
  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${SurveyService_URL}/saveSurveyElements`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        dispatch(SavePageElementRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SavePageElementSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(SavePageElementError(error));
          // return response;
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

//Save AssessmentElement///
export const SaveAssessmentElementActions = {
  SAVE_ASSESSMENT_ELEMENT: "SAVE_ASSESSMENT_ELEMENT",
  SAVE_ASSESSMENT_ELEMENT_STATUS: "SAVE_ASSESSMENT_ELEMENT_STATUS",
  SAVE_ASSESSMENT_ELEMENT_SUCCESS: "SAVE_ASSESSMENT_ELEMENT_SUCCESS",
  SAVE_ASSESSMENT_ELEMENT_FAILURE: "SAVE_ASSESSMENT_ELEMENT_FAILURE",
  LOGOUT: "LOGOUT",
};

export function SaveAssessmentElementRequest() {
  return {
    type: SaveAssessmentElementActions.SAVE_ASSESSMENT_ELEMENT,
    isPosting: true,
  };
}

export function SaveAssessmentElementSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveAssessmentElementActions.SAVE_ASSESSMENT_ELEMENT_SUCCESS,
      payload: props,
    });
  };
}

export function SaveAssessmentElementFailure(error) {
  return {
    type: SaveAssessmentElementActions.SAVE_ASSESSMENT_ELEMENT_FAILURE,
    payload: error,
  };
}

export function SaveAssessmentElementError(error) {
  return {
    type: SaveAssessmentElementActions.SAVE_ASSESSMENT_ELEMENT_FAILURE,
    payload: error,
  };
}

export function saveAssessmentElement(props, access_token) {
  const apiUrl = props.submitToReviewer && props.submitToReviewer === 'all' ? `${SurveyService_URL}/Apc_Persist/Amc_30/saveSurveyAssessment?submitToReviewer=true` : 
  `${SurveyService_URL}/Apc_Persist/Amc_30/saveSurveyAssessment`;
  // const submitToReviewerObj = props.submitToReviewer ? delete props.submitToReviewer : props;
  console.log("propsdata", props.assessmentSubmitData)
  return (dispatch) =>
    axios({
      method: "post",
      data: props.submitToReviewer ? props.assessmentSubmitData : props,
      url: apiUrl,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        dispatch(SaveAssessmentElementRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveAssessmentElementSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(SaveAssessmentElementError(error));
          // return response;
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

//Save PageElement///
export const DeleteSurveyPageActions = {
  DEL_SURVEY_PAGE: "DEL_SURVEY_PAGE",
  DEL_SURVEY_PAGE_STATUS: "DEL_SURVEY_PAGE_STATUS",
  DEL_SURVEY_PAGE_SUCCESS: "DEL_SURVEY_PAGE_SUCCESS",
  DEL_SURVEY_PAGE_FAILURE: "DEL_SURVEY_PAGE_FAILURE",
  LOGOUT: "LOGOUT",
};

export function DeleteSurveyPageRequest() {
  return {
    type: DeleteSurveyPageActions.DEL_SURVEY_PAGE,
    isPosting: true,
  };
}

export function DeleteSurveyPageSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: DeleteSurveyPageActions.DEL_SURVEY_PAGE_SUCCESS,
      payload: props,
    });
  };
}

export function DeleteSurveyPageFailure(error) {
  return {
    type: DeleteSurveyPageActions.DEL_SURVEY_PAGE_FAILURE,
    payload: error,
  };
}

export function DeleteSurveyPageError(error) {
  return {
    type: DeleteSurveyPageActions.DEL_SURVEY_PAGE_FAILURE,
    payload: error,
  };
}

export function delSurveyPage(props, access_token) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: props,
      url:
        `${SurveyService_URL}/Apc_State/Amc_30/deleteSurveyPages/` +
        props +
        `?status=false`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        dispatch(DeleteSurveyPageRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(DeleteSurveyPageSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(DeleteSurveyPageError(error));
          // return response;
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export const GetActiveTemplateActions = {
  FETCH_ACTIVE_TEMPLATE: "FETCH_ACTIVE_TEMPLATE",
  FETCH_ACTIVE_TEMPLATE_STATUS: "FETCH_ACTIVE_TEMPLATE_STATUS",
  FETCH_ACTIVE_TEMPLATE_FULFILLED: "FETCH_ACTIVE_TEMPLATE_FULFILLED",
  FETCH_ACTIVE_TEMPLATE_REJECTED: "FETCH_ACTIVE_TEMPLATE_REJECTED",
};
export function getActiveTemplateList(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${SurveyService_URL}/Apc_View/Amc_30/findAllSurveyPagination?start=0&length=10&sortOrder=desc&sortField=id&status=true&search=&searchCol=&type=` +
        params.type +
        `&templateFor=` +
        params.templateFor +
        `&surveyStatus=` +
        params.surveyStatus,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveTemplateListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveTemplateListSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getActiveTemplateListError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveTieriingTemplateList(params) {
  return (dispatch) =>
    axios({
      method: "post",
      url:
        `${SurveyService_URL}/Apc_View/Amc_30/findAllSurveyPagination?start=0&length=10&sortOrder=desc&sortField=id&status=true&search=&searchCol=&type=` +
        params.type +
        `&isVendorTiering=true&templateFor=` +
        params.templateFor +
        `&surveyStatus=` +
        params.surveyStatus,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return response;
        } else {
          const error = new Error(response.statusText);
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveTemplateListRequest() {
  return {
    type: GetActiveTemplateActions.FETCH_ACTIVE_TEMPLATE,
    isPosting: true,
  };
}
export function getActiveTemplateListError(error) {
  return {
    type: GetActiveTemplateActions.FETCH_ACTIVE_TEMPLATE_REJECTED,
    payload: error,
  };
}

export function getActiveTemplateListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveTemplateActions.FETCH_ACTIVE_TEMPLATE_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveTemplateListStatus() {
  return (dispatch) => dispatch(getActiveTemplateListStatusRequest());
}
export function getActiveTemplateListStatusRequest() {
  return {
    type: GetActiveTemplateActions.FETCH_ACTIVE_TEMPLATE_STATUS,
  };
}

////Get review//
export const GetSurveyReviewActions = {
  FETCH_FORMBUILDER_REVIEW_REVIEW_DETAILS:
    "FETCH_FORMBUILDER_REVIEW_REVIEW_DETAILS",
  FETCH_FORMBUILDER_REVIEW_REVIEW_DETAILS_STATUS:
    "FETCH_FORMBUILDER_REVIEW_REVIEW_DETAILS_STATUS",
  FETCH_FORMBUILDER_REVIEW_REVIEW_DETAILS_FULFILLED:
    "FETCH_FORMBUILDER_REVIEW_REVIEW_DETAILS_FULFILLED",
  FETCH_FORMBUILDER_REVIEW_REVIEW_DETAILS_REJECTED:
    "FETCH_FORMBUILDER_REVIEW_REVIEW_DETAILS_REJECTED",
  //   SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getSurveyReview(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${SurveyService_URL}/Apc_View/Amc_30/getSurveyReview/` +
        params.surveyId,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getSurveyReviewRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getSurveyReviewSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getSurveyReviewError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getSurveyReviewRequest() {
  return {
    type: GetSurveyReviewActions.FETCH_FORMBUILDER_REVIEW_REVIEW_DETAILS,
    isPosting: true,
  };
}
export function getSurveyReviewError(error) {
  return {
    type: GetSurveyReviewActions.FETCH_FORMBUILDER_REVIEW_REVIEW_DETAILS_REJECTED,
    payload: error,
  };
}

export function getSurveyReviewSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetSurveyReviewActions.FETCH_FORMBUILDER_REVIEW_REVIEW_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getSurveyReviewStatus() {
  return (dispatch) => dispatch(getSurveyReviewStatusRequest());
}
export function getSurveyReviewStatusRequest() {
  return {
    type: GetSurveyReviewActions.FETCH_FORMBUILDER_REVIEW_REVIEW_DETAILS_STATUS,
  };
}

export function setReviewError() {
  return (dispatch) => dispatch(getSurveyReviewErrorRequest());
}
export function getSurveyReviewErrorRequest() {
  return {
    type: GetSurveyReviewActions.SET_EDIT_ERROR,
  };
}

///Get survey by user//
export const GetSurveyByUserActions = {
  FETCH_SURVEY_BY_USER_DETAILS: "FETCH_SURVEY_BY_USER_DETAILS",
  FETCH_SURVEY_BY_USER_DETAILS_STATUS: "FETCH_SURVEY_BY_USER_DETAILS_STATUS",
  FETCH_SURVEY_BY_USER_DETAILS_FULFILLED:
    "FETCH_SURVEY_BY_USER_DETAILS_FULFILLED",
  FETCH_SURVEY_BY_USER_DETAILS_REJECTED:
    "FETCH_SURVEY_BY_USER_DETAILS_REJECTED",
  //   SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getSurveyByUser(params) {
  const finalUrl =
    typeof params.userId === "string"
      ? "Apc_View/Amc_30/getSurveyByUserAndSurveyIdForExternalUser"
      : "Apc_View/Amc_30/getSurveyByUserAndSurveyId";
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${SurveyService_URL}/${finalUrl}/` +
        params.userId +
        `/` +
        params.surveyId,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getSurveyByUserRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getSurveyByUserSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getSurveyByUserError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getSurveyByUserRequest() {
  return {
    type: GetSurveyByUserActions.FETCH_SURVEY_BY_USER_DETAILS,
    isPosting: true,
  };
}
export function getSurveyByUserError(error) {
  return {
    type: GetSurveyByUserActions.FETCH_SURVEY_BY_USER_DETAILS_REJECTED,
    payload: error,
  };
}

export function getSurveyByUserSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetSurveyByUserActions.FETCH_SURVEY_BY_USER_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getSurveyByUserStatus() {
  return (dispatch) => dispatch(getSurveyByUserStatusRequest());
}
export function getSurveyByUserStatusRequest() {
  return {
    type: GetSurveyByUserActions.FETCH_SURVEY_BY_USER_DETAILS_STATUS,
  };
}

export function setUserSurveyError() {
  return (dispatch) => dispatch(getSurveyByUserErrorRequest());
}
export function getSurveyByUserErrorRequest() {
  return {
    type: GetSurveyByUserActions.SET_EDIT_ERROR,
  };
}

///Validate URL
///Get survey by user//
export const ValidateUrlActions = {
  VALIDATE_URL_DETAILS: "VALIDATE_URL_DETAILS",
  VALIDATE_URL_DETAILS_STATUS: "VALIDATE_URL_DETAILS_STATUS",
  VALIDATE_URL_DETAILS_FULFILLED: "VALIDATE_URL_DETAILS_FULFILLED",
  VALIDATE_URL_DETAILS_REJECTED: "VALIDATE_URL_DETAILS_REJECTED",
  //   SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function validateUrl(params) {
  return (dispatch) =>
    axios({
      method: "post",
      data: JSON.stringify({
        ...params,
      }),
      url: `${SurveyService_URL}/Apc_View/Amc_30/validateTinyUrl`,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        dispatch(validateUrlRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(validateUrlSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(validateUrlError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function validateUrlRequest() {
  return {
    type: ValidateUrlActions.VALIDATE_URL_DETAILS,
    isPosting: true,
  };
}
export function validateUrlError(error) {
  return {
    type: ValidateUrlActions.VALIDATE_URL_DETAILS_REJECTED,
    payload: error,
  };
}

export function validateUrlSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: ValidateUrlActions.VALIDATE_URL_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function validateUrlStatus() {
  return (dispatch) => dispatch(validateUrlStatusRequest());
}
export function validateUrlStatusRequest() {
  return {
    type: ValidateUrlActions.VALIDATE_URL_DETAILS_STATUS,
  };
}
/////Get long Url

export const LongUrlActions = {
  LONG_URL_DETAILS: "LONG_URL_DETAILS",
  LONG_URL_DETAILS_STATUS: "LONG_URL_DETAILS_STATUS",
  LONG_URL_DETAILS_FULFILLED: "LONG_URL_DETAILS_FULFILLED",
  LONG_URL_DETAILS_REJECTED: "LONG_URL_DETAILS_REJECTED",
  //   SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function longUrl(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify({
      //   ...params,
      // }),
      url:
        `${SystemService_URL}/Apc_View/Amc_51/getLongUrl?shortUrl=` +
        params.url,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        // dispatch(longUrlRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(longUrlSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(longUrlError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function longUrlRequest() {
  return {
    type: LongUrlActions.LONG_URL_DETAILS,
    isPosting: true,
  };
}
export function longUrlError(error) {
  return {
    type: LongUrlActions.LONG_URL_DETAILS_REJECTED,
    payload: error,
  };
}

export function longUrlSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: LongUrlActions.LONG_URL_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function longUrlStatus() {
  return (dispatch) => dispatch(longUrlStatusRequest());
}
export function longUrlStatusRequest() {
  return {
    type: LongUrlActions.LONG_URL_DETAILS_STATUS,
  };
}

///Update Ext Survey status
///Get survey by user//
export const UpdateExtSurveyActions = {
  UPDATE_EXT_SURVEY_DETAILS: "UPDATE_EXT_SURVEY_DETAILS",
  UPDATE_EXT_SURVEY_DETAILS_STATUS: "UPDATE_EXT_SURVEY_DETAILS_STATUS",
  UPDATE_EXT_SURVEY_DETAILS_FULFILLED: "UPDATE_EXT_SURVEY_DETAILS_FULFILLED",
  UPDATE_EXT_SURVEY_DETAILS_REJECTED: "UPDATE_EXT_SURVEY_DETAILS_REJECTED",
  //   SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function updateExtSurvey(params) {
  return (dispatch) =>
    axios({
      method: "post",
      data: JSON.stringify({
        ...params,
      }),
      url: `${SurveyService_URL}/Apc_Persist/Amc_30/saveExternalUserSurvey`,
      // headers: {
      //   transactionId: "1",
      //   Authorization: "Bearer " + params.access_token,
      // },
    })
      .then((response) => {
        dispatch(updateExtSurveyRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(updateExtSurveySuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(updateExtSurveyError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function updateExtSurveyRequest() {
  return {
    type: UpdateExtSurveyActions.UPDATE_EXT_SURVEY_DETAILS,
    isPosting: true,
  };
}
export function updateExtSurveyError(error) {
  return {
    type: UpdateExtSurveyActions.UPDATE_EXT_SURVEY_DETAILS_REJECTED,
    payload: error,
  };
}

export function updateExtSurveySuccess(props) {
  return (dispatch) => {
    dispatch({
      type: UpdateExtSurveyActions.UPDATE_EXT_SURVEY_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function updateExtSurveyStatus() {
  return (dispatch) => dispatch(updateExtSurveyStatusRequest());
}
export function updateExtSurveyStatusRequest() {
  return {
    type: UpdateExtSurveyActions.UPDATE_EXT_SURVEY_DETAILS_STATUS,
  };
}

///VALIDATE OTP FOR EXT USER

//Save Survey///
export const ValidateOTPAction = {
  VALIDATE_OTP: "VALIDATE_OTP",
  VALIDATE_OTP_STATUS: "VALIDATE_OTP_STATUS",
  VALIDATE_OTP_SUCCESS: "VALIDATE_OTP_SUCCESS",
  VALIDATE_OTP_FAILURE: "VALIDATE_OTP_FAILURE",
  //   LOGOUT: "LOGOUT",
};

export function ValidateOTPRequest() {
  return {
    type: ValidateOTPAction.VALIDATE_OTP,
    isPosting: true,
  };
}

export function ValidateOTPSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: ValidateOTPAction.VALIDATE_OTP_SUCCESS,
      payload: props,
    });
  };
}

export function ValidateOTPFailure(error) {
  return {
    type: ValidateOTPAction.VALIDATE_OTP_FAILURE,
    payload: error,
  };
}

export function ValidateOTPError(error) {
  return {
    type: ValidateOTPAction.VALIDATE_OTP_FAILURE,
    payload: error,
  };
}

export function validateOTP(props) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: props,
      url:
        `${OTPService_URL}/Apc_View/Amc_49/verifyOTP?mobileNo=` +
        props.loginId +
        `&otp=` +
        props.otp +
        `&transactionId=1`,
      // headers: {
      //   authorization: "Bearer " + access_token,
      // },
    })
      .then((response) => {
        dispatch(ValidateOTPRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(ValidateOTPSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(ValidateOTPError(error));
          // return response;
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        }
      });
}

///save page Element for ext user//
//Save AssessmentElement///
export const SaveExternalSurveyElementActions = {
  SAVE_EXT_ASSESSMENT_ELEMENT: "SAVE_EXT_ASSESSMENT_ELEMENT",
  SAVE_EXT_ASSESSMENT_ELEMENT_STATUS: "SAVE_EXT_ASSESSMENT_ELEMENT_STATUS",
  SAVE_EXT_ASSESSMENT_ELEMENT_SUCCESS: "SAVE_EXT_ASSESSMENT_ELEMENT_SUCCESS",
  SAVE_EXT_ASSESSMENT_ELEMENT_FAILURE: "SAVE_EXT_ASSESSMENT_ELEMENT_FAILURE",
};

export function SaveExternalSurveyElementRequest() {
  return {
    type: SaveExternalSurveyElementActions.SAVE_EXT_ASSESSMENT_ELEMENT,
    isPosting: true,
  };
}

export function SaveExternalSurveyElementSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveExternalSurveyElementActions.SAVE_EXT_ASSESSMENT_ELEMENT_SUCCESS,
      payload: props,
    });
  };
}

export function SaveExternalSurveyElementFailure(error) {
  return {
    type: SaveExternalSurveyElementActions.SAVE_EXT_ASSESSMENT_ELEMENT_FAILURE,
    payload: error,
  };
}

export function SaveExternalSurveyElementError(error) {
  return {
    type: SaveExternalSurveyElementActions.SAVE_EXT_ASSESSMENT_ELEMENT_FAILURE,
    payload: error,
  };
}

export function saveExternalAssessmentElement(props, access_token) {
  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${SurveyService_URL}/Apc_Persist/Amc_30/saveExternalSurveyAssessment`,
    })
      .then((response) => {
        dispatch(SaveExternalSurveyElementRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveExternalSurveyElementSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(SaveExternalSurveyElementError(error));
          // return response;
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

//////////////////////////////////
export const selfReviewActions = {
  SELF_REVIEW_ACTIONS: "SELF_REVIEW_ACTIONS",
};

export function selfReview(data) {
  return (dispatch) => dispatch(selfReviewData(data));
}

export function selfReviewData(data) {
  return {
    type: selfReviewActions.SELF_REVIEW_ACTIONS,
    payload: data,
  };
}

/////////////////////////////////////

//////////////////////////////////
export const assessmentTypeActions = {
  ASSESSMENT_TYPE_ACTIONS: "ASSESSMENT_TYPE_ACTIONS",
};

export function assessmentType(data) {
  return (dispatch) => dispatch(assessmentTypeData(data));
}

export function assessmentTypeData(data) {
  return {
    type: assessmentTypeActions.ASSESSMENT_TYPE_ACTIONS,
    payload: data,
  };
}

/////////////////////////////////////

export const GetSurveyUsersActions = {
  GET_SURVEY_USERS: "GET_SURVEY_USERS",
  GET_SURVEY_USERS_STATUS: "GET_SURVEY_USERS_STATUS",
  GET_SURVEY_USERS_FULFILLED: "GET_SURVEY_USERS_FULFILLED",
  GET_SURVEY_USERS_REJECTED: "GET_SURVEY_USERS_REJECTED",
};
export function getSurveyUsersList(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${SurveyService_URL}/Apc_View/Amc_30/getSurveyUsers/` +
        params.surveyId,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getSurveyUsersListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getSurveyUsersListSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getSurveyUsersListError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getSurveyUsersListRequest() {
  return {
    type: GetSurveyUsersActions.GET_SURVEY_USERS,
    isPosting: true,
  };
}
export function getSurveyUsersListError(error) {
  return {
    type: GetSurveyUsersActions.GET_SURVEY_USERS_REJECTED,
    payload: error,
  };
}

export function getSurveyUsersListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetSurveyUsersActions.GET_SURVEY_USERS_FULFILLED,
      payload: props,
    });
  };
}

export function getSurveyUsersListStatus() {
  return (dispatch) => dispatch(getSurveyUsersListStatusRequest());
}
export function getSurveyUsersListStatusRequest() {
  return {
    type: GetSurveyUsersActions.GET_SURVEY_USERS_STATUS,
  };
}
////Survey List

export const GetAllSurveyListActions = {
  FETCH_ALL_SURVEY_LIST: "FETCH_ALL_SURVEY_LIST",
  FETCH_ALL_SURVEY_LIST_STATUS: "FETCH_ALL_SURVEY_LIST_STATUS",
  FETCH_ALL_SURVEY_LIST_FULFILLED: "FETCH_ALL_SURVEY_LIST_FULFILLED",
  FETCH_ALL_SURVEY_LIST_REJECTED: "FETCH_ALL_SURVEY_LIST_REJECTED",
};
export function getAllSurveyListByStatus(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${SurveyService_URL}/Apc_View/Amc_30/findAllSurveyPagination?start=0&length=10&sortOrder=desc&sortField=id&status=true&search=&searchCol=&type=1,2&surveyStatus=` +
        params.surveyStatus,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        // dispatch(getAllSurveyListByStatusRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getAllSurveyListByStatusSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getAllSurveyListByStatusError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getAllSurveyListByStatusRequest() {
  return {
    type: GetAllSurveyListActions.FETCH_ALL_SURVEY_LIST,
    isPosting: true,
  };
}
export function getAllSurveyListByStatusError(error) {
  return {
    type: GetAllSurveyListActions.FETCH_ALL_SURVEY_LIST_REJECTED,
    payload: error,
  };
}

export function getAllSurveyListByStatusSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetAllSurveyListActions.FETCH_ALL_SURVEY_LIST_FULFILLED,
      payload: props,
    });
  };
}

//////////Survey Rules Action
export const SaveSurveyRulesActions = {
  SAVE_SURVEY_RULES: "SAVE_SURVEY_RULES",
  SAVE_SURVEY_RULES_STATUS: "SAVE_SURVEY_RULES_STATUS",
  SAVE_SURVEY_RULES_SUCCESS: "SAVE_SURVEY_RULES_SUCCESS",
  SAVE_SURVEY_RULES_FAILURE: "SAVE_SURVEY_RULES_FAILURE",
};

export function SaveSurveyRulesRequest() {
  return {
    type: SaveSurveyRulesActions.SAVE_SURVEY_RULES,
    isPosting: true,
  };
}

export function SaveSurveyRulesSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveSurveyRulesActions.SAVE_SURVEY_RULES_SUCCESS,
      payload: props,
    });
  };
}

export function SaveSurveyRulesFailure(error) {
  return {
    type: SaveSurveyRulesActions.SAVE_SURVEY_RULES_FAILURE,
    payload: error,
  };
}

export function SaveSurveyRulesError(error) {
  return {
    type: SaveSurveyRulesActions.SAVE_SURVEY_RULES_FAILURE,
    payload: error,
  };
}

export function saveSurveyRules(props, access_token) {
  // var value = {
  //   id: props.legalBasisId || null,
  //   name: props.legalBasisName
  // };

  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${SurveyService_URL}/Apc_Persist/Amc_31/saveSurveyRules`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        //   dispatch(SaveSurveyRulesRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveSurveyRulesSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(SaveSurveyRulesError(error));
          // return response;
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export const GetSurveyRulesDetailsActions = {
  FETCH_RULES_DETAILS: "FETCH_RULES_DETAILS",
  FETCH_RULES_DETAILS_STATUS: "FETCH_RULES_DETAILS_STATUS",
  FETCH_RULES_DETAILS_FULFILLED: "FETCH_RULES_DETAILS_FULFILLED",
  FETCH_RULES_DETAILS_REJECTED: "FETCH_RULES_DETAILS_REJECTED",
  // SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getRulesDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${SurveyService_URL}/Apc_View/Amc_31/getSurveyRules/` +
        params.surveyId,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        // dispatch(getRulesDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getRulesDetailsSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getRulesDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getRulesDetailsRequest() {
  return {
    type: GetSurveyRulesDetailsActions.FETCH_RULES_DETAILS,
    isPosting: true,
  };
}
export function getRulesDetailsError(error) {
  return {
    type: GetSurveyRulesDetailsActions.FETCH_RULES_DETAILS_REJECTED,
    payload: error,
  };
}

export function getRulesDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetSurveyRulesDetailsActions.FETCH_RULES_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getRulesDetailsStatus() {
  return (dispatch) => dispatch(getRulesDetailsStatusRequest());
}
export function getRulesDetailsStatusRequest() {
  return {
    type: GetSurveyRulesDetailsActions.FETCH_RULES_DETAILS_STATUS,
  };
}
export const DelSurveyRulesActions = {
  DEL_SURVEY_PAGE: "DEL_SURVEY_PAGE",
  DEL_SURVEY_PAGE_STATUS: "DEL_SURVEY_PAGE_STATUS",
  DEL_SURVEY_PAGE_SUCCESS: "DEL_SURVEY_PAGE_SUCCESS",
  DEL_SURVEY_PAGE_FAILURE: "DEL_SURVEY_PAGE_FAILURE",
};

export function DelSurveyRulesRequest() {
  return {
    type: DelSurveyRulesActions.DEL_SURVEY_PAGE,
    isPosting: true,
  };
}

export function DelSurveyRulesSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: DelSurveyRulesActions.DEL_SURVEY_PAGE_SUCCESS,
      payload: props,
    });
  };
}

export function DelSurveyRulesFailure(error) {
  return {
    type: DelSurveyRulesActions.DEL_SURVEY_PAGE_FAILURE,
    payload: error,
  };
}

export function DelSurveyRulesError(error) {
  return {
    type: DelSurveyRulesActions.DEL_SURVEY_PAGE_FAILURE,
    payload: error,
  };
}

export function delSurveyRule(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: props,
      url:
        `${SurveyService_URL}/Apc_State/Amc_31/deleteSurveyRules/` +
        params.ruleId,
      headers: {
        authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        //   dispatch(DelSurveyRulesRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(DelSurveyRulesSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(DelSurveyRulesError(error));
          // return response;
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

////Get Surrvey By Rules/////

export const GetSurveyByRulesActions = {
  FETCH_SURVEY_BY_RULES: "FETCH_SURVEY_BY_RULES",
  FETCH_SURVEY_BY_RULES_STATUS: "FETCH_SURVEY_BY_RULES_STATUS",
  FETCH_SURVEY_BY_RULES_FULFILLED: "FETCH_SURVEY_BY_RULES_FULFILLED",
  FETCH_SURVEY_BY_RULES_REJECTED: "FETCH_SURVEY_BY_RULES_REJECTED",
  // SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getSurveyByRules(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url: `${SurveyService_URL}/Apc_View/Amc_31/getSurvey/` + params.surveyId,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        // dispatch(getSurveyByRulesRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getSurveyByRulesSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getSurveyByRulesError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getSurveyByRulesRequest() {
  return {
    type: GetSurveyByRulesActions.FETCH_SURVEY_BY_RULES,
    isPosting: true,
  };
}
export function getSurveyByRulesError(error) {
  return {
    type: GetSurveyByRulesActions.FETCH_SURVEY_BY_RULES_REJECTED,
    payload: error,
  };
}

export function getSurveyByRulesSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetSurveyByRulesActions.FETCH_SURVEY_BY_RULES_FULFILLED,
      payload: props,
    });
  };
}

export function getSurveyByRulesStatus() {
  return (dispatch) => dispatch(getSurveyByRulesStatusRequest());
}
export function getSurveyByRulesStatusRequest() {
  return {
    type: GetSurveyByRulesActions.FETCH_SURVEY_BY_RULES_STATUS,
  };
}

// export function setDetailsError() {
//   return (dispatch) => dispatch(getRulesDetailsErrorRequest());
// }
// export function getRulesDetailsErrorRequest() {
//   return {
//     type: GetSurveyRulesDetailsActions.SET_EDIT_ERROR,
//   };
// }

////////Risk Assessment score/////

//////////Survey RiskScore Action
export const SaveSurveyRiskScoreActions = {
  SAVE_SURVEY_RISKSCORE: "SAVE_SURVEY_RISKSCORE",
  SAVE_SURVEY_RISKSCORE_STATUS: "SAVE_SURVEY_RISKSCORE_STATUS",
  SAVE_SURVEY_RISKSCORE_SUCCESS: "SAVE_SURVEY_RISKSCORE_SUCCESS",
  SAVE_SURVEY_RISKSCORE_FAILURE: "SAVE_SURVEY_RISKSCORE_FAILURE",
};

export function SaveSurveyRiskScoreRequest() {
  return {
    type: SaveSurveyRiskScoreActions.SAVE_SURVEY_RISKSCORE,
    isPosting: true,
  };
}

export function SaveSurveyRiskScoreSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveSurveyRiskScoreActions.SAVE_SURVEY_RISKSCORE_SUCCESS,
      payload: props,
    });
  };
}

export function SaveSurveyRiskScoreFailure(error) {
  return {
    type: SaveSurveyRiskScoreActions.SAVE_SURVEY_RISKSCORE_FAILURE,
    payload: error,
  };
}

export function SaveSurveyRiskScoreError(error) {
  return {
    type: SaveSurveyRiskScoreActions.SAVE_SURVEY_RISKSCORE_FAILURE,
    payload: error,
  };
}

export function saveSurveyRiskScore(props, access_token) {
  // var value = {
  //   id: props.legalBasisId || null,
  //   name: props.legalBasisName
  // };

  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${SurveyService_URL}/Apc_Persist/Amc_30/saveSurveyElementRisk`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        //   dispatch(SaveSurveyRiskScoreRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveSurveyRiskScoreSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(SaveSurveyRiskScoreError(error));
          // return response;
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export const GetSurveyRiskScoreDetailsActions = {
  FETCH_RISKSCORE_DETAILS: "FETCH_RISKSCORE_DETAILS",
  FETCH_RISKSCORE_DETAILS_STATUS: "FETCH_RISKSCORE_DETAILS_STATUS",
  FETCH_RISKSCORE_DETAILS_FULFILLED: "FETCH_RISKSCORE_DETAILS_FULFILLED",
  FETCH_RISKSCORE_DETAILS_REJECTED: "FETCH_RISKSCORE_DETAILS_REJECTED",
  // SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getRiskScoreDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${SurveyService_URL}/Apc_View/Amc_30/getSurveyElementRisk/` +
        params.surveyId,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        // dispatch(getRiskScoreDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getRiskScoreDetailsSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getRiskScoreDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getRiskScoreDetailsRequest() {
  return {
    type: GetSurveyRiskScoreDetailsActions.FETCH_RISKSCORE_DETAILS,
    isPosting: true,
  };
}
export function getRiskScoreDetailsError(error) {
  return {
    type: GetSurveyRiskScoreDetailsActions.FETCH_RISKSCORE_DETAILS_REJECTED,
    payload: error,
  };
}

export function getRiskScoreDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetSurveyRiskScoreDetailsActions.FETCH_RISKSCORE_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getRiskScoreDetailsStatus() {
  return (dispatch) => dispatch(getRiskScoreDetailsStatusRequest());
}
export function getRiskScoreDetailsStatusRequest() {
  return {
    type: GetSurveyRiskScoreDetailsActions.FETCH_RISKSCORE_DETAILS_STATUS,
  };
}
export const DelSurveyRiskScoreActions = {
  DEL_SURVEY_PAGE: "DEL_SURVEY_PAGE",
  DEL_SURVEY_PAGE_STATUS: "DEL_SURVEY_PAGE_STATUS",
  DEL_SURVEY_PAGE_SUCCESS: "DEL_SURVEY_PAGE_SUCCESS",
  DEL_SURVEY_PAGE_FAILURE: "DEL_SURVEY_PAGE_FAILURE",
};

export function DelSurveyRiskScoreRequest() {
  return {
    type: DelSurveyRiskScoreActions.DEL_SURVEY_PAGE,
    isPosting: true,
  };
}

export function DelSurveyRiskScoreSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: DelSurveyRiskScoreActions.DEL_SURVEY_PAGE_SUCCESS,
      payload: props,
    });
  };
}

export function DelSurveyRiskScoreFailure(error) {
  return {
    type: DelSurveyRiskScoreActions.DEL_SURVEY_PAGE_FAILURE,
    payload: error,
  };
}

export function DelSurveyRiskScoreError(error) {
  return {
    type: DelSurveyRiskScoreActions.DEL_SURVEY_PAGE_FAILURE,
    payload: error,
  };
}

export function delSurveyRiskScore(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: props,
      url:
        `${SurveyService_URL}/Apc_State/Amc_31/deleteSurveyRiskScore/` +
        params.ruleId,
      headers: {
        authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        //   dispatch(DelSurveyRiskScoreRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(DelSurveyRiskScoreSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(DelSurveyRiskScoreError(error));
          // return response;
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

////Get Surrvey By RiskScore/////

export const GetSurveyByRiskScoreActions = {
  FETCH_SURVEY_BY_RISKSCORE: "FETCH_SURVEY_BY_RISKSCORE",
  FETCH_SURVEY_BY_RISKSCORE_STATUS: "FETCH_SURVEY_BY_RISKSCORE_STATUS",
  FETCH_SURVEY_BY_RISKSCORE_FULFILLED: "FETCH_SURVEY_BY_RISKSCORE_FULFILLED",
  FETCH_SURVEY_BY_RISKSCORE_REJECTED: "FETCH_SURVEY_BY_RISKSCORE_REJECTED",
  // SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getSurveyByRiskScore(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url: `${SurveyService_URL}/Apc_View/Amc_31/getSurvey/` + params.surveyId,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        // dispatch(getSurveyByRiskScoreRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getSurveyByRiskScoreSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getSurveyByRiskScoreError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getSurveyByRiskScoreRequest() {
  return {
    type: GetSurveyByRiskScoreActions.FETCH_SURVEY_BY_RISKSCORE,
    isPosting: true,
  };
}
export function getSurveyByRiskScoreError(error) {
  return {
    type: GetSurveyByRiskScoreActions.FETCH_SURVEY_BY_RISKSCORE_REJECTED,
    payload: error,
  };
}

export function getSurveyByRiskScoreSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetSurveyByRiskScoreActions.FETCH_SURVEY_BY_RISKSCORE_FULFILLED,
      payload: props,
    });
  };
}

export function getSurveyByRiskScoreStatus() {
  return (dispatch) => dispatch(getSurveyByRiskScoreStatusRequest());
}
export function getSurveyByRiskScoreStatusRequest() {
  return {
    type: GetSurveyByRiskScoreActions.FETCH_SURVEY_BY_RISKSCORE_STATUS,
  };
}

///Get Spider Chart Details by user and surveyId//
export const GetSpiderChartSurveyByUserActions = {
  FETCH_SPIDER_CHART_SURVEY_BY_USER_DETAILS:
    "FETCH_SPIDER_CHART_SURVEY_BY_USER_DETAILS",
  FETCH_SPIDER_CHART_SURVEY_BY_USER_DETAILS_STATUS:
    "FETCH_SPIDER_CHART_SURVEY_BY_USER_DETAILS_STATUS",
  FETCH_SPIDER_CHART_SURVEY_BY_USER_DETAILS_FULFILLED:
    "FETCH_SPIDER_CHART_SURVEY_BY_USER_DETAILS_FULFILLED",
  FETCH_SPIDER_CHART_SURVEY_BY_USER_DETAILS_REJECTED:
    "FETCH_SPIDER_CHART_SURVEY_BY_USER_DETAILS_REJECTED",
};

export function getSpiderChartSurveyByUser(params) {
  const finalUrl =
    typeof params.userId === "string"
      ? "Apc_Persist/Amc_30/getCategoryWisePointsForSpiderGraphToExternalUser?emailId"
      : "Apc_Persist/Amc_30/getCategoryWisePointsForSpiderGraph?userId";
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url: `${SurveyService_URL}/${finalUrl}=${params.userId}&surveyId=${params.surveyId}`,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getSpiderChartSurveyByUserRequest());

        if (response.status >= 200 && response.status < 300) {
          dispatch(getSpiderChartSurveyByUserSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getSpiderChartSurveyByUserError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getSpiderChartSurveyByUserRequest() {
  return {
    type: GetSpiderChartSurveyByUserActions.FETCH_SPIDER_CHART_SURVEY_BY_USER_DETAILS,
    isPosting: true,
  };
}
export function getSpiderChartSurveyByUserError(error) {
  return {
    type: GetSpiderChartSurveyByUserActions.FETCH_SPIDER_CHART_SURVEY_BY_USER_DETAILS_REJECTED,
    payload: error,
  };
}

export function getSpiderChartSurveyByUserSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetSpiderChartSurveyByUserActions.FETCH_SPIDER_CHART_SURVEY_BY_USER_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

//get spider chart for overall assessment

export const GetSpiderChartForSurveyActions = {
  FETCH_SPIDER_CHART_FOR_SURVEY: "FETCH_SPIDER_CHART_FOR_SURVEY",
  FETCH_SPIDER_CHART_FOR_SURVEY_FULFILLED:
    "FETCH_SPIDER_CHART_FOR_SURVEY_FULFILLED",
  FETCH_SPIDER_CHART_FOR_SURVEY_REJECTED:
    "FETCH_SPIDER_CHART_FOR_SURVEY_REJECTED",
};

export function getSpiderChartForSurvey(params) {
  return (dispatch) =>
    axios({
      method: "post",
      url: `${SurveyService_URL}/Apc_Persist/Amc_30/getAssessmentReveiewPointsForSpiderGraph?surveyId=${params.surveyId}`,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getSpiderChartForSurveyRequest());

        if (response.status >= 200 && response.status < 300) {
          dispatch(getSpiderChartForSurveySuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getSpiderChartForSurveyError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getSpiderChartForSurveyRequest() {
  return {
    type: GetSpiderChartForSurveyActions.FETCH_SPIDER_CHART_FOR_SURVEY,
    isPosting: true,
  };
}
export function getSpiderChartForSurveyError(error) {
  return {
    type: GetSpiderChartForSurveyActions.FETCH_SPIDER_CHART_FOR_SURVEY_REJECTED,
    payload: error,
  };
}

export function getSpiderChartForSurveySuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetSpiderChartForSurveyActions.FETCH_SPIDER_CHART_FOR_SURVEY_FULFILLED,
      payload: props,
    });
  };
}

//////////Query Raise Action
export const RaiseQueryActions = {
  RAISE_QUERY: "RAISE_QUERY",
  RAISE_QUERY_STATUS: "RAISE_QUERY_STATUS",
  RAISE_QUERY_SUCCESS: "RAISE_QUERY_SUCCESS",
  RAISE_QUERY_FAILURE: "RAISE_QUERY_FAILURE",
};

export function RaiseQueryRequest() {
  return {
    type: RaiseQueryActions.RAISE_QUERY,
    isPosting: true,
  };
}

export function RaiseQuerySuccess(props) {
  return (dispatch) => {
    dispatch({
      type: RaiseQueryActions.RAISE_QUERY_SUCCESS,
      payload: props,
    });
  };
}

export function RaiseQueryFailure(error) {
  return {
    type: RaiseQueryActions.RAISE_QUERY_FAILURE,
    payload: error,
  };
}

export function RaiseQueryError(error) {
  return {
    type: RaiseQueryActions.RAISE_QUERY_FAILURE,
    payload: error,
  };
}

export function RaiseQuery(props, access_token) {
  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${QueryService_URL}/Apc_Persist/Amc_96/raisedQuery`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        //   dispatch(RaiseQueryRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(RaiseQuerySuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(RaiseQueryError(error));
          // return response;
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

///Get Raised Query Details
export const GetRaisedQueryDetailsActions = {
  FETCH_RAISED_QUERY_DETAILS: "FETCH_RAISED_QUERY_DETAILS",
  FETCH_RAISED_QUERY_DETAILS_STATUS: "FETCH_RAISED_QUERY_DETAILS_STATUS",
  FETCH_RAISED_QUERY_DETAILS_FULFILLED: "FETCH_RAISED_QUERY_DETAILS_FULFILLED",
  FETCH_RAISED_QUERY_DETAILS_REJECTED: "FETCH_RAISED_QUERY_DETAILS_REJECTED",
};

export function getRaisedQueryDetails(params) {
  const finalUrl =
    "Apc_View/Amc_96/getQueryDetailsByCreatedByUserIdAndSurveyId";
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url: `${QueryService_URL}/${finalUrl}?createdByUserId=${params.userId}&surveyId=${params.surveyId}`,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getRaisedQueryDetailsRequest());

        if (response.status >= 200 && response.status < 300) {
          dispatch(getRaisedQueryDetailsSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getRaisedQueryDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getRaisedQueryDetailsRequest() {
  return {
    type: GetRaisedQueryDetailsActions.FETCH_RAISED_QUERY_DETAILS,
    isPosting: true,
  };
}
export function getRaisedQueryDetailsError(error) {
  return {
    type: GetRaisedQueryDetailsActions.FETCH_RAISED_QUERY_DETAILS_REJECTED,
    payload: error,
  };
}

export function getRaisedQueryDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetRaisedQueryDetailsActions.FETCH_RAISED_QUERY_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function validateAddAssesment(access_token) {
  return (dispatch) =>
    axios({
      method: "post",
      url: `${SurveyService_URL}/companyAssessmentLimit`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);

          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          // switch (err.response.status) {
          //   case 400:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));
          //     break;
          //   case 404:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));

          //     break;
          //   case 406:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));
          //     break;
          //   case 500:
          //     err = { message: "Server Error" };
          //     break;
          //   default:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));

          //     break;
          // }
          return err.response;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

//// get review master values

export const GetSurveyMasterActions = {
  FETCH_MASTER_DETAILS_STATUS: "FETCH_MASTER_DETAILS_STATUS",
  FETCH_MASTER_DETAILS_FULFILLED: "FETCH_MASTER_DETAILS_FULFILLED",
  FETCH_MASTER_DETAILS_REJECTED: "FETCH_MASTER_DETAILS_REJECTED",
};

export function getSurveyReviewMasterDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      url: `${SurveyService_URL}/Apc_View/Amc_31/getAssessmentReviewMaster`,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          dispatch(getSurveyReviewMasterDetailsSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getSurveyMasterDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function getSurveyMasterDetailsError(error) {
  return {
    type: GetSurveyMasterActions.FETCH_MASTER_DETAILS_REJECTED,
    payload: error,
  };
}

export function getSurveyReviewMasterDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetSurveyMasterActions.FETCH_MASTER_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getSurveyMasterDetailsStatus() {
  return (dispatch) => dispatch(getSurveyMasterDetailsStatusRequest());
}

export function getSurveyMasterDetailsStatusRequest() {
  return {
    type: GetSurveyMasterActions.FETCH_MASTER_DETAILS_STATUS,
  };
}

//// save master

export const SaveSurveyMasterActions = {
  SAVE_SURVEY_MASTER_SUCCESS: "SAVE_SURVEY_MASTER_SUCCESS",
  SAVE_SURVEY_MASTER_FAILURE: "SAVE_SURVEY_MASTER_FAILURE",
  LOGOUT: "LOGOUT",
};

export function SaveSurveyMasterSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveSurveyMasterActions.SAVE_SURVEY_MASTER_SUCCESS,
      payload: props,
    });
  };
}

export function SaveSurveyMasterError(error) {
  return {
    type: SaveSurveyMasterActions.SAVE_SURVEY_MASTER_FAILURE,
    payload: error,
  };
}

export function saveMasterSurvey(props, access_token) {
  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${SurveyService_URL}/Apc_Persist/Amc_30/saveSurveyAssessmentReview`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveSurveyMasterSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(SaveSurveyMasterError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

///////GET Query Details ByAssignedToUserId

export const GetQueryDetailsActions = {
  FETCH_QUERY_DETAILS_BY_ASSIGNED_TO_USERID:
    "FETCH_QUERY_DETAILS_BY_ASSIGNED_TO_USERID",
  FETCH_QUERY_DETAILS_BY_ASSIGNED_TO_USERID_STATUS:
    "FETCH_QUERY_DETAILS_BY_ASSIGNED_TO_USERID_STATUS",
  FETCH_QUERY_DETAILS_BY_ASSIGNED_TO_USERID_FULFILLED:
    "FETCH_QUERY_DETAILS_BY_ASSIGNED_TO_USERID_FULFILLED",
  FETCH_QUERY_DETAILS_BY_ASSIGNED_TO_USERID_REJECTED:
    "FETCH_QUERY_DETAILS_BY_ASSIGNED_TO_USERID_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getQueryDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url: `${QueryService_URL}/Apc_View/Amc_96/getQueryDetailsByAssignedToUserId?assignedToUserId=${params.assignedToUserId}&surveyId=${params.surveyId}`,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getQueryDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getQueryDetailsSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(getQueryDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getQueryDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getQueryDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getQueryDetailsError(err));
              break;
            case 500:
              dispatch(getQueryDetailsError("Server Error"));
              break;
            default:
              dispatch(getQueryDetailsError("Unknown Error"));
              break;
          }
        } else {
          // dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getQueryDetailsRequest() {
  return {
    type: GetQueryDetailsActions.FETCH_QUERY_DETAILS_BY_ASSIGNED_TO_USERID,
    isPosting: true,
  };
}
export function getQueryDetailsError(error) {
  return {
    type: GetQueryDetailsActions.FETCH_QUERY_DETAILS_BY_ASSIGNED_TO_USERID_REJECTED,
    payload: error,
  };
}

export function getQueryDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetQueryDetailsActions.FETCH_QUERY_DETAILS_BY_ASSIGNED_TO_USERID_FULFILLED,
      payload: props,
    });
  };
}

export function getQueryDetailsStatus() {
  return (dispatch) => dispatch(getQueryDetailsStatusRequest());
}
export function getQueryDetailsStatusRequest() {
  return {
    type: GetQueryDetailsActions.FETCH_QUERY_DETAILS_BY_ASSIGNED_TO_USERID_STATUS,
  };
}

// Save Mark as Resolved

export const SaveMarkAsResolvedActions = {
  SAVE_MARK_AS_RESOLVED: "SAVE_MARK_AS_RESOLVED",
  SAVE_MARK_AS_RESOLVED_STATUS: "SAVE_MARK_AS_RESOLVED_STATUS",
  SAVE_MARK_AS_RESOLVED_SUCCESS: "SAVE_MARK_AS_RESOLVED_SUCCESS",
  SAVE_MARK_AS_RESOLVED_FAILURE: "SAVE_MARK_AS_RESOLVED_FAILURE",
};

export function SaveMarkAsResolvedRequest() {
  return {
    type: SaveMarkAsResolvedActions.SAVE_MARK_AS_RESOLVED,
    isPosting: true,
  };
}

export function SaveMarkAsResolvedSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveMarkAsResolvedActions.SAVE_MARK_AS_RESOLVED_SUCCESS,
      payload: props,
    });
  };
}

export function SaveMarkAsResolvedFailure(error) {
  return {
    type: SaveMarkAsResolvedActions.SAVE_MARK_AS_RESOLVED_FAILURE,
    payload: error,
  };
}

export function SaveMarkAsResolvedError(error) {
  return {
    type: SaveMarkAsResolvedActions.SAVE_MARK_AS_RESOLVED_FAILURE,
    payload: error,
  };
}

export function saveMarkAsResolved(props) {
  // var value = {
  //   id: props.legalBasisId || null,
  //   name: props.legalBasisName
  // };

  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${QueryService_URL}/Apc_Persist/Amc_96/markAsResolved?queryId=${props.queryId}&userId=${props.userId}`,
      headers: {
        authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        dispatch(SaveMarkAsResolvedRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveMarkAsResolvedSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(SaveMarkAsResolvedError(error));
          // return response;
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}


// Copy Template

export const SaveCopyTemplateActions = {
  SAVE_COPYTEMPLATE: "SAVE_COPYTEMPLATE",
  SAVE_COPYTEMPLATE_STATUS: "SAVE_COPYTEMPLATE_STATUS",
  SAVE_COPYTEMPLATE_SUCCESS: "SAVE_COPYTEMPLATE_SUCCESS",
  SAVE_COPYTEMPLATE_FAILURE: "SAVE_COPYTEMPLATE_FAILURE",
  LOGOUT: "LOGOUT",
};

export function SaveCopyTemplateRequest() {
  return {
    type: SaveCopyTemplateActions.SAVE_COPYTEMPLATE,
    isPosting: true,
  };
}

export function SaveCopyTemplateSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveCopyTemplateActions.SAVE_COPYTEMPLATE_SUCCESS,
      payload: props,
    });
  };
}

export function SaveCopyTemplateFailure(error) {
  return {
    type: SaveCopyTemplateActions.SAVE_COPYTEMPLATE_FAILURE,
    payload: error,
  };
}

export function SaveCopyTemplateError(error) {
  return {
    type: SaveCopyTemplateActions.SAVE_COPYTEMPLATE_FAILURE,
    payload: error,
  };
}

export function saveCopyTemplate(props, access_token) {
  // var value = {
  //   id: props.legalBasisId || null,
  //   name: props.legalBasisName
  // };

  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      // url: `${SurveyService_URL}/Apc_Persist/Amc_30/saveSurveyPages`,
      url: `${SurveyService_URL}/Apc_Persist/Amc_30/saveCopyTemplete`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        dispatch(SaveCopyTemplateRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveCopyTemplateSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(SaveCopyTemplateError(error));
          // return response;
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}