import axios from "axios";
import {
  CompanyService_URL,
  // SystemService_URL
  // CompanyService_URL
} from "../Url";
import HashMap from "hashmap";
import { clearLogoutData } from "../Logout/actions";

var map = new HashMap();

function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

// Save AssetType
export function logout() {
  return {
    type: SaveOrganisationActions.LOGOUT,
    payload: "Session Expired. Please Login Again",
  };
}
export const SaveOrganisationActions = {
  SAVE_ORGANISATION: "SAVE_ORGANISATION",
  SAVE_ORGANISATION_STATUS: "SAVE_ORGANISATION_STATUS",
  SAVE_ORGANISATION_SUCCESS: "SAVE_ORGANISATION_SUCCESS",
  SAVE_ORGANISATION_FAILURE: "SAVE_ORGANISATION_FAILURE",
  LOGOUT: "LOGOUT",
};

export function SaveOrganisationRequest() {
  return {
    type: SaveOrganisationActions.SAVE_ORGANISATION,
    isPosting: true,
  };
}

export function SaveOrganisationSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveOrganisationActions.SAVE_ORGANISATION_SUCCESS,
      payload: props,
    });
  };
}

export function SaveOrganisationFailure(error) {
  return {
    type: SaveOrganisationActions.SAVE_ORGANISATION_FAILURE,
    payload: error,
  };
}

export function SaveOrganisationError(error) {
  return {
    type: SaveOrganisationActions.SAVE_ORGANISATION_FAILURE,
    payload: error,
  };
}

export function saveOrganisation(props, access_token) {
  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${CompanyService_URL}/Apc_Persist/Amc_34/saveOrganizationMaster?isOrganization=false`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        dispatch(SaveOrganisationRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveOrganisationSuccess(response));
          return response;
        } else if (response.status >= 401 && response.status < 500) {
          
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(SaveOrganisationError(error));
          throw error;
          // return response;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(SaveOrganisationError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(SaveOrganisationError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(SaveOrganisationError(err));
              break;
            case 500:
              dispatch(SaveOrganisationError("Server Error"));
              break;
            default:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(SaveOrganisationError(err));
              break;
          }
        } else {
          //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// clear  details
export const ClearFormDetailsActions = {
  CLEAR_FORM_DETAILS: "CLEAR_FORM_DETAILS",
};

export function clearFormData() {
  return (dispatch) => dispatch(clearFormDetails());
}

export function clearFormDetails() {
  return {
    type: ClearFormDetailsActions.CLEAR_FORM_DETAILS,
  };
}

// get  details by id

export const GetOrganisationDetailsActions = {
  FETCH_ORGANISATION_DETAILS: "FETCH_ORGANISATION_DETAILS",
  FETCH_ORGANISATION_DETAILS_STATUS: "FETCH_ORGANISATION_DETAILS_STATUS",
  FETCH_ORGANISATION_DETAILS_FULFILLED: "FETCH_ORGANISATION_DETAILS_FULFILLED",
  FETCH_ORGANISATION_DETAILS_REJECTED: "FETCH_ORGANISATION_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getOrganisationDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${CompanyService_URL}/Apc_View/Amc_34/getOrganizationById/` +
        params.organizationId,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getOrganisationDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getOrganisationDetailsSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getOrganisationDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getOrganisationDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getOrganisationDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getOrganisationDetailsError(err));
              break;
            case 500:
              dispatch(getOrganisationDetailsError("Server Error"));
              break;
            default:
              dispatch(getOrganisationDetailsError("Unknown Error"));
              break;
          }
        } else {
          //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getOrganisationDetailsRequest() {
  return {
    type: GetOrganisationDetailsActions.FETCH_ORGANISATION_DETAILS,
    isPosting: true,
  };
}
export function getOrganisationDetailsError(error) {
  return {
    type: GetOrganisationDetailsActions.FETCH_ORGANISATION_DETAILS_REJECTED,
    payload: error,
  };
}

export function getOrganisationDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetOrganisationDetailsActions.FETCH_ORGANISATION_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getOrganisationDetailsStatus() {
  return (dispatch) => dispatch(getOrganisationDetailsStatusRequest());
}
export function getOrganisationDetailsStatusRequest() {
  return {
    type: GetOrganisationDetailsActions.FETCH_ORGANISATION_DETAILS_STATUS,
  };
}

export function setDetailsError() {
  return (dispatch) => dispatch(getOrganisationDetailsErrorRequest());
}
export function getOrganisationDetailsErrorRequest() {
  return {
    type: GetOrganisationDetailsActions.SET_EDIT_ERROR,
  };
}

export const GetActiveOrganisationActions = {
  FETCH_ACTIVE_ORGANISATION: "FETCH_ACTIVE_ORGANISATION",
  FETCH_ACTIVE_ORGANISATION_STATUS: "FETCH_ACTIVE_ORGANISATION_STATUS",
  FETCH_ACTIVE_ORGANISATION_FULFILLED: "FETCH_ACTIVE_ORGANISATION_FULFILLED",
  FETCH_ACTIVE_ORGANISATION_REJECTED: "FETCH_ACTIVE_ORGANISATION_REJECTED",
};
export function getActiveOrganisationList(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url: `${CompanyService_URL}/Apc_View/Amc_34/getAllOrganizationMaster`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        //console("Inside Hierarchy ",response)
        dispatch(getActiveOrganisationListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveOrganisationListSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getActiveOrganisationListError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveOrganisationListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveOrganisationListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveOrganisationListError(err));
              break;
            case 500:
              dispatch(getActiveOrganisationListError("Server Error"));
              break;
            default:
              dispatch(getActiveOrganisationListError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveOrganisationListRequest() {
  return {
    type: GetActiveOrganisationActions.FETCH_ACTIVE_ORGANISATION,
    isPosting: true,
  };
}
export function getActiveOrganisationListError(error) {
  return {
    type: GetActiveOrganisationActions.FETCH_ACTIVE_ORGANISATION_REJECTED,
    payload: error,
  };
}

export function getActiveOrganisationListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveOrganisationActions.FETCH_ACTIVE_ORGANISATION_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveOrganisationListStatus() {
  return (dispatch) => dispatch(getActiveOrganisationListStatusRequest());
}
export function getActiveOrganisationListStatusRequest() {
  return {
    type: GetActiveOrganisationActions.FETCH_ACTIVE_ORGANISATION_STATUS,
  };
}

/////////////////////////////Get Organization Hierarchy////////////////////////////////////////

export const GetActiveOrganisationHierarchyActions = {
  FETCH_ACTIVE_ORGANISATION_HIERARCHY: "FETCH_ACTIVE_ORGANISATION_HIERARCHY",
  FETCH_ACTIVE_ORGANISATION_STATUS_HIERARCHY:
    "FETCH_ACTIVE_ORGANISATION_STATUS_HIERARCHY",
  FETCH_ACTIVE_ORGANISATION_FULFILLED_HIERARCHY:
    "FETCH_ACTIVE_ORGANISATION_FULFILLED_HIERARCHY",
  FETCH_ACTIVE_ORGANISATION_REJECTED_HIERARCHY:
    "FETCH_ACTIVE_ORGANISATION_REJECTED_HIERARCHY",
};
export function getActiveOrganisationHierarchyList(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url: `${CompanyService_URL}/Apc_View/Amc_34/getAllOrganizationHierarchy`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveOrganisationHierarchyListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveOrganisationHierarchyListSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getActiveOrganisationHierarchyListError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveOrganisationHierarchyListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveOrganisationHierarchyListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveOrganisationHierarchyListError(err));
              break;
            case 500:
              dispatch(getActiveOrganisationHierarchyListError("Server Error"));
              break;
            default:
              dispatch(
                getActiveOrganisationHierarchyListError("Unknown Error")
              );
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveOrganisationHierarchyListRequest() {
  return {
    type:
      GetActiveOrganisationHierarchyActions.FETCH_ACTIVE_ORGANISATION_HIERARCHY,
    isPosting: true,
  };
}
export function getActiveOrganisationHierarchyListError(error) {
  return {
    type:
      GetActiveOrganisationHierarchyActions.FETCH_ACTIVE_ORGANISATION_REJECTED_HIERARCHY,
    payload: error,
  };
}

export function getActiveOrganisationHierarchyListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type:
        GetActiveOrganisationHierarchyActions.FETCH_ACTIVE_ORGANISATION_FULFILLED_HIERARCHY,
      payload: props,
    });
  };
}

export function getActiveOrganisationHierarchyListStatus() {
  return (dispatch) =>
    dispatch(getActiveOrganisationHierarchyListStatusRequest());
}
export function getActiveOrganisationHierarchyListStatusRequest() {
  return {
    type:
      GetActiveOrganisationHierarchyActions.FETCH_ACTIVE_ORGANISATION_STATUS_HIERARCHY,
  };
}

////////////////////////////GET ORG HIERARCHY BY ORGID//////////////////////////////////////////

export const GetActiveOrganisationByIdHierarchyActions = {
  FETCH_ACTIVE_ORGANISATION_BYID_HIERARCHY:
    "FETCH_ACTIVE_ORGANISATION_BYID_HIERARCHY",
  FETCH_ACTIVE_ORGANISATION_BYID_STATUS_HIERARCHY:
    "FETCH_ACTIVE_ORGANISATION_BYID_STATUS_HIERARCHY",
  FETCH_ACTIVE_ORGANISATION_BYID_FULFILLED_HIERARCHY:
    "FETCH_ACTIVE_ORGANISATION_BYID_FULFILLED_HIERARCHY",
  FETCH_ACTIVE_ORGANISATION_BYID_REJECTED_HIERARCHY:
    "FETCH_ACTIVE_ORGANISATION_BYID_REJECTED_HIERARCHY",
};
export function getActiveOrganisationByIdHierarchyList(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${CompanyService_URL}/Apc_View/Amc_34/getAllOrganizationHierarchy/` +
        params.organizationId,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveOrganisationByIdHierarchyListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveOrganisationByIdHierarchyListSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getActiveOrganisationByIdHierarchyListError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveOrganisationByIdHierarchyListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveOrganisationByIdHierarchyListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveOrganisationByIdHierarchyListError(err));
              break;
            case 500:
              dispatch(
                getActiveOrganisationByIdHierarchyListError("Server Error")
              );
              break;
            default:
              dispatch(
                getActiveOrganisationByIdHierarchyListError("Unknown Error")
              );
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveOrganisationByIdHierarchyListRequest() {
  return {
    type:
      GetActiveOrganisationByIdHierarchyActions.FETCH_ACTIVE_ORGANISATION_BYID_HIERARCHY,
    isPosting: true,
  };
}
export function getActiveOrganisationByIdHierarchyListError(error) {
  return {
    type:
      GetActiveOrganisationByIdHierarchyActions.FETCH_ACTIVE_ORGANISATION_BYID_REJECTED_HIERARCHY,
    payload: error,
  };
}

export function getActiveOrganisationByIdHierarchyListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type:
        GetActiveOrganisationByIdHierarchyActions.FETCH_ACTIVE_ORGANISATION_BYID_FULFILLED_HIERARCHY,
      payload: props,
    });
  };
}

export function getActiveOrganisationByIdHierarchyListStatus() {
  return (dispatch) =>
    dispatch(getActiveOrganisationByIdHierarchyListRequest());
}
// export function getActiveOrganisationByIdHierarchyListRequest() {
//   return {
//     type: GetActiveOrganisationByIdHierarchyActions.FETCH_ACTIVE_ORGANISATION_BYID_STATUS_HIERARCHY
//   };
// }
////////////////////////////GET ORG HIERARCHY BY ORGID//////////////////////////////////////////

export const GetAllOrgNoHierarchyActions = {
  FETCH_ORG_NO_HIER: "FETCH_ORG_NO_HIER",
  FETCH_ORG_NO_HIER_STATUS: "FETCH_ORG_NO_HIER_STATUS",
  FETCH_ORG_NO_HIER_FULFILLED: "FETCH_ORG_NO_HIER_FULFILLED",
  FETCH_ORG_NO_HIER_REJECTED: "FETCH_ORG_NO_HIER_REJECTED",
};

export function getOrgNoHierList(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url: `${CompanyService_URL}/Apc_View/Amc_34/getAllOrganizationNoHierarchy`,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getOrgNoHierListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getOrgNoHierListSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getOrgNoHierListError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
          // switch (err.response.status) {
          //   case 400:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));

          //     dispatch(getOrgNoHierListError(err));
          //     break;
          //   case 404:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));

          //     dispatch(getOrgNoHierListError(err));
          //     break;
          //   case 406:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));

          //     dispatch(getOrgNoHierListError(err));
          //     break;
          //   case 500:
          //     dispatch(getOrgNoHierListError("Server Error"));
          //     break;
          //   default:
          //     dispatch(getOrgNoHierListError("Unknown Error"));
          //     break;
          // }
        } else {
          //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getOrgNoHierListRequest() {
  return {
    type: GetAllOrgNoHierarchyActions.FETCH_ORG_NO_HIER,
    isPosting: true,
  };
}
export function getOrgNoHierListError(error) {
  return {
    type: GetAllOrgNoHierarchyActions.FETCH_ORG_NO_HIER_REJECTED,
    payload: error,
  };
}

export function getOrgNoHierListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetAllOrgNoHierarchyActions.FETCH_ORG_NO_HIER_FULFILLED,
      payload: props,
    });
  };
}

export function getOrgNoHierListStatus() {
  return (dispatch) => dispatch(getOrgNoHierListStatusRequest());
}
export function getOrgNoHierListStatusRequest() {
  return {
    type: GetAllOrgNoHierarchyActions.FETCH_ORG_NO_HIER_STATUS,
  };
}


////////////////////////////GET Responsible Dept//////////////////////////////////////////

export const GetAllResponsibleDeptActions = {
  FETCH_RESPONSIBLE_DEPT: "FETCH_RESPONSIBLE_DEPT",
  FETCH_RESPONSIBLE_DEPT_STATUS: "FETCH_RESPONSIBLE_DEPT_STATUS",
  FETCH_RESPONSIBLE_DEPT_FULFILLED: "FETCH_RESPONSIBLE_DEPT_FULFILLED",
  FETCH_RESPONSIBLE_DEPT_REJECTED: "FETCH_RESPONSIBLE_DEPT_REJECTED",
};

export function getAllResponsibleDeptList(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url: `${CompanyService_URL}/Apc_View/Amc_34/getAllOrganizationNoHierarchy`,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getResponsibleDeptListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getResponsibleDeptListSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getResponsibleDeptListError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
          // switch (err.response.status) {
          //   case 400:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));

          //     dispatch(getResponsibleDeptListError(err));
          //     break;
          //   case 404:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));

          //     dispatch(getResponsibleDeptListError(err));
          //     break;
          //   case 406:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach((ele) => (err = ele));

          //     dispatch(getResponsibleDeptListError(err));
          //     break;
          //   case 500:
          //     dispatch(getResponsibleDeptListError("Server Error"));
          //     break;
          //   default:
          //     dispatch(getResponsibleDeptListError("Unknown Error"));
          //     break;
          // }
        } else {
          //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getResponsibleDeptListRequest() {
  return {
    type: GetAllResponsibleDeptActions.FETCH_RESPONSIBLE_DEPT,
    isPosting: true,
  };
}
export function getResponsibleDeptListError(error) {
  return {
    type: GetAllResponsibleDeptActions.FETCH_RESPONSIBLE_DEPT_REJECTED,
    payload: error,
  };
}

export function getResponsibleDeptListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetAllResponsibleDeptActions.FETCH_RESPONSIBLE_DEPT_FULFILLED,
      payload: props,
    });
  };
}

export function getResponsibleDeptListStatus() {
  return (dispatch) => dispatch(getResponsibleDeptListStatusRequest());
}
export function getResponsibleDeptListStatusRequest() {
  return {
    type: GetAllResponsibleDeptActions.FETCH_RESPONSIBLE_DEPT_STATUS,
  };
}
