import axios from "axios";
import { clearLogoutData } from "../Logout/actions";
import {
  SuperAdmin_Service_URL,
  ArtistAdmin_Listing_Service_URL,
  AssetManagementService_URL,
  RiskManagementService_URL,
  SystemService_URL,
  UserManagementService_URL,
  RopaManagementService_URL,
  CompanyService_URL,
  ConsentManagementService_URL,
  EdgeService_URL,
  Server_IP,
  Column_URL,
  RequestManagement_URL,
  IncidentManagementService_URL,
  CookieService_Url,
  VendorService_URL,
  //RiskManagementService_Url
  SurveyService_URL,
  DataConnection_URL,
} from "../Url";
import HashMap from "hashmap";
import { getCurrentPageName } from "../../containers/listing/PageModule";

var map = new HashMap();
var err;
var dataToPost;
//////////////////////////////////////////////////////////////////////////////////////////////
function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}
// get current page name in caps
let PageName = getCurrentPageName();
console.log("PageName",PageName)
// set action type on page url {e.g "locationList" }
const FETCH_REQUEST = "FETCH_" + PageName;
const FETCH_FULFILLED = "FETCH_" + PageName + "_FULFILLED";
const FETCH_REJECTED = "FETCH_" + PageName + "_REJECTED";

// update listAction on page change
export function listActions(type) {
  if (type) {
    return (ListActions = {
      FETCH_LIST: type.FETCH_REQUEST,
      FETCH_LIST_FULFILLED: type.FETCH_FULFILLED,
      FETCH_LIST_REJECTED: type.FETCH_REJECTED,
      SET_APPLICATION_ERROR: "SET_APPLICATION_ERROR",
      LOGOUT: "LOGOUT",
    });
  }
}

// onload listActions
export let ListActions = {
  FETCH_LIST: FETCH_REQUEST,
  FETCH_LIST_FULFILLED: FETCH_FULFILLED,
  FETCH_LIST_REJECTED: FETCH_REJECTED,
  SET_APPLICATION_ERROR: "SET_APPLICATION_ERROR",
  LOGOUT: "LOGOUT",
};

// service call
export function getListing(props = {}) {
  const search = props.search || "";
  const statusID = props.statusID || "0";
  const active = props.active;
  const result = props.result || "10";
  const page = props.page || 0;
  const pageNameInState = props.currentPage;
  const sortOrder = props.sortOrder === false ? "asc" : "desc";
  const searchCol = (props.search === null ? "" : props.searchCol) || "";
  const access_token = props.access_token;
  const dmcId = props.dmcId;
  const companyId = props.companyId || "";

  PageName = getCurrentPageName();
  // set handler as per page, set your page url in caps
  let handler = "";
  let methodType = "";

  let data = [
    {
      columnID: 0,
      sequenceColumn: 0,
    },
  ];
  if (PageName === "ASSETTYPEMASTER" && pageNameInState === "assetTypeMaster") {
    methodType = "POST";
    const sortField = props.sortField || "assetTypeId";
    if (search !== "") {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },
        length: result,
        search: props.search,
        sort: props.sort,
        type: props.type,
        start: page,
        status: active,
      };
    } else {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },

        length: result,
        type: props.type,
        sort: props.sort,
        start: page,
        status: active,
      };
    }

    handler = `${AssetManagementService_URL}/Apc_View/Amc_9/listing/AssetType`;
  } else if (
    PageName === "CONSENTPOINTSLISTING" &&
    pageNameInState === "consentPointsListing"
  ) {
    methodType = "POST";
    const sortField = props.sortField || "id";
    if (search !== "") {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },
        length: result,
        search: props.search,
        sort: props.sort,
        type: props.type,
        start: page,
        status: active,
      };
    } else {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },

        length: result,
        type: props.type,
        sort: props.sort,
        start: page,
        status: active,
      };
    }

    handler = `${ConsentManagementService_URL}/Apc_View/Amc_52/listing/ConsentPoint`;
  } else if (
    PageName === "THREATLISTING" &&
    pageNameInState === "ThreatListing"
  ) {
    methodType = "POST";
    const sortField = props.sortField || "threatId";
    if (search !== "") {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },
        length: result,
        search: props.search,
        sort: props.sort,
        type: props.type,
        start: page,
        status: active,
      };
    } else {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },

        length: result,
        type: props.type,
        sort: props.sort,
        start: page,
        status: active,
      };
    }

    handler = `${RiskManagementService_URL}/listing/Threat`;
  } else if (
    PageName === "CONTROLREGISTERLISTING" &&
    pageNameInState === "ControlRegisterListing"
  ) {
    methodType = "POST";
    const sortField = props.sortField || "controlRegisterId";
    if (search !== "") {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },
        length: result,
        search: props.search,
        sort: props.sort,
        type: props.type,
        start: page,
        status: active,
      };
    } else {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },

        length: result,
        type: props.type,
        sort: props.sort,
        start: page,
        status: active,
      };
    }

    handler = `${RiskManagementService_URL}/listing/ControlRegister`;
  } else if (
    PageName === "RISKWORKFLOWLISTING" &&
    pageNameInState === "RiskWorkflowListing"
  ) {
    methodType = "POST";
    const sortField = props.sortField || "riskWorkFlowId";
    if (search !== "") {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },
        length: result,
        search: props.search,
        sort: props.sort,
        type: props.type,
        start: page,
        status: active,
      };
    } else {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },

        length: result,
        type: props.type,
        sort: props.sort,
        start: page,
        status: active,
      };
    }

    handler = `${RiskManagementService_URL}/listing/RiskWorkFlowMaster`;
  } else if (
    PageName === "CONTROLCATEGORIESLISTING" &&
    pageNameInState === "ControlCategoriesListing"
  ) {
    methodType = "POST";
    const sortField = props.sortField || "controlCategoryId";
    if (search !== "") {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },
        length: result,
        search: props.search,
        sort: props.sort,
        type: props.type,
        start: page,
        status: active,
      };
    } else {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },

        length: result,
        type: props.type,
        sort: props.sort,
        start: page,
        status: active,
      };
    }

    handler = `${RiskManagementService_URL}/listing/ControlCategory`;
  } else if (
    PageName === "CONTROLFRAMEWORKLISTING" &&
    pageNameInState === "ControlFrameworkListing"
  ) {
    methodType = "POST";
    const sortField = props.sortField || "controlFrameworkId";
    if (search !== "") {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },
        length: result,
        search: props.search,
        sort: props.sort,
        type: props.type,
        start: page,
        status: active,
      };
    } else {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },
        length: result,
        type: props.type,
        sort: props.sort,
        start: page,
        status: active,
      };
    }

    handler = `${RiskManagementService_URL}/listing/ControlFramework`;
  } else if (
    PageName === "CONTROLLIBRARYLISTING" &&
    pageNameInState === "ControlLibraryListing"
  ) {
    methodType = "POST";
    const sortField = props.sortField || "controlId";
    if (search !== "") {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },
        length: result,
        search: props.search,
        sort: props.sort,
        type: props.type,
        start: page,
        status: active,
      };
    } else {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },

        length: result,
        type: props.type,
        sort: props.sort,
        start: page,
        status: active,
      };
    }

    handler = `${RiskManagementService_URL}/listing/ControlLibrary`;
  } else if (
    PageName === "VULNERABILITYLISTING" &&
    pageNameInState === "vulnerabilityListing"
  ) {
    methodType = "POST";
    const sortField = props.sortField || "vulnerabilityTitle";
    if (search !== "") {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },
        length: result,
        search: props.search,
        sort: props.sort,
        type: props.type,
        start: page,
        status: active,
      };
    } else {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },

        length: result,
        type: props.type,
        sort: props.sort,
        start: page,
        status: active,
      };
    }

    handler = `${RiskManagementService_URL}/listing/VulnerabilityMaster`;
  } else if (
    PageName === "RISKLIBRARYLISTING" &&
    pageNameInState === "riskLibraryListing"
  ) {
    methodType = "POST";
    const sortField = props.sortField || "riskLibraryName";
    if (search !== "") {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },
        length: result,
        search: props.search,
        sort: props.sort,
        type: props.type,
        start: page,
        status: active,
      };
    } else {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },

        length: result,
        type: props.type,
        sort: props.sort,
        start: page,
        status: active,
      };
    }

    handler = `${RiskManagementService_URL}/listing/RiskLibrary`;
  } else if (
    PageName === "RISKREGISTERLISTING" &&
    pageNameInState === "RiskRegisterListing"
  ) {
    methodType = "POST";
    const sortField = props.sortField || "riskID";
    if (search !== "") {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },
        length: result,
        search: props.search,
        sort: props.sort,
        type: props.type,
        start: page,
        status: active,
      };
    } else {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },

        length: result,
        type: props.type,
        sort: props.sort,
        start: page,
        status: active,
      };
    }

    handler = `${RiskManagementService_URL}/Apc_View/Amc_9/listing/RiskMaster`;
  } else if (
    PageName === "COLLECTIONINTERACTIONLISTING" &&
    pageNameInState === "collectionInteractionListing"
  ) {
    methodType = "POST";
    const sortField = props.sortField || "id";
    if (search !== "") {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },
        length: result,
        search: props.search,
        sort: props.sort,
        type: props.type,
        start: page,
        status: active,
      };
    } else {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },

        length: result,
        type: props.type,
        sort: props.sort,
        start: page,
        status: active,
      };
    }

    handler =
      `${ConsentManagementService_URL}/Apc_View/Amc_57/listing/listingConsentInteractions?expired=` +
      props.expired;
  } else if (
    PageName === "COLLECTIONSMASTERLISTING" &&
    pageNameInState === "CollectionsMasterListing"
  ) {
    methodType = "POST";
    const sortField = props.sortField || "id";
    if (search !== "") {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },
        length: result,
        search: props.search,
        sort: props.sort,
        type: props.type,
        start: page,
        status: active,
      };
    } else {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },

        length: result,
        type: props.type,
        sort: props.sort,
        start: page,
        status: active,
      };
    }

    handler = `${ConsentManagementService_URL}/Apc_View/Amc_90/listing/ConsentPointEntry`;
  } else if (
    PageName === "CONSENTPURPOSELISTING" &&
    pageNameInState === "consentPurposeListing"
  ) {
    methodType = "POST";
    const sortField = props.sortField || "consentPurposeTitle";
    if (search !== "") {
      dataToPost = {
        defaultSort: {
          sortField: "consentPurposeTitle",
          sortOrder: "asc",
        },
        length: result,
        search: props.search,
        sort: props.sort,
        start: page,
        status: active,
      };
    } else {
      dataToPost = {
        defaultSort: {
          sortField: "consenPurposeTitle",
          sortOrder: "asc",
        },
        length: result,

        sort: props.sort,
        start: page,
        status: active,
      };
    }

    handler = `${ConsentManagementService_URL}/Apc_View/Amc_57/listing/ConsentPurpose`;
  } else if (
    PageName === "CONSENTHEADLISTING" &&
    pageNameInState === "consentHeadListing"
  ) {
    methodType = "POST";
    const sortField = props.sortField || "consentHeadTitle";
    if (search !== "") {
      dataToPost = {
        defaultSort: {
          sortField: "consentHeadTitle",
          sortOrder: "asc",
        },
        length: result,
        search: props.search,
        sort: props.sort,
        start: page,
        status: active,
      };
    } else {
      dataToPost = {
        defaultSort: {
          sortField: "consentHeadTitle",
          sortOrder: "asc",
        },
        length: result,
        sort: props.sort,
        start: page,
        status: active,
      };
    }

    handler = `${ConsentManagementService_URL}/Apc_View/Amc_56/listing/ConsentHead`;
  } else if (
    PageName === "COOKIECATEGORIESLISTING" &&
    pageNameInState === "cookieCategoriesListing"
  ) {
    methodType = "POST";
    const sortField = props.sortField || "cookieCategoryName";
    if (search !== "") {
      dataToPost = {
        defaultSort: {
          sortField: "cookieCategoryName",
          sortOrder: "asc",
        },
        length: result,
        search: props.search,
        sort: props.sort,
        start: page,
        status: active,
      };
    } else {
      dataToPost = {
        defaultSort: {
          sortField: "cookieCategoryName",
          sortOrder: "asc",
        },
        length: result,
        sort: props.sort,
        start: page,
        status: active,
      };
    }

    handler = `${CookieService_Url}/Apc_View/Amc_62/listing/CookieCategory`;
  } else if (
    PageName === "ROPAMASTERLISTING" &&
    pageNameInState === "ropaMasterListing"
  ) {
    methodType = "POST";
    const sortField = props.sortField || "name";
    const type = props.type || 0;

    if (search !== "") {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },
        length: result,
        search: props.search,
        sort: props.sort,
        start: page,
        status: active,
        type: type,
      };
    } else {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },

        length: result,
        type: type,
        sort: props.sort,
        start: page,
        status: active,
      };
    }

    handler = `${RopaManagementService_URL}/getAllRopaWorkflowPagination`;
    /* ?start=` +
      page +
      `&length=` +
      result +
      `&sortOrder=` +
      sortOrder +
      `&sortField=` +
      sortField +
      `&status=` +
      active +
      `&search=` +
      search +
      `&searchCol=` +
      searchCol +
      `&access_token=` +
      access_token */
  } else if (
    PageName === "TEMPLATEFIELDMASTER" &&
    pageNameInState === "templateFieldMaster"
  ) {
    methodType = "POST";
    const sortField = props.sortField || "id";
    const type = props.type || 0;

    if (search !== "") {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },
        length: result,
        search: props.search,
        sort: props.sort,
        start: page,
        status: active,
        // type: type,
      };
    } else {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },

        length: result,
        // type: type,
        sort: props.sort,
        start: page,
        status: active,
      };
    }

    handler = `${RopaManagementService_URL}/Apc_View/Amc_19/getAllNewTemplateField`;
  } else if (
    PageName === "DOMAINLISTING" &&
    pageNameInState === "DomainListing"
  ) {
    methodType = "POST";
    const sortField = props.sortField || "name";

    if (search !== "") {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },
        length: result,
        search: props.search,
        sort: props.sort,
        start: page,
        status: active,
      };
    } else {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },

        length: result,

        sort: props.sort,
        start: page,
        status: active,
      };
    }

    handler = `${CookieService_Url}/Apc_View/Amc_93/listing/CookieDomain`;
  } else if (
    PageName === "COOKIECLASSIFICATIONLISTING" &&
    pageNameInState === "CookieClassificationListing"
  ) {
    methodType = "POST";
    const sortField = props.sortField || "name";

    if (search !== "") {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },
        length: result,
        search: props.search,
        sort: props.sort,
        start: page,
        status: active,
      };
    } else {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },

        length: result,

        sort: props.sort,
        start: page,
        status: active,
      };
    }

    handler = `${CookieService_Url}/Apc_View/Amc_93/listing/CookieDetails`;
  } else if (
    PageName === "COOKIETEMPLATELISTING" &&
    pageNameInState === "cookieTemplateListing"
  ) {
    methodType = "POST";
    const sortField = props.sortField || "templateName";

    if (search !== "") {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },
        length: result,
        search: props.search,
        sort: props.sort,
        start: page,
        status: active,
      };
    } else {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },

        length: result,

        sort: props.sort,
        start: page,
        status: active,
      };
    }

    handler = `${CookieService_Url}/Apc_View/Amc_93/listing/CookieTemplate`;
  } else if (PageName === "HOSTINGTYPEMASTER") {
    methodType = "POST";
    const sortField = props.sortField || "hostProviderTypeName";
    if (search !== "") {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },
        length: result,
        search: props.search,
        sort: props.sort,
        type: props.type,
        start: page,
        status: active,
      };
    } else {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },
        length: result,
        type: props.type,
        sort: props.sort,
        start: page,
        status: active,
      };
    }

    handler = `${SystemService_URL}/listing/HostProviderTypeMaster`;
  } else if (PageName === "BREACHTYPELISTING") {
    methodType = "POST";
    const sortField = props.sortField || "name";

    handler =
      `${IncidentManagementService_URL}/Apc_View/Amc_37/findAllBreachType?start=` +
      page +
      `&length=` +
      result +
      `&sortOrder=` +
      sortOrder +
      `&sortField=` +
      sortField +
      `&status=` +
      active +
      `&search=` +
      search +
      `&searchCol=` +
      searchCol;
    // `&access_token=` +
    // access_token;
  } else if (
    PageName === "RISKCATEGORIESLISTING" &&
    PageName === "RISKCATEGORIESLISTING" &&
    pageNameInState === "RiskCategoriesListing"
  ) {
    methodType = "POST";
    const sortField = props.sortField || "riskCategoryName";
    if (search !== "") {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },
        length: result,
        search: props.search,
        sort: props.sort,
        type: props.type,
        start: page,
        status: active,
      };
    } else {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },

        length: result,
        type: props.type,
        sort: props.sort,
        start: page,
        status: active,
      };
    }

    handler = `${RiskManagementService_URL}/listing/RiskCategory`;
  } else if (PageName === "DATASUBJECTCATEGORIES" || PageName === "DATADISCOVERYSUBJECTCATEGORIES") {
    /*else if (PageName === "HOSTINGPROVIDERMASTER") {
    methodType = "POST";
    const sortField = props.sortField || "hostProviderName";

    handler =
      `${SystemService_URL}/getAllHostingProviderMasterPagination?start=` +
      page +
      `&length=` +
      result +
      `&sortOrder=` +
      sortOrder +
      `&sortField=` +
      sortField +
      `&status=` +
      active +
      `&search=` +
      search +
      `&searchCol=` +
      searchCol +
      `&access_token=` +
      access_token;
  } */
    methodType = "POST";
    const sortField = props.sortField || "name";
    const type = props.type || 0;
    if (search !== "") {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: "asc",
        },
        length: result,
        search: props.search,
        sort: props.sort,
        start: page,
        status: active,
        type: type,
      };
    } else {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: "asc",
        },

        length: result,
        type: type,
        sort: props.sort,
        start: page,
        status: active,
      };
    }

    handler = `${SystemService_URL}/listing/DataCategory`;
  } else if (
    PageName === "HOSTINGPROVIDERMASTER" &&
    pageNameInState === "hostingProviderMaster"
  ) {
    methodType = "POST";
    const sortField = props.sortField || "hostProviderName";
    const type = props.type || 0;
    if (search !== "") {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: "asc",
        },
        length: result,
        search: props.search,
        sort: props.sort,
        start: page,
        status: active,
        type: type,
      };
    } else {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: "asc",
        },

        length: result,
        type: type,
        sort: props.sort,
        start: page,
        status: active,
      };
    }

    handler = `${SystemService_URL}/Apc_View/Amc_11/listing/HostingProviderMaster`;
  } else if (PageName === "DATASUBJECTVOLRANGE" || PageName === "DATADISCOVERYSUBJECTVOLRANGE") {
    methodType = "POST";
    const sortField = props.sortField || "volumeRange";
    const type = props.type || 0;
    if (search !== "") {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: "asc",
        },
        length: result,
        search: props.search,
        sort: props.sort,
        start: page,
        status: active,
        type: type,
      };
    } else {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: "asc",
        },

        length: result,
        type: type,
        sort: props.sort,
        start: page,
        status: active,
      };
    }

    handler = `${SystemService_URL}/Apc_View/Amc_17/listing/DataSubjectVolume`;
  } else if (PageName === "DATADISPOSALMASTER" || PageName === "DATADISCOVERYDISPOSALMASTER") {
    methodType = "POST";
    const sortField = props.sortField || "dataDisposalName";
    const type = props.type || 0;
    if (search !== "") {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: "asc",
        },
        length: result,
        search: props.search,
        sort: props.sort,
        start: page,
        status: active,
        type: type,
      };
    } else {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: "asc",
        },

        length: result,
        type: type,
        sort: props.sort,
        start: page,
        status: active,
      };
    }

    handler = `${SystemService_URL}/Apc_View/Amc_18/listing/DataDisposal`;
  } /*else if (PageName === "SECURITYMEASUREMASTER") {
    methodType = "POST";
    const sortField = props.sortField || "securityMeasureName";

    handler =
      `${SystemService_URL}/getAllSecurityMeasureMasterPagination?start=` +
      page +
      `&length=` +
      result +
      `&sortOrder=` +
      sortOrder +
      `&sortField=` +
      sortField +
      `&status=` +
      active +
      `&search=` +
      search +
      `&searchCol=` +
      searchCol +
      `&access_token=` +
      access_token;
  } */ else if (PageName === "SECURITYTYPEMASTER") {
    methodType = "POST";
    const sortField = props.sortField || "securityMeasureTypeName";
    const type = props.type || 0;
    if (search !== "") {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: "asc",
        },
        length: result,
        search: props.search,
        sort: props.sort,
        start: page,
        status: active,
        type: type,
      };
    } else {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: "asc",
        },

        length: result,
        type: type,
        sort: props.sort,
        start: page,
        status: active,
      };
    }

    handler = `${SystemService_URL}/listing/securityMeasureTypeMaster`;
  } else if (
    PageName === "SECURITYMEASUREMASTER" &&
    pageNameInState === "securityMeasureMaster"
  ) {
    methodType = "POST";
    const sortField = props.sortField || "securityMeasureId";
    if (search !== "") {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },
        length: result,
        search: props.search,
        sort: props.sort,
        type: props.type,
        start: page,
        status: active,
      };
    } else {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },

        length: result,
        type: props.type,
        sort: props.sort,
        start: page,
        status: active,
      };
    }

    handler = `${SystemService_URL}/listing/securityMeasureMaster`;
  } else if (PageName === "ASSETMASTER" && pageNameInState === "assetMaster") {
    methodType = "POST";
    const sortField = props.sortField || "assetName";
    const type = props.type || 0;
    if (search !== "") {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: "asc",
        },
        length: result,
        search: props.search,
        sort: props.sort,
        start: page,
        status: active,
        type: type,
      };
    } else {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: "asc",
        },

        length: result,
        type: type,
        sort: props.sort,
        start: page,
        status: active,
      };
    }

    handler = `${AssetManagementService_URL}/Apc_View/Amc_8/getAllAssetMasterPagination`;
    // handler = `${AssetManagementService_URL}/Apc_View/Amc_8/getAllAssetNamesByCompanyId`;
    /* ?start=` +
      page +
      `&length=` +
      result +
      `&sortOrder=` +
      sortOrder +
      `&sortField=` +
      sortField +
      `&status=` +
      active +
      `&search=` +
      search +
      `&searchCol=` +
      searchCol +
      `&access_token=` +
      access_token */
  } else if (PageName === "DATASUBJECTELEMENTS" || PageName === "DATADISCOVERYSUBJECTELEMENTS") {
    methodType = "POST";
    const sortField = props.sortField || "name";
    const type = props.type || 0;
    if (search !== "") {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: "asc",
        },
        length: result,
        search: props.search,
        sort: props.sort,
        start: page,
        status: active,
        type: type,
      };
    } else {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: "asc",
        },

        length: result,
        type: type,
        sort: props.sort,
        start: page,
        status: active,
      };
    }

    handler = `${SystemService_URL}/listing/DataElement`;
  } else if (PageName === "DATACONNECTIONS") {
    methodType = "POST";
    const sortField = props.sortField || "connectionName";
    const type = props.type || 0;
    if (search !== "") {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: "asc",
        },
        length: result,
        search: props.search,
        sort: props.sort,
        start: page,
        status: active,
        type: type,
      };
    } else {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: "asc",
        },

        length: result,
        type: type,
        sort: props.sort,
        start: page,
        status: active,
      };
    }

    handler = `${DataConnection_URL}/getConnectionsList`;
  } else if (PageName === "DATADISCOVERYSCANS") {
    methodType = "POST";
    const sortField = props.sortField || "createdOn";
    const type = props.type || 0;
    if (search !== "") {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: "desc",
        },
        length: result,
        search: props.search,
        sort: [] || props.sort,
        start: page,
        status: active,
        type: type,
      };
    } else {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: "desc",
        },

        length: result,
        type: type,
        sort: [] || props.sort,
        start: page,
        status: active,
      };
    }

    handler = `${DataConnection_URL}/listing/Scan?companyId=${companyId}`;
  } else if (PageName === "USERMASTER" && pageNameInState === "userMaster") {
    methodType = "POST";
    const sortField = props.sortField || "userFirstName";
    handler =
      // `http://192.168.0.37:7001/getAllCompanyUserByPagination?start=` +
      `${UserManagementService_URL}/Apc_View/Amc_31/getAllCompanyUserByPagination?start=` +
      page +
      `&length=` +
      result +
      `&sortOrder=` +
      sortOrder +
      `&sortField=` +
      sortField +
      `&status=` +
      active +
      `&search=` +
      search +
      `&searchCol=` +
      searchCol +
      `&companyId=` +
      companyId;
    // `&access_token=` +
    // access_token;
  } else if (PageName === "ROLEMASTER" && pageNameInState === "roleMaster") {
    methodType = "POST";
    const sortField = props.sortField || "contactRoleName";

    handler =
      `${AssetManagementService_URL}/Apc_View/Amc_33/getAllContactRolePagination?start=` +
      page +
      `&length=` +
      result +
      `&sortOrder=` +
      sortOrder +
      `&sortField=` +
      sortField +
      `&status=` +
      active +
      `&search=` +
      search +
      `&searchCol=` +
      searchCol;
    // `&access_token=` +
    // access_token;
  } else if (
    PageName === "ROLEMANAGEMENTLISTING" &&
    pageNameInState === "roleManagementListing"
  ) {
    methodType = "POST";
    const sortField = props.sortField || "name";

    handler =
      `${EdgeService_URL}/findAllCompanyRolesPaginationCriteria?start=` +
      page +
      `&length=` +
      result +
      `&sortOrder=` +
      sortOrder +
      `&sortField=` +
      sortField +
      `&status=` +
      active +
      `&search=` +
      search +
      `&searchCol=` +
      searchCol;
    // `&access_token=` +
    // access_token;
  } /* else if (PageName === "ROPAMASTERLISTING") {
    methodType = "POST";
    const sortField = props.sortField || "name";

    handler =
      `${RopaManagementService_URL}/Apc_View/Amc_19/getAllRopaMasterPagination?start=` +
      page +
      `&length=` +
      result +
      `&sortOrder=` +
      sortOrder +
      `&sortField=` +
      sortField +
      `&status=` +
      active +
      `&search=` +
      search +
      `&searchCol=` +
      searchCol +
      `&access_token=` +
      access_token;
  } */ else if (PageName === "LEGALBASIS") {
    methodType = "POST";
    const sortField = props.sortField || "name";
    handler =
      `${SystemService_URL}/getAllLegalBasisPagination?start=` +
      page +
      `&length=` +
      result +
      `&sortOrder=` +
      sortOrder +
      `&sortField=` +
      sortField +
      `&status=` +
      active +
      `&search=` +
      search +
      `&searchCol=` +
      searchCol;
    // `&access_token=` +
    // access_token;
  } else if (PageName === "ACTIVITYMASTER") {
    methodType = "POST";
    const sortField = props.sortField || "name";
    handler =
      `${RopaManagementService_URL}/Apc_View/Amc_19/getAllRopaMasterPagination?start=` +
      page +
      `&length=` +
      result +
      `&sortOrder=` +
      sortOrder +
      `&sortField=` +
      sortField +
      `&status=` +
      active +
      `&search=` +
      search +
      `&searchCol=` +
      searchCol;
    // `&access_token=` +
    // access_token;
  } else if (
    PageName === "RESPONSESLISTING" &&
    pageNameInState === "ResponsesListing"
  ) {
    methodType = "POST";
    const sortField = props.sortField || "name";
    const type = props.type;
    const status = props.surveyStatus;
    const awatingResponse = props.awatingResponse;

    handler =
      // `http://192.168.0.37:7001/getAllCompanyUserByPagination?start=` +
      `${SurveyService_URL}/Apc_View/Amc_30/findAllSurveyPaginationByUser?start=` +
      page +
      `&length=` +
      result +
      `&sortOrder=` +
      sortOrder +
      `&sortField=` +
      sortField +
      `&booleanfield=` +
      active +
      `&search=` +
      search +
      `&searchCol=` +
      searchCol +
      `&type=` +
      type +
      `&awatingResponse=` +
      awatingResponse +
      `&surveyStatus=` +
      status;
    // `&access_token=` +
    // access_token;
  } else if (
    PageName === "SURVEYLISTING" &&
    pageNameInState === "SurveyListing"
  ) {
    methodType = "POST";
    const sortField = props.sortField || "name";
    const type = props.type;
    const status = props.surveyStatus;

    handler =
      // `http://192.168.0.37:7001/getAllCompanyUserByPagination?start=` +
      `${SurveyService_URL}/Apc_View/Amc_30/findAllSurveyPagination?start=` +
      page +
      `&length=` +
      result +
      `&sortOrder=` +
      sortOrder +
      `&sortField=` +
      sortField +
      `&booleanfield=` +
      active +
      `&search=` +
      search +
      `&searchCol=` +
      searchCol +
      `&type=` +
      type +
      `&surveyStatus=` +
      status;
    // `&access_token=` +
    // access_token;
  } else if (
    PageName === "VENDORALLTIERLISTING" &&
    pageNameInState === "VendorAllTierListing"
  ) {
    methodType = "POST";
    const sortField = props.sortField || "name";
    const type = props.type;
    const status = props.surveyStatus;

    handler =
      // `http://192.168.0.37:7001/getAllCompanyUserByPagination?start=` +
      `${SurveyService_URL}/Apc_View/Amc_30/findAllSurveyPagination?start=` +
      page +
      `&length=` +
      result +
      `&sortOrder=` +
      sortOrder +
      `&sortField=` +
      sortField +
      `&booleanfield=` +
      active +
      `&search=` +
      search +
      `&searchCol=` +
      searchCol +
      `&type=` +
      type +
      `&surveyStatus=` +
      status +
      `&isVendorTiering=true`;
    // `&access_token=` +
    // access_token;
  } else if (
    PageName === "SURVEYLISTINGCOMPLETED" &&
    pageNameInState === "SurveyListingCompleted"
  ) {
    methodType = "POST";
    const sortField = props.sortField || "name";
    const type = props.type;
    const status = props.surveyStatus;

    handler =
      // `http://192.168.0.37:7001/getAllCompanyUserByPagination?start=` +
      `${SurveyService_URL}/Apc_View/Amc_30/findAllSurveyPagination?start=` +
      page +
      `&length=` +
      result +
      `&sortOrder=` +
      sortOrder +
      `&sortField=` +
      sortField +
      `&booleanfield=` +
      active +
      `&search=` +
      search +
      `&searchCol=` +
      searchCol +
      `&type=` +
      type +
      `&surveyStatus=` +
      status +
      `&access_token=` +
      access_token;
  } else if (
    PageName === "TIERINGASSESSMENTLISTINGCOMPLETED" &&
    pageNameInState === "TieringAssessmentListingCompleted"
  ) {
    methodType = "POST";
    const sortField = props.sortField || "name";
    const type = props.type;
    const status = props.surveyStatus;

    handler =
      // `http://192.168.0.37:7001/getAllCompanyUserByPagination?start=` +
      `${SurveyService_URL}/Apc_View/Amc_30/findAllSurveyPagination?start=` +
      page +
      `&length=` +
      result +
      `&sortOrder=` +
      sortOrder +
      `&sortField=` +
      sortField +
      `&booleanfield=` +
      active +
      `&search=` +
      search +
      `&searchCol=` +
      searchCol +
      `&type=` +
      type +
      `&surveyStatus=` +
      status +
      `&isVendorTiering=true`;
  } else if (
    PageName === "BREACHINCIDENTREGISTER" &&
    pageNameInState === "breachIncidentRegister"
  ) {
    methodType = "POST";
    const sortField = props.sortField || "name";
    const type = props.type;
    const status = props.breachStatus;

    handler =
      // `http://192.168.0.37:7001/getAllCompanyUserByPagination?start=` +
      `${IncidentManagementService_URL}/Apc_View/Amc_36/findAllBreachIncidentPaginationByStatus?start=` +
      page +
      `&length=` +
      result +
      `&sortOrder=` +
      sortOrder +
      `&sortField=` +
      sortField +
      `&status=` +
      active +
      `&search=` +
      search +
      `&searchCol=` +
      searchCol +
      `&type=` +
      type;
    // `&access_token=` +
    // access_token;
  } else if (
    PageName === "SURVEYDRAFTLISTING" &&
    pageNameInState === "SurveyDraftListing"
  ) {
    methodType = "POST";
    const sortField = props.sortField || "name";
    const type = props.type;

    const status = props.surveyStatus;

    handler =
      // `http://192.168.0.37:7001/getAllCompanyUserByPagination?start=` +
      `${SurveyService_URL}/Apc_View/Amc_30/findAllSurveyPagination?start=` +
      page +
      `&length=` +
      result +
      `&sortOrder=` +
      sortOrder +
      `&sortField=` +
      sortField +
      `&status=` +
      active +
      `&search=` +
      search +
      `&searchCol=` +
      searchCol +
      `&type=` +
      type +
      `&surveyStatus=` +
      status;
    // `&access_token=` +
    // access_token;
  } else if (
    PageName === "VENDORDRAFTLISTING" &&
    pageNameInState === "VendorDraftListing"
  ) {
    methodType = "POST";
    const sortField = props.sortField || "name";
    const type = props.type;

    const status = props.surveyStatus;

    handler =
      `${SurveyService_URL}/Apc_View/Amc_30/findAllSurveyPagination?start=` +
      page +
      `&length=` +
      result +
      `&sortOrder=` +
      sortOrder +
      `&sortField=` +
      sortField +
      `&status=` +
      active +
      `&search=` +
      search +
      `&searchCol=` +
      searchCol +
      `&type=` +
      type +
      `&surveyStatus=` +
      status +
      `&isVendorTiering=true`;
    // `&access_token=` +
    // access_token;
  } else if (
    PageName === "SURVEYASSESSMENTAWATINGLISTING" &&
    pageNameInState === "SurveyAssessmentAwatingListing"
  ) {
    methodType = "POST";
    const sortField = props.sortField || "name";
    const type = props.type;

    const status = props.surveyStatus;

    handler =
      // `http://192.168.0.37:7001/getAllCompanyUserByPagination?start=` +
      `${SurveyService_URL}/Apc_View/Amc_30/findAllSurveyPagination?start=` +
      page +
      `&length=` +
      result +
      `&sortOrder=` +
      sortOrder +
      `&sortField=` +
      sortField +
      `&status=` +
      active +
      `&search=` +
      search +
      `&searchCol=` +
      searchCol +
      `&type=` +
      type +
      `&surveyStatus=` +
      status;
    // `&access_token=` +
    // access_token;
  } else if (
    PageName === "TIERINGASSESSMENTAWATINGLISTING" &&
    pageNameInState === "TieringAssessmentAwatingListing"
  ) {
    methodType = "POST";
    const sortField = props.sortField || "name";
    const type = props.type;

    const status = props.surveyStatus;

    handler =
      // `http://192.168.0.37:7001/getAllCompanyUserByPagination?start=` +
      `${SurveyService_URL}/Apc_View/Amc_30/findAllSurveyPagination?start=` +
      page +
      `&length=` +
      result +
      `&sortOrder=` +
      sortOrder +
      `&sortField=` +
      sortField +
      `&status=` +
      active +
      `&search=` +
      search +
      `&searchCol=` +
      searchCol +
      `&type=` +
      type +
      `&surveyStatus=` +
      status +
      `&isVendorTiering=true`;
    // `&access_token=` +
    // access_token;
  } else if (
    PageName === "PRESURVEYLISTING" &&
    pageNameInState === "PreSurveyListing"
  ) {
    methodType = "POST";
    const sortField = props.sortField || "name";
    const type = props.type;

    handler =
      // `http://192.168.0.37:7001/getAllCompanyUserByPagination?start=` +
      `${SurveyService_URL}/Apc_View/Amc_30/findAllSurveyPagination?start=` +
      page +
      `&length=` +
      result +
      `&sortOrder=` +
      sortOrder +
      `&sortField=` +
      sortField +
      `&status=` +
      active +
      `&search=` +
      search +
      `&searchCol=` +
      searchCol +
      `&type=` +
      type;
    // `&access_token=` +
    // access_token;
  } else if (
    PageName === "TEMPLATEMANAGERLISTING" &&
    pageNameInState === "templateManagerListing"
  ) {
    methodType = "POST";
    const sortField = props.sortField || "name";
    const type = props.type;
    const surveyStatus = props.surveyStatus;
    handler =
      // `http://192.168.0.37:7001/getAllCompanyUserByPagination?start=` +
      `${SurveyService_URL}/Apc_View/Amc_30/findAllSurveyPagination?start=` +
      page +
      `&length=` +
      result +
      `&sortOrder=` +
      sortOrder +
      `&sortField=` +
      sortField +
      `&status=` +
      active +
      `&search=` +
      search +
      `&searchCol=` +
      searchCol +
      `&type=` +
      type +
      `&surveyStatus=` +
      surveyStatus;
    // `&access_token=` +
    // access_token;
  } else if (
    PageName === "TIERINGTEMPLATEMANAGERLISTING" &&
    pageNameInState === "TieringTemplateManagerListing"
  ) {
    methodType = "POST";
    const sortField = props.sortField || "name";
    const type = props.type;
    const surveyStatus = props.surveyStatus;
    handler =
      // `http://192.168.0.37:7001/getAllCompanyUserByPagination?start=` +
      `${SurveyService_URL}/Apc_View/Amc_30/findAllSurveyPagination?start=` +
      page +
      `&length=` +
      result +
      `&sortOrder=` +
      sortOrder +
      `&sortField=` +
      sortField +
      `&status=` +
      active +
      `&search=` +
      search +
      `&searchCol=` +
      searchCol +
      `&type=` +
      type +
      `&surveyStatus=` +
      surveyStatus +
      `&isVendorTiering=true`;
    // `&access_token=` +
    // access_token;
  } else if (
    PageName === "BREACHTEMPLATEMANAGERLISTING" &&
    pageNameInState === "breachTemplateManagerListing"
  ) {
    methodType = "POST";
    const sortField = props.sortField || "name";
    const type = props.type;
    const breachStatus = props.breachStatus;
    handler =
      // `http://192.168.0.37:7001/getAllCompanyUserByPagination?start=` +
      `${IncidentManagementService_URL}/Apc_View/Amc_36/findAllBreachPagination?start=` +
      page +
      `&length=` +
      result +
      `&sortOrder=` +
      sortOrder +
      `&sortField=` +
      sortField +
      `&status=` +
      active +
      `&search=` +
      search +
      `&searchCol=` +
      searchCol +
      `&type=` +
      type +
      `&breachStatus=` +
      breachStatus;
    // `&access_token=` +
    // access_token;
  } else if (
    PageName === "TEMPLATEMANAGERDRAFTLISTING" &&
    pageNameInState === "templateManagerDraftListing"
  ) {
    methodType = "POST";
    const sortField = props.sortField || "name";
    const type = props.type;
    const surveyStatus = props.surveyStatus;
    handler =
      // `http://192.168.0.37:7001/getAllCompanyUserByPagination?start=` +
      `${SurveyService_URL}/Apc_View/Amc_30/findAllSurveyPagination?start=` +
      page +
      `&length=` +
      result +
      `&sortOrder=` +
      sortOrder +
      `&sortField=` +
      sortField +
      `&status=` +
      active +
      `&search=` +
      search +
      `&searchCol=` +
      searchCol +
      `&type=` +
      type +
      `&surveyStatus=` +
      surveyStatus;
    // `&access_token=` +
    // access_token;
  } else if (
    PageName === "TIERINGTEMPLATEMANAGERDRAFTLISTING" &&
    pageNameInState === "TieringTemplateManagerDraftListing"
  ) {
    methodType = "POST";
    const sortField = props.sortField || "name";
    const type = props.type;
    const surveyStatus = props.surveyStatus;
    handler =
      // `http://192.168.0.37:7001/getAllCompanyUserByPagination?start=` +
      `${SurveyService_URL}/Apc_View/Amc_30/findAllSurveyPagination?start=` +
      page +
      `&length=` +
      result +
      `&sortOrder=` +
      sortOrder +
      `&sortField=` +
      sortField +
      `&status=` +
      active +
      `&search=` +
      search +
      `&searchCol=` +
      searchCol +
      `&type=` +
      type +
      `&surveyStatus=` +
      surveyStatus +
      `&isVendorTiering=true`;
    // `&access_token=` +
    // access_token;
  } else if (
    PageName === "SURVEYTEMPLATESAWAITING" &&
    pageNameInState === "SurveyTemplatesAwaiting"
  ) {
    methodType = "POST";
    const sortField = props.sortField || "name";
    const type = props.type;
    const surveyStatus = props.surveyStatus;
    handler =
      // `http://192.168.0.37:7001/getAllCompanyUserByPagination?start=` +
      `${SurveyService_URL}/Apc_View/Amc_30/findAllSurveyPagination?start=` +
      page +
      `&length=` +
      result +
      `&sortOrder=` +
      sortOrder +
      `&sortField=` +
      sortField +
      `&status=` +
      active +
      `&search=` +
      search +
      `&searchCol=` +
      searchCol +
      `&type=` +
      type +
      `&surveyStatus=` +
      surveyStatus;
    // `&access_token=` +
    // access_token;
  } else if (
    PageName === "TIERINGTEMPLATESAWAITING" &&
    pageNameInState === "TieringTemplatesAwaiting"
  ) {
    methodType = "POST";
    const sortField = props.sortField || "name";
    const type = props.type;
    const surveyStatus = props.surveyStatus;
    handler =
      // `http://192.168.0.37:7001/getAllCompanyUserByPagination?start=` +
      `${SurveyService_URL}/Apc_View/Amc_30/findAllSurveyPagination?start=` +
      page +
      `&length=` +
      result +
      `&sortOrder=` +
      sortOrder +
      `&sortField=` +
      sortField +
      `&status=` +
      active +
      `&search=` +
      search +
      `&searchCol=` +
      searchCol +
      `&type=` +
      type +
      `&surveyStatus=` +
      surveyStatus +
      `&isVendorTiering=true`;
    // `&access_token=` +
    // access_token;
  } else if (PageName === "REGULATIONMASTERLISTING") {
    methodType = "POST";
    const sortField = props.sortField || "name";

    handler =
      `${RopaManagementService_URL}/Apc_View/Amc_21/findAllRegulationPagination?start=` +
      page +
      `&length=` +
      result +
      `&sortOrder=` +
      sortOrder +
      `&sortField=` +
      sortField +
      `&status=` +
      active +
      `&search=` +
      search +
      `&searchCol=` +
      searchCol;
    // `&access_token=` +
    // access_token;
  } else if (PageName === "TEMPLATELISTING") {
    methodType = "POST";
    const sortField = props.sortField || "name";

    handler =
      `${RopaManagementService_URL}/Apc_View/Amc_20/findAllTemplatePagination?start=` +
      page +
      `&length=` +
      result +
      `&sortOrder=` +
      sortOrder +
      `&sortField=` +
      sortField +
      `&status=` +
      active +
      `&search=` +
      search +
      `&searchCol=` +
      searchCol;
    // `&access_token=` +
    // access_token;
  } else if (PageName === "DATARECIPIENTLISTING") {
    methodType = "POST";
    const sortField = props.sortField || "name";

    handler =
      `${RopaManagementService_URL}/Apc_View/Amc_27/findAllDataRecipient?start=` +
      page +
      `&length=` +
      result +
      `&sortOrder=` +
      sortOrder +
      `&sortField=` +
      sortField +
      `&status=` +
      active +
      `&search=` +
      search +
      `&searchCol=` +
      searchCol;
    // `&access_token=` +
    // access_token;
  } else if (PageName === "DATAPROCESSORLISTING") {
    methodType = "POST";
    const sortField = props.sortField || "name";

    handler =
      `${RopaManagementService_URL}/findAllDataProcessor?start=` +
      page +
      `&length=` +
      result +
      `&sortOrder=` +
      sortOrder +
      `&sortField=` +
      sortField +
      `&status=` +
      active +
      `&search=` +
      search +
      `&searchCol=` +
      searchCol;
    // `&access_token=` +
    // access_token;
  } else if (PageName === "DATASUBPROCESSORLISTING") {
    methodType = "POST";
    const sortField = props.sortField || "name";

    handler =
      `${RopaManagementService_URL}/Apc_View/Amc_26/findAllDataSubProcessor?start=` +
      page +
      `&length=` +
      result +
      `&sortOrder=` +
      sortOrder +
      `&sortField=` +
      sortField +
      `&status=` +
      active +
      `&search=` +
      search +
      `&searchCol=` +
      searchCol;
    // `&access_token=` +
    // access_token;
  } else if (PageName === "ROPADATAPROCCATLISTING") {
    methodType = "POST";
    const sortField = props.sortField || "name";

    handler =
      `${RopaManagementService_URL}/findAllDataProcessorCategory?start=` +
      page +
      `&length=` +
      result +
      `&sortOrder=` +
      sortOrder +
      `&sortField=` +
      sortField +
      `&status=` +
      active +
      `&search=` +
      search +
      `&searchCol=` +
      searchCol;
    // `&access_token=` +
    // access_token;
  } else if (PageName === "DATACONTACTLISTING") {
    methodType = "POST";
    const sortField = props.sortField || "name";

    handler =
      `${RopaManagementService_URL}/Apc_View/Amc_19/findAllRopaContactDetail?start=` +
      page +
      `&length=` +
      result +
      `&sortOrder=` +
      sortOrder +
      `&sortField=` +
      sortField +
      `&status=` +
      active +
      `&search=` +
      search +
      `&searchCol=` +
      searchCol;
    // `&access_token=` +
    // access_token;
  } else if (PageName === "DATATRANSFERLISTING") {
    methodType = "POST";
    const sortField = props.sortField || "dataTransferMethodName";

    if (search !== "") {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },
        length: result,
        search: props.search,
        sort: props.sort,
        type: props.type,
        start: page,
        status: active,
      };
    } else {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },

        length: result,
        type: props.type,
        sort: props.sort,
        start: page,
        status: active,
      };
    }

    handler = `${SystemService_URL}/Apc_View/Amc_17/listing/dataTransferMethod`;
  } else if (PageName === "PURPOSELISTING") {
    methodType = "POST";
    const sortField = props.sortField || "purposeName";
    const type = props.type || 0;
    if (search !== "") {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: "asc",
        },
        length: result,
        search: props.search,
        sort: props.sort,
        start: page,
        status: active,
        type: type,
      };
    } else {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: "asc",
        },

        length: result,
        type: type,
        sort: props.sort,
        start: page,
        status: active,
      };
    }
    handler = `${SystemService_URL}/listing/purposeMaster`;
    // handler =
    //   `${SystemService_URL}/getAllPurposeMasterPagination?start=` +
    //   page +
    //   `&length=` +
    //   result +
    //   `&sortOrder=` +
    //   sortOrder +
    //   `&sortField=` +
    //   sortField +
    //   `&status=` +
    //   active +
    //   `&search=` +
    //   search +
    //   `&searchCol=` +
    //   searchCol +
    //   `&access_token=` +
    //   access_token;
  } else if (PageName === "ASSESSMENTLISTING") {
    methodType = "POST";
    const sortField = props.sortField || "name";

    handler =
      `${SurveyService_URL}/Apc_View/Amc_30/findAllSurveyPaginationByUser?start=` +
      page +
      `&length=` +
      result +
      `&sortOrder=` +
      sortOrder +
      `&sortField=` +
      sortField +
      `&status=` +
      active +
      `&search=` +
      search +
      `&searchCol=` +
      searchCol;
    // `&access_token=` +
    // access_token;
  } else if (PageName === "ASSESSMENTREVIEWLISTING") {
    methodType = "POST";
    const sortField = props.sortField || "name";
    const type = props.type;
    const surveyStatus = props.surveyStatus;

    handler =
      `${SurveyService_URL}/Apc_View/Amc_30/findAllSurveyPaginationByUser?start=` +
      page +
      `&length=` +
      result +
      `&sortOrder=` +
      sortOrder +
      `&sortField=` +
      sortField +
      `&status=` +
      active +
      `&search=` +
      search +
      `&searchCol=` +
      searchCol +
      `&type=` +
      type +
      `&surveyStatus=` +
      surveyStatus;
    // `&access_token=` +
    // access_token;
  } else if (PageName === "ASSESSMENTPASTREVIEWLISTING") {
    methodType = "POST";
    const sortField = props.sortField || "name";
    const type = props.type;
    const surveyStatus = props.surveyStatus;

    handler =
      `${SurveyService_URL}/Apc_View/Amc_30/findAllSurveyPaginationByUser?start=` +
      page +
      `&length=` +
      result +
      `&sortOrder=` +
      sortOrder +
      `&sortField=` +
      sortField +
      `&status=` +
      active +
      `&search=` +
      search +
      `&searchCol=` +
      searchCol +
      `&type=` +
      type +
      `&surveyStatus=` +
      surveyStatus;
    // `&access_token=` +
    // access_token;
  } else if (PageName === "DATASUBJECTMASTER" || PageName === "DATADISCOVERYSUBJECTMASTER") {
    methodType = "POST";
    const sortField = props.sortField || "name";
    const type = props.type || 0;
    if (search !== "") {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: "asc",
        },
        length: result,
        search: props.search,
        sort: props.sort,
        start: page,
        status: active,
        type: type,
      };
    } else {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: "asc",
        },

        length: result,
        type: type,
        sort: props.sort,
        start: page,
        status: active,
      };
    }

    handler = `${SystemService_URL}/listing/DataSubject`;
  } else if (PageName === "DESIGNATIONLISTING") {
    methodType = "POST";
    const sortField = props.sortField || "designationName";
    const type = props.type || 0;
    if (search !== "") {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: "asc",
        },
        length: result,
        search: props.search,
        sort: props.sort,
        start: page,
        status: active,
        type: type,
      };
    } else {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: "asc",
        },

        length: result,
        type: type,
        sort: props.sort,
        start: page,
        status: active,
      };
    }

    handler = `${SystemService_URL}/listing/designationMaster`;
  }
  if (PageName === "REQUESTTYPEMASTER") {
    methodType = "POST";
    const sortField = props.sortField || "name";

    handler =
      `${RequestManagement_URL}/getAllRequestTypePagination?start=` +
      page +
      `&length=` +
      result +
      `&sortOrder=` +
      sortOrder +
      `&sortField=` +
      sortField +
      `&status=` +
      active +
      `&search=` +
      search +
      `&searchCol=` +
      searchCol;
    // `&access_token=` +
    // access_token;
  }
  if (PageName === "REQUESTSOURCESMASTER") {
    methodType = "POST";
    const sortField = props.sortField || "name";

    handler =
      `${RequestManagement_URL}/getAllRequestSourcesPagination?start=` +
      page +
      `&length=` +
      result +
      `&sortOrder=` +
      sortOrder +
      `&sortField=` +
      sortField +
      `&status=` +
      active +
      `&search=` +
      search +
      `&searchCol=` +
      searchCol;
    // `&access_token=` +
    // access_token;
  }
  if (PageName === "BREACHSOURCELISTING") {
    methodType = "POST";
    const sortField = props.sortField || "name";

    handler =
      `${IncidentManagementService_URL}/Apc_View/Amc_84/findAllBreachSource?start=` +
      page +
      `&length=` +
      result +
      `&sortOrder=` +
      sortOrder +
      `&sortField=` +
      sortField +
      `&status=` +
      active +
      `&search=` +
      search +
      `&searchCol=` +
      searchCol;
    // `&access_token=` +
    // access_token;
  }
  if (PageName === "REQUESTMASTER") {
    methodType = "POST";
    const sortField = props.sortField || "name";
    const type = props.type || 0;
    handler =
      `${RequestManagement_URL}/getAllRequestPagination?start=` +
      page +
      `&length=` +
      result +
      `&sortOrder=` +
      sortOrder +
      `&sortField=` +
      sortField +
      `&status=` +
      active +
      `&search=` +
      search +
      `&searchCol=` +
      searchCol +
      `&type=` +
      type;
    // `&access_token=` +
    // access_token;
  } else if (
    PageName === "RISKASSESSMENT" &&
    pageNameInState === "riskAssessment"
  ) {
    methodType = "POST";
    const sortField = props.sortField || "name";
    const type = props.type;

    const status = props.surveyStatus;

    handler =
      // `http://192.168.0.37:7001/getAllCompanyUserByPagination?start=` +
      `${RequestManagement_URL}/Apc_View/Amc_30/findAllSurveyPagination?start=` +
      page +
      `&length=` +
      result +
      `&sortOrder=` +
      sortOrder +
      `&sortField=` +
      sortField +
      `&status=` +
      active +
      `&search=` +
      search +
      `&searchCol=` +
      searchCol +
      `&type=` +
      type +
      `&surveyStatus=` +
      status;
    // `&access_token=` +
    // access_token;
  } else if (
    PageName === "RISKASSESSMENT" &&
    pageNameInState === "riskAssessment" &&
    props.pageData === "RiskResponses"
  ) {
    methodType = "POST";
    const sortField = props.sortField || "name";
    const type = props.type;
    const status = props.surveyStatus;
    const awatingResponse = props.awatingResponse;

    handler =
      // `http://192.168.0.37:7001/getAllCompanyUserByPagination?start=` +
      `${SurveyService_URL}/Apc_View/Amc_30/findAllSurveyPaginationByUser?start=` +
      page +
      `&length=` +
      result +
      `&sortOrder=` +
      sortOrder +
      `&sortField=` +
      sortField +
      `&booleanfield=` +
      active +
      `&search=` +
      search +
      `&searchCol=` +
      searchCol +
      `&type=` +
      type +
      `&awatingResponse=` +
      awatingResponse +
      `&surveyStatus=` +
      status;
    // `&access_token=` +
    // access_token;
  } else if (
    PageName === "RISKTEMPASSESSMENT" &&
    pageNameInState === "riskTempAssessment"
  ) {
    methodType = "POST";
    const sortField = props.sortField || "name";
    const type = props.type;
    const surveyStatus = props.surveyStatus;
    handler =
      // `http://192.168.0.37:7001/getAllCompanyUserByPagination?start=` +
      `${RequestManagement_URL}/Apc_View/Amc_30/findAllSurveyPagination?start=` +
      page +
      `&length=` +
      result +
      `&sortOrder=` +
      sortOrder +
      `&sortField=` +
      sortField +
      `&status=` +
      active +
      `&search=` +
      search +
      `&searchCol=` +
      searchCol +
      `&type=` +
      type +
      `&surveyStatus=` +
      surveyStatus;
    // `&access_token=` +
    // access_token;
  } else if (
    PageName === "VENDORINDUSTRYLISTING" &&
    pageNameInState === "VendorIndustryListing"
  ) {
    methodType = "POST";
    const sortField = props.sortField || "industryTypeId";
    if (search !== "") {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },
        length: result,
        search: props.search,
        sort: props.sort,
        type: props.type,
        start: page,
        status: active,
      };
    } else {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },

        length: result,
        type: props.type,
        sort: props.sort,
        start: page,
        status: active,
      };
    }

    handler = `${VendorService_URL}/Apc_View/Amc_85/listing/IndustryType`;
  } else if (
    PageName === "VENDORTYPELISTING" &&
    pageNameInState === "VendorTypeListing"
  ) {
    methodType = "POST";
    const sortField = props.sortField || "id";
    if (search !== "") {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },
        length: result,
        search: props.search,
        sort: props.sort,
        type: props.type,
        start: page,
        status: active,
      };
    } else {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },

        length: result,
        type: props.type,
        sort: props.sort,
        start: page,
        status: active,
      };
    }

    handler = `${VendorService_URL}/Apc_View/Amc_86/listing/VendorType`;
  } else if (
    PageName === "VENDORSERVICELISTING" &&
    pageNameInState === "VendorServiceListing"
  ) {
    methodType = "POST";
    const sortField = props.sortField || "vendorCategoryId";
    if (search !== "") {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },
        length: result,
        search: props.search,
        sort: props.sort,
        type: props.type,
        start: page,
        status: active,
      };
    } else {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },

        length: result,
        type: props.type,
        sort: props.sort,
        start: page,
        status: active,
      };
    }

    handler = `${VendorService_URL}/Apc_View/Amc_88/listing/VendorCategory`;
  } else if (
    PageName === "VENDORREGISTERLISTING" &&
    pageNameInState === "VendorRegisterListing"
  ) {
    methodType = "POST";
    const sortField = props.sortField || "vendorId";
    if (search !== "") {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },
        length: result,
        search: props.search,
        sort: props.sort,
        type: props.type,
        start: page,
        status: active,
      };
    } else {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },

        length: result,
        type: props.type,
        sort: props.sort,
        start: page,
        status: active,
      };
    }

    handler = `${VendorService_URL}/Apc_View/Amc_89/getAllVendorMasterPagination`;
  } else if (
    PageName === "VENDORRISKRATINGLISTING" &&
    pageNameInState === "VendorRiskRatingListing"
  ) {
    methodType = "POST";
    const sortField = props.sortField || "sequence";
    if (search !== "") {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },
        length: result,
        search: props.search,
        sort: props.sort,
        type: props.type,
        start: page,
        status: active,
      };
    } else {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },

        length: result,
        type: props.type,
        sort: props.sort,
        start: page,
        status: active,
      };
    }

    handler = `${VendorService_URL}/Apc_Persist/Amc_97/listing/VenderRisk`;
  } else if (
    PageName === "VENDORTIERLISTING" &&
    pageNameInState === "VendorTierListing"
  ) {
    methodType = "POST";
    const sortField = props.sortField || "sequence";
    if (search !== "") {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },
        length: result,
        search: props.search,
        sort: props.sort,
        type: props.type,
        start: page,
        status: active,
      };
    } else {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },

        length: result,
        type: props.type,
        sort: props.sort,
        start: page,
        status: active,
      };
    }

    handler = `${VendorService_URL}/Apc_Persist/Amc_98/listing/VenderTier`;
  } else if (
    PageName === "DOCUMENTTYPELISTING" &&
    pageNameInState === "DocumentTypeListing"
  ) {
    methodType = "POST";
    const sortField = props.sortField || "id";
    if (search !== "") {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },
        length: result,
        search: props.search,
        sort: props.sort,
        type: props.type,
        start: page,
        status: active,
      };
    } else {
      dataToPost = {
        defaultSort: {
          sortField: sortField,
          sortOrder: sortOrder,
        },

        length: result,
        type: props.type,
        sort: props.sort,
        start: page,
        status: active,
      };
    }

    handler = `${VendorService_URL}/Apc_View/Amc_99/listing/VendorDocumentType`;
  } else if (
    PageName === "TIERRESPONSESLISTING" &&
    pageNameInState === "TierResponsesListing"
  ) {
    console.log("TIERRESPONSESLISTING test");
    methodType = "POST";
    const sortField = props.sortField || "name";
    const type = props.type;
    const status = props.surveyStatus;
    const awatingResponse = props.awatingResponse;

    handler =
      // `http://192.168.0.37:7001/getAllCompanyUserByPagination?start=` +
      `${SurveyService_URL}/Apc_View/Amc_30/findAllSurveyPaginationByUser?start=` +
      page +
      `&length=` +
      result +
      `&sortOrder=` +
      sortOrder +
      `&sortField=` +
      sortField +
      `&booleanfield=` +
      active +
      `&search=` +
      search +
      `&searchCol=` +
      searchCol +
      `&type=` +
      type +
      `&awatingResponse=` +
      awatingResponse +
      `&surveyStatus=` +
      status +
      `&isVendorTiering=true`;
    // `&access_token=` +
    // access_token;
  } else if (
    PageName === "VENDORASSESSMENTAWATINGLISTING" &&
    pageNameInState === "VendorAssessmentAwatingListing"
  ) {
    console.log("VENDORASSESSMENTAWATINGLISTING test");
    methodType = "POST";
    const sortField = props.sortField || "name";
    const type = props.type;
    const status = props.surveyStatus;
    const awatingResponse = props.awatingResponse;

    handler =
      // `http://192.168.0.37:7001/getAllCompanyUserByPagination?start=` +
      `${SurveyService_URL}/Apc_View/Amc_30/listingAssessmentsForVendor?start=` +
      page +
      `&length=` +
      result +
      `&sortOrder=` +
      sortOrder +
      `&sortField=` +
      sortField +
      `&booleanfield=` +
      active +
      `&search=` +
      search +
      `&searchCol=` +
      searchCol +
      `&type=` +
      type +
      `&surveyStatus=` +
      status;
    // `&access_token=` +
    // access_token;
  } else if (
    PageName === "VENDORASSESSMENTDRAFTLISTING" &&
    pageNameInState === "VendorAssessmentDraftListing"
  ) {
    console.log("VENDORASSESSMENTDRAFTLISTING test");
    methodType = "POST";
    const sortField = props.sortField || "name";
    const type = props.type;
    const status = props.surveyStatus;
    const awatingResponse = props.awatingResponse;

    handler =
      // `http://192.168.0.37:7001/getAllCompanyUserByPagination?start=` +
      `${SurveyService_URL}/Apc_View/Amc_30/listingAssessmentsForVendor?start=` +
      page +
      `&length=` +
      result +
      `&sortOrder=` +
      sortOrder +
      `&sortField=` +
      sortField +
      `&booleanfield=` +
      active +
      `&search=` +
      search +
      `&searchCol=` +
      searchCol +
      `&type=` +
      type +
      `&surveyStatus=` +
      status;
    // `&access_token=` +
    // access_token;
  } else if (
    PageName === "VENDORASSESSMENTLISTINGCOMPLETED" &&
    pageNameInState === "VendorAssessmentListingCompleted"
  ) {
    console.log("VENDORASSESSMENTLISTINGCOMPLETED test");
    methodType = "POST";
    const sortField = props.sortField || "name";
    const type = props.type;
    const status = props.surveyStatus;
    const awatingResponse = props.awatingResponse;

    handler =
      // `http://192.168.0.37:7001/getAllCompanyUserByPagination?start=` +
      `${SurveyService_URL}/Apc_View/Amc_30/listingAssessmentsForVendor?start=` +
      page +
      `&length=` +
      result +
      `&sortOrder=` +
      sortOrder +
      `&sortField=` +
      sortField +
      `&booleanfield=` +
      active +
      `&search=` +
      search +
      `&searchCol=` +
      searchCol +
      `&type=` +
      type +
      `&surveyStatus=` +
      status;
    // `&access_token=` +
    // access_token;
  } else if (
    PageName === "VENDORALLPUBLISHEDLISTING" &&
    pageNameInState === "VendorAllPublishedListing"
  ) {
    console.log("VENDORALLPUBLISHEDLISTING test");
    methodType = "POST";
    const sortField = props.sortField || "name";
    const type = props.type;
    const status = props.surveyStatus;
    const awatingResponse = props.awatingResponse;

    handler =
      // `http://192.168.0.37:7001/getAllCompanyUserByPagination?start=` +
      `${SurveyService_URL}/Apc_View/Amc_30/listingAssessmentsForVendor?start=` +
      page +
      `&length=` +
      result +
      `&sortOrder=` +
      sortOrder +
      `&sortField=` +
      sortField +
      `&booleanfield=` +
      active +
      `&search=` +
      search +
      `&searchCol=` +
      searchCol +
      `&type=` +
      type +
      `&surveyStatus=` +
      status;
    // `&access_token=` +
    // access_token;
  }
  return (dispatch) =>
    axios({
      method: methodType,
      data:
        PageName === "ASSETMASTER" ||
        PageName === "PURPOSELISTING" ||
        PageName === "CONTROLREGISTERLISTING" ||
        PageName === "HOSTINGPROVIDERMASTER" ||
        PageName === "HOSTINGTYPEMASTER" ||
        PageName === "DATASUBJECTMASTER" ||
        PageName === "DATADISCOVERYSUBJECTMASTER" ||
        PageName === "DATATRANSFERLISTING" ||
        PageName === "DESIGNATIONLISTING" ||
        PageName === "DATASUBJECTVOLRANGE" ||
        PageName === "DATADISCOVERYSUBJECTVOLRANGE" ||
        PageName === "DATADISPOSALMASTER" ||
        PageName === "DATADISCOVERYDISPOSALMASTER" ||
        PageName === "SECURITYTYPEMASTER" ||
        PageName === "DATASUBJECTELEMENTS" ||
        PageName === "DATADISCOVERYSUBJECTELEMENTS" ||
        PageName === "DATASUBJECTCATEGORIES" ||
        PageName === "DATADISCOVERYSUBJECTCATEGORIES"  ||
        PageName === "SECURITYMEASUREMASTER" ||
        PageName === "ASSETTYPEMASTER" ||
        PageName === "CONSENTPURPOSELISTING" ||
        PageName === "CONTROLLIBRARYLISTING" ||
        PageName === "VULNERABILITYLISTING" ||
        PageName === "RISKLIBRARYLISTING" ||
        PageName === "RISKWORKFLOWLISTING" ||
        PageName === "CONTROLFRAMEWORKLISTING" ||
        PageName === "CONTROLCATEGORIESLISTING" ||
        PageName === "RISKREGISTERLISTING" ||
        PageName === "RISKCATEGORIESLISTING" ||
        PageName === "THREATLISTING" ||
        PageName === "COOKIECATEGORIESLISTING" ||
        PageName === "CONSENTPOINTSLISTING" ||
        PageName === "CONSENTHEADLISTING" ||
        PageName === "ROPAMASTERLISTING" ||
        PageName === "DOMAINLISTING" ||
        PageName === "COOKIETEMPLATELISTING" ||
        PageName === "COOKIECLASSIFICATIONLISTING" ||
        PageName === "COLLECTIONSMASTERLISTING" ||
        PageName === "COLLECTIONINTERACTIONLISTING" ||
        PageName === "TEMPLATEFIELDMASTER" ||
        PageName === "VENDORINDUSTRYLISTING" ||
        PageName === "VENDORTYPELISTING" ||
        PageName === "VENDORSERVICELISTING" ||
        PageName === "VENDORREGISTERLISTING" ||
        PageName === "VENDORRISKRATINGLISTING" ||
        PageName === "VENDORTIERLISTING" ||
        PageName === "DOCUMENTTYPELISTING" ||
        PageName === "DATACONNECTIONS" ||
        PageName === "DATADISCOVERYSCANS"
          ? dataToPost
          : data,
      // url: `http://demo4521367.mockable.io/getAllAssetTypePagination`,
      url: `${handler}`,
      // data:value,
      headers: {
        Authorization: "Bearer " + access_token,
        "Content-Type": "application/json",
        transactionId: "1",
      },
    })
      .then((response) => {
        dispatch(applicationUsersRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(applicationUsersSuccess(response));

          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(applicationUsersError(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(applicationUsersError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(applicationUsersError(err));
              break;
            case 500:
              dispatch(applicationUsersError("Server Error"));
            default:
              dispatch(applicationUsersError("Unknown Error"));
              break;
          }
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export function applicationUsersRequest() {
  // set type on req
  let type = {
    FETCH_REQUEST: "FETCH_" + PageName,
    FETCH_FULFILLED: "FETCH_" + PageName + "_FULFILLED",
    FETCH_REJECTED: "FETCH_" + PageName + "_REJECTED",
  };
  listActions(type);

  return {
    type: type.FETCH_REQUEST,
    isPosting: true,
  };
}
export function applicationUsersError(error) {
  return {
    type: ListActions.FETCH_LIST_REJECTED,
    payload: error,
  };
}

export function logout() {
  return {
    type: ListActions.LOGOUT,
    payload: "Session Expired. Please Login Again",
  };
}

export function applicationUsersSuccess(props) {
  // set type on req
  let type = {
    FETCH_REQUEST: "FETCH_" + PageName,
    FETCH_FULFILLED: "FETCH_" + PageName + "_FULFILLED",
    FETCH_REJECTED: "FETCH_" + PageName + "_REJECTED",
  };
  listActions(type);

  return (dispatch) => {
    dispatch({
      type: type.FETCH_FULFILLED,
      payload: props,
    });
  };
}

export function setApplicationError() {
  return (dispatch) => dispatch(setErrorRequest());
}

export function setErrorRequest() {
  return {
    type: ListActions.SET_APPLICATION_ERROR,
  };
}

///////////////////////////////////////////////////////////////////////////
////////////////////////////////////Update Active Inactive Status/////////////
export const updateActiveInactiveActions = {
  UPDATE_STATUS: "UPDATE_STATUS",
  // SAVE_LOCATION_STATUS: 'UPDATEAINA_STATUS_LOCATION_STATUS',
  UPDATE_STATUS_SUCCESS: "UPDATE_STATUS_SUCCESS",
  UPDATE_STATUS_FAILURE: "UPDATE_STATUS_FAILURE",
  // FETCH_LOCATION_MASTER: 'FETCH_LOCATION_MASTER',
  SET_UPDATE_ERROR: "SET_UPDATE_ERROR",
  // LOGOUT:'LOGOUT'
};

export function updateStatusRequest() {
  return {
    type: updateActiveInactiveActions.UPDATE_STATUS,
    isPosting: true,
  };
}

export function updateStatusSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: updateActiveInactiveActions.UPDATE_STATUS_SUCCESS,
      payload: props,
    });
  };
}

export function updateStatusFailure(error) {
  return {
    type: updateActiveInactiveActions.UPDATE_STATUS_FAILURE,
    payload: error,
  };
}

export function updateActiveInactive(props) {
  let handler = "";
  if (PageName === "ASSETTYPEMASTER") {
    handler =
      `${AssetManagementService_URL}/Apc_State/Amc_9/deleteAssetType?assetTypeId=` +
      props.assetTypeId +
      `&status=` +
      props.isActive;
  } else if (PageName === "ASSETMASTER") {
    handler =
      `${AssetManagementService_URL}/Apc_State/Amc_8/activeInactiveAssetMaster
?assetId=` +
      props.assetId +
      `&status=` +
      props.isActive;
  } else if (PageName === "USERMASTER") {
    handler =
      `${UserManagementService_URL}/Apc_State/Amc_31/activeInactiveUser?userId=` +
      props.userId +
      `&status=` +
      props.isActive;
  } else if (PageName === "HOSTINGTYPEMASTER") {
    handler =
      `${SystemService_URL}/deleteHostProviderTypeMaster?hostProviderTypeId=` +
      props.hostingTypeId +
      `&status=` +
      props.isActive;
  } else if (PageName === "BREACHTYPELISTING") {
    handler =
      `${IncidentManagementService_URL}/Apc_State/Amc_37/deleteBreachTypeById?id=` +
      props.id +
      `&status=` +
      props.isActive;
  } else if (PageName === "BREACHINCIDENTREGISTER") {
    handler =
      `${IncidentManagementService_URL}/Apc_State/Amc_36/activeInactiveBreachIncident/` +
      props.id;
  } else if (PageName === "HOSTINGPROVIDERMASTER") {
    handler =
      `${SystemService_URL}/deleteHostingProviderMaster?hostProviderId=` +
      props.hostingProviderId +
      `&status=` +
      props.isActive;
  } else if (PageName === "DATASUBJECTCATEGORIES" || PageName === "DATADISCOVERYSUBJECTCATEGORIES") {
    handler =
      `${SystemService_URL}/deleteDataCategory?id=` +
      props.dataSubjectCategoryId +
      `&status=` +
      props.isActive;
  } else if (PageName === "DATASUBJECTELEMENTS" || PageName === "DATADISCOVERYSUBJECTELEMENTS") {
    handler =
      `${SystemService_URL}/deleteDataElement?id=` +
      props.dataSubjectElementId +
      `&status=` +
      props.isActive;
  } else if (PageName === "DATASUBJECTVOLRANGE" || PageName === "DATADISCOVERYSUBJECTVOLRANGE") {
    handler =
      `${SystemService_URL}/deleteDataVolume?dataVolumeId=` +
      props.dataVolumeId +
      `&status=` +
      props.isActive;
  } else if (PageName === "DATADISPOSALMASTER" || PageName === "DATADISCOVERYDISPOSALMASTER") {
    handler =
      `${SystemService_URL}/deleteDataDisposalMaster?dataDisposalId=` +
      props.dataDisposalId +
      `&status=` +
      props.isActive;
  } else if (PageName === "SECURITYMEASUREMASTER") {
    handler =
      `${SystemService_URL}/deleteSecurityMeasureMaster?securityMeasureId=` +
      props.securityMeasureId +
      `&status=` +
      props.isActive;
  } else if (PageName === "SECURITYTYPEMASTER") {
    handler =
      `${SystemService_URL}/deleteSecurityMeasureTypeMaster?securityMeasureTypeId=` +
      props.securityMeasureTypeId +
      `&status=` +
      props.isActive;
  } else if (PageName === "LEGALBASIS") {
    handler =
      `${SystemService_URL}/deleteLegalBasisById?id=` +
      props.legalBasisId +
      `&status=` +
      props.isActive;
  } else if (PageName === "PURPOSELISTING") {
    handler =
      `${SystemService_URL}/deletePurposeMaster?purposeId=` +
      props.purposeId +
      `&status=` +
      props.isActive;
  } else if (PageName === "COOKIECATEGORIESLISTING") {
    handler =
      `${CookieService_Url}/Apc_State/Amc_62/deleteCookieCategory?id=` +
      props.id +
      `&status=` +
      props.isActive;
  }
  //  else if (PageName === "ORGANISATIONMASTER") {
  //   handler =
  //     `${CompanyService_URL}/Apc_State/Amc_34/deleteOrganizationById/` +
  //     props.organizationId;
  // }
  else if (PageName === "ACTIVITYMASTER") {
    handler =
      `${CompanyService_URL}/Apc_State/Amc_34/deleteOrganizationById/` +
      props.organizationId;
  } else if (PageName === "REGULATIONMASTERLISTING") {
    handler =
      `${RopaManagementService_URL}/Apc_State/Amc_21/deleteRegulationMaster?regulationId=` +
      props.regulationId +
      `&status=` +
      props.isActive;
  } else if (PageName === "ROLEMASTER") {
    handler =
      `${AssetManagementService_URL}/Apc_State/Amc_33/deleteContactRoleById?contactRoleId=` +
      props.contactRoleId +
      `&status=` +
      props.isActive;
  } else if (PageName === "ROLEMANAGEMENTLISTING") {
    handler =
      `${EdgeService_URL}/activeInactiveCompanyRoles?id=` +
      props.id +
      `&status=` +
      props.isActive;
  } else if (PageName === "CONTROLREGISTERLISTING") {
    handler =
      `${RiskManagementService_URL}/activeInactiveControlRegister?controlRegisterId=` +
      props.id +
      `&status=` +
      props.isActive;
  } else if (PageName === "RISKREGISTERLISTING") {
    handler =
      `${RiskManagementService_URL}/activeInactiveRiskMaster?riskId=` +
      props.id +
      `&status=` +
      props.isActive;
  } else if (PageName === "DATARECIPIENTLISTING") {
    handler =
      `${RopaManagementService_URL}/Apc_State/Amc_27/deleteDataRecipientById?id=` +
      props.dataRecipientId +
      `&status=` +
      props.isActive;
  } else if (PageName === "DATAPROCESSORLISTING") {
    handler =
      `${RopaManagementService_URL}/Apc_State/Amc_24/deleteDataProcessorById?id=` +
      props.dataProcessorId +
      `&status=` +
      props.isActive;
  } else if (PageName === "DATASUBPROCESSORLISTING") {
    handler =
      `${RopaManagementService_URL}/Apc_State/Amc_26/deleteDataSubProcessorById?id=` +
      props.dataSubProcessorId +
      `&status=` +
      props.isActive;
  } else if (PageName === "ROPADATAPROCCATLISTING") {
    handler =
      `${RopaManagementService_URL}/Apc_State/Amc_25/deleteDataProcessorCategoryById?id=` +
      props.dataProcCatId +
      `&status=` +
      props.isActive;
  } else if (PageName === "DATACONTACTLISTING") {
    handler =
      `${RopaManagementService_URL}/Apc_State/Amc_19/deleteRopaContactDetailById?id=` +
      props.dataContactId +
      `&status=` +
      props.isActive;
  } else if (PageName === "DATATRANSFERLISTING") {
    handler =
      `${SystemService_URL}/deleteDataTransferMethod?id=` +
      props.dataTransferId +
      `&status=` +
      props.isActive;
  } else if (PageName === "DATASUBJECTMASTER" || PageName === "DATADISCOVERYSUBJECTMASTER") {
    handler =
      `${SystemService_URL}/deleteDataSubject?id=` +
      props.dataSubId +
      `&status=` +
      props.isActive;
  } else if (PageName === "TEMPLATEMANAGERLISTING") {
    handler =
      `${SurveyService_URL}/Apc_State/Amc_30/deleteSurvey?surveyId=` +
      props.id +
      `&status=` +
      props.isActive;
  } else if (PageName === "BREACHTEMPLATEMANAGERLISTING") {
    handler =
      `${IncidentManagementService_URL}/Apc_State/Amc_36/deleteBreach?breachId=` +
      props.id +
      `&status=` +
      props.isActive;
  } else if (PageName === "THREATLISTING") {
    handler =
      `${RiskManagementService_URL}/deleteThreat?threatId=` +
      props.threatId +
      `&status=` +
      props.isActive;
  } else if (PageName === "RISKWORKFLOWLISTING") {
    handler =
      `${RiskManagementService_URL}/deleteRiskWorkFlowMaster?riskWorkFlowMasterId=` +
      props.riskWorkFlowId +
      `&status=` +
      props.isActive;
  } else if (
    PageName === "TEMPLATEMANAGERDRAFTLISTING" ||
    PageName === "SURVEYTEMPLATESAWAITING" ||
    PageName === "TIERINGTEMPLATEMANAGERDRAFTLISTING" ||
    PageName === "TIERINGTEMPLATEMANAGERLISTING" ||
    PageName === "TIERINGTEMPLATESAWAITING"
  ) {
    handler =
      `${SurveyService_URL}/Apc_State/Amc_30/deleteSurvey?surveyId=` +
      props.id +
      `&status=` +
      props.isActive;
  } else if (
    PageName === "SURVEYDRAFTLISTING" ||
    PageName === "SURVEYASSESSMENTAWATINGLISTING" ||
    PageName === "VENDORDRAFTLISTING"
  ) {
    handler =
      `${SurveyService_URL}/Apc_State/Amc_30/deleteSurvey?surveyId=` +
      props.id +
      `&status=` +
      props.isActive;
  } else if (PageName === "SURVEYLISTINGCOMPLETED") {
    handler =
      `${SurveyService_URL}/Apc_State/Amc_30/deleteSurvey?surveyId=` +
      props.id +
      `&status=` +
      props.isActive;
  } else if (PageName === "TEMPLATELISTING") {
    handler =
      `${RopaManagementService_URL}/Apc_State/Amc_20/deleteRopaTemplate?templateId=` +
      props.ropaTempId +
      `&status=` +
      props.isActive;
  } else if (PageName === "ROPAMASTERLISTING") {
    handler =
      `${RopaManagementService_URL}/Apc_State/Amc_19/deleteRopa?id=` +
      props.ropaId +
      `&status=` +
      props.isActive;
  } else if (PageName === "TEMPLATEFIELDMASTER") {
    handler =
      `${RopaManagementService_URL}/Apc_State/Amc_19/deleteNewTemplateFieldByTemplateId?Id=` +
      props.ropaId +
      `&status=` +
      props.isActive;
  } else if (PageName === "DESIGNATIONLISTING") {
    handler =
      `${SystemService_URL}/deleteDesignationMaster?id=` +
      props.id +
      `&status=` +
      props.isActive;
  }
  if (PageName === "REQUESTTYPEMASTER") {
    handler =
      `${RequestManagement_URL}/deleteRequestType?id=` +
      props.id +
      `&status=` +
      props.isActive;
  }
  if (PageName === "REQUESTSOURCESMASTER") {
    handler =
      `${RequestManagement_URL}/deleteRequestSources?id=` +
      props.id +
      `&status=` +
      props.isActive;
  }
  if (PageName === "BREACHSOURCELISTING") {
    handler =
      `${IncidentManagementService_URL}/Apc_State/Amc_84/deleteBreachSourceById?id=` +
      props.id +
      `&status=` +
      props.isActive;
  }
  if (PageName === "REQUESTMASTER") {
    handler =
      `${RequestManagement_URL}/deleteRequest?id=` +
      props.id +
      `&status=` +
      props.isActive;
  }
  if (PageName === "CONSENTHEADLISTING") {
    handler =
      `${ConsentManagementService_URL}/Apc_State/Amc_56/deleteConsentHead?id=` +
      props.id +
      `&status=` +
      props.isActive;
  }
  if (PageName === "CONTROLCATEGORIESLISTING") {
    handler =
      `${RiskManagementService_URL}/Apc_State/Amc_9/deleteControlCategory?controlCategoryId=` +
      props.controlCategoryId +
      `&status=` +
      props.isActive;
  }
  if (PageName === "CONTROLFRAMEWORKLISTING") {
    handler =
      `${RiskManagementService_URL}/Apc_State/Amc_9/deleteControlFramework?controlFrameworkId=` +
      props.controlFrameworkId +
      `&status=` +
      props.isActive;
  }
  if (PageName === "CONTROLLIBRARYLISTING") {
    handler =
      `${RiskManagementService_URL}/Apc_State/Amc_9/deleteControlLibrary?controlLibraryId=` +
      props.controlLibraryId +
      `&status=` +
      props.isActive;
  }
  if (PageName === "VULNERABILITYLISTING") {
    handler =
      `${RiskManagementService_URL}/Apc_State/Amc_9/deleteVulnerabilityMaster?vulnerabilityId=` +
      props.vulnerabilityId +
      `&status=` +
      props.isActive;
  }
  if (PageName === "RISKLIBRARYLISTING") {
    handler =
      `${RiskManagementService_URL}/Apc_State/Amc_9/deleteRiskLibrary?riskLibraryId=` +
      props.riskLibraryId +
      `&status=` +
      props.isActive;
  }

  if (PageName === "RISKCATEGORIESLISTING") {
    handler =
      `${RiskManagementService_URL}/deleteRiskCategory?riskCategoryId=` +
      props.riskCategoryId +
      `&status=` +
      props.isActive;
  } else if (PageName === "CONSENTPURPOSELISTING") {
    handler =
      `${ConsentManagementService_URL}/Apc_State/Amc_57/deleteConsentPurpose?id=` +
      props.id +
      `&status=` +
      props.isActive;
  } else if (PageName === "CONSENTPOINTSLISTING") {
    handler =
      `${ConsentManagementService_URL}/Apc_State/Amc_52/deleteConsentPoint?id=` +
      props.id +
      `&status=` +
      props.isActive;
  } else if (PageName === "CONSENTPOINTSLISTING") {
    handler =
      `${ConsentManagementService_URL}/Apc_State/Amc_52/deleteConsentPoint?id=` +
      props.id +
      `&status=` +
      props.isActive;
  } else if (PageName === "DOMAINLISTING") {
    handler =
      `${CookieService_Url}/Apc_State/Amc_93/activateDeactivateDomain?domain=` +
      props.id +
      `&active=` +
      props.isActive;
  } else if (PageName === "COLLECTIONSMASTERLISTING") {
    handler =
      `${ConsentManagementService_URL}/Apc_State/Amc_90/deleteConsentPointEntry?id=` +
      props.id +
      `&status=` +
      props.isActive;
  } else if (PageName === "VENDORINDUSTRYLISTING") {
    handler =
      `${VendorService_URL}/Apc_State/Amc_85/deleteIndustryType?industryTypeId=` +
      props.industryTypeId +
      `&status=` +
      props.isActive;
  } else if (PageName === "VENDORTYPELISTING") {
    handler =
      `${VendorService_URL}/Apc_View/Amc_86/deleteVendorType?id=` +
      props.vendorTypeId +
      `&status=` +
      props.isActive;
  } else if (PageName === "VENDORSERVICELISTING") {
    handler =
      `${VendorService_URL}/Apc_View/Amc_88/deleteVendorCategory?vendorCategoryId=` +
      props.vendorCategoryId +
      `&status=` +
      props.isActive;
  } else if (PageName === "VENDORREGISTERLISTING") {
    handler =
      `${VendorService_URL}/Apc_State/Amc_89/deleteVendorMaster?vendorId=` +
      props.vendorId +
      `&status=` +
      props.isActive;
  } else if (PageName === "VENDORRISKRATINGLISTING") {
    handler =
      `${VendorService_URL}/Apc_Persist/Amc_97/deleteVendorRisk?id=` +
      props.id +
      `&status=` +
      props.isActive;
  } else if (PageName === "VENDORTIERLISTING") {
    handler =
      `${VendorService_URL}/Apc_Persist/Amc_98/deleteVendorTier?id=` +
      props.id +
      `&status=` +
      props.isActive;
  } else if (PageName === "DOCUMENTTYPELISTING") {
    handler =
      `${VendorService_URL}/Apc_View/Amc_99/deleteVendorDocumentType?id=` +
      props.id +
      `&status=` +
      props.isActive;
  } else if (PageName === "DATACONNECTIONS") {
    handler =
      `${DataConnection_URL}/activateOrDeactivateConnection?relationalDataCredentialsId=` +
      props.dataConnectionSubjectElementId +
      `&status=` +
      props.isActive;
  } else if (PageName === "DATADISCOVERYSCANS") {
    handler =
      `${DataConnection_URL}/update/Scan/ActiveFlag?dataScanId=` +
      props.dataConnectionSubjectElementId +
      `&active=` +
      props.isActive;
  }
  return (dispatch) =>
    axios({
      method: "post",

      url: handler,
      headers: {
        Authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        dispatch(updateStatusRequest());
        if (response.status === 200) {
          dispatch(updateStatusSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(updateStatusFailure(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(updateStatusFailure(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(updateStatusFailure(err));
              break;
            case 500:
              dispatch(updateStatusFailure("Server Error"));
              break;
            default:
              dispatch(updateStatusFailure("Unknown Error"));
              break;
          }
        } else {
          dispatch(clearLogoutData());
        }
      });
}

//////////////////////Update Active Inactive Location Status/////////////////////////////

export function getColumnsMaster(props, access_token) {
  return (dispatch) =>
    axios({
      method: "post",
      url: `${Column_URL}/getFieldList?masterId=` + props.masterId,
      headers: {
        authorization: "Bearer " + access_token,
      },
    }).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      } else if (response.status >= 401 && response.status < 500) {
        return response;
      } else {
        //dispatch(clearLogoutData());
        const error = new Error(response.statusText);
        // throw error;
        return error;
      }
    });
}
export const FetchHistoryActions = {
  FETCH_HISTORY: "FETCH_HISTORY",
  FETCH_HISTORY_STATUS: "FETCH_HISTORY_STATUS",
  FETCH_HISTORY_SUCCESS: "FETCH_HISTORY_SUCCESS",
  FETCH_HISTORY_FAILURE: "FETCH_HISTORY_FAILURE",
};

export function FetchHistoryRequest() {
  return {
    type: FetchHistoryActions.FETCH_HISTORY,
    isPosting: true,
  };
}

export function FetchHistorySuccess(props) {
  return (dispatch) => {
    dispatch({
      type: FetchHistoryActions.FETCH_HISTORY_SUCCESS,
      payload: props,
    });
  };
}

export function FetchHistoryFailure(error) {
  return {
    type: FetchHistoryActions.FETCH_HISTORY_FAILURE,
    payload: error,
  };
}

export function FetchHistoryError(error) {
  return {
    type: FetchHistoryActions.FETCH_HISTORY_FAILURE,
    payload: error,
  };
}

export function getHistoryIndi(props) {
  return (dispatch) =>
    axios({
      method: "post",
      url:
        `${SystemService_URL}/getHistoryFeedByCompanyIdAndEntityAndId?entityId=` +
        props.entityId +
        `&entityType=` +
        props.entityType,
      headers: {
        authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        dispatch(FetchHistoryRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(FetchHistorySuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(FetchHistoryError(error));
          throw error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          // switch (err.response.status) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}
export function getHistory(props) {
  return (dispatch) =>
    axios({
      method: "post",
      url:
        `${SystemService_URL}/getHistoryFeedByCompanyIdAndEntity?entityType=` +
        props.entityType,
      headers: {
        authorization: "Bearer " + props.access_token,
      },
    }).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      } else if (response.status >= 401 && response.status < 500) {
        return response;
      } else {
        //dispatch(clearLogoutData());
        const error = new Error(response.statusText);
        // throw error;
        return error;
      }
    });
}

export function downloadHistoryPdf(props) {
  return (dispatch) =>
    axios({
      method: "post",
      url:
        `${SystemService_URL}/downloadHistoryInPdf?entityType=` +
        props.entityType,
      headers: {
        authorization: "Bearer " + props.access_token,
      },
    }).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response;
      } else if (response.status >= 401 && response.status < 500) {
        return response;
      } else {
        //dispatch(clearLogoutData());
        const error = new Error(response.statusText);
        // throw error;
        return error;
      }
    });
}

export function saveColumnsMaster(props, access_token) {
  return (dispatch) =>
    axios({
      method: "post",
      data: props,
      url: `${Column_URL}/saveUserFields`,
      headers: {
        authorization: "Bearer " + access_token,
      },
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return response.data;
        } else if (response.status >= 401 && response.status < 500) {
          return response;
        } else {
          dispatch(clearLogoutData());
          const error = new Error(response.statusText);
          return error;
        }
      })
      .catch((err) => {
        //
        if (err.response) {
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export const ClearHistoryDetailsActions = {
  CLEAR_HISTORY_DETAILS: "CLEAR_HISTORY_DETAILS",
};

export function clearHistoryData() {
  return (dispatch) => dispatch(clearHistoryData());
}

export function clearHistoryDetails() {
  return {
    type: ClearHistoryDetailsActions.CLEAR_HISTORY_DETAILS,
  };
}

export function getMaster(access_token, endpoint) {
  const active = true;
  // var endpoint =
  //     `${AssetManagementService_URL}/Apc_View/Amc_9/getAllAssetTypePagination?length=0&status=`+active;
  return (dispatch) =>
    axios({
      method: "post",
      url: endpoint,
      headers: {
        authorization: "Bearer " + access_token,
      },
    }).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      } else if (response.status >= 401 && response.status < 500) {
        return response;
      } else {
        const error = new Error(response.statusText);
        // throw error;
        return error;
      }
    });
}

// for delete organization master tree node
export function removeOrganization(props) {
  let handler =
    `${CompanyService_URL}/Apc_State/Amc_34/deleteOrganizationById/` +
    props.organizationId;

  return (dispatch) =>
    axios({
      method: "post",

      url: handler,
      headers: {
        Authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        dispatch(updateStatusRequest());
        if (response.status === 200) {
          dispatch(updateStatusSuccess(response));
          return response;
        } else {
          const error = new Error(response.statusText);
          dispatch(updateStatusFailure(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(updateStatusFailure(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(updateStatusFailure(err));
              break;
            case 500:
              dispatch(updateStatusFailure("Server Error"));
              break;
            default:
              dispatch(updateStatusFailure("Unknown Error"));
              break;
          }
        } else {
          dispatch(clearLogoutData());
        }
      });
}

export const forDpoReviewActions = {
  GET_DPO_REVIEW: "GET_DPO_REVIEW",
};

export function getDpoReviewStatus(props) {
  return (dispatch) => dispatch(getDpoReviewDetailsSuccess(props));
}

export function getDpoReviewDetailsSuccess(props) {
  return {
    type: forDpoReviewActions.GET_DPO_REVIEW,
    payload: props,
  };
}

export const forDpoReviewSecondActions = {
  GET_ROPA_TEMPLATE_TAB: "GET_ROPA_TEMPLATE_TAB",
};

export function getDpoReviewSecondStatus(props) {
  return (dispatch) => dispatch(getDpoReviewDetailsSecondSuccess(props));
}

export function getDpoReviewDetailsSecondSuccess(props) {
  return {
    type: forDpoReviewSecondActions.GET_ROPA_TEMPLATE_TAB,
    payload: props,
  };
}
