import axios from "axios";
import { AssetManagementService_URL, SystemService_URL, DataConnection_URL } from "../Url";
import HashMap from "hashmap";
import { clearLogoutData } from "../Logout/actions";

var map = new HashMap();

function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

// Save AssetType

export const SaveDataConnElementActions = {
  SAVE_DATA_CONN_ELE: "SAVE_DATA_CONN_ELE",
  SAVE_DATA_CONN_ELE_STATUS: "SAVE_DATA_CONN_ELE_STATUS",
  SAVE_DATA_CONN_ELE_SUCCESS: "SAVE_DATA_CONN_ELE_SUCCESS",
  SAVE_DATA_CONN_ELE_FAILURE: "SAVE_DATA_CONN_ELE_FAILURE",
};

export function SaveDataConnElementRequest() {
  return {
    type: SaveDataConnElementActions.SAVE_DATA_CONN_ELE,
    isPosting: true,
  };
}

export function SaveDataConnElementSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: SaveDataConnElementActions.SAVE_DATA_CONN_ELE_SUCCESS,
      payload: props,
    });
  };
}

export function SaveDataConnElementFailure(error) {
  return {
    type: SaveDataConnElementActions.SAVE_DATA_CONN_ELE_FAILURE,
    payload: error,
  };
}

export function SaveDataConnElementError(error) {
  return {
    type: SaveDataConnElementActions.SAVE_DATA_CONN_ELE_FAILURE,
    payload: error,
  };
}

export function saveDataConnElement(props) {
  console.log("saveDataConnElement props",props)
  var value = {
    url: props.dburl,
    userName: props.dbusername,
    password: props.dbpassword,
    dataStoreVendor: props.dataStoreVendor,
    connectionName: props.connectionname,
    relationalDataCredentialsId: props.dataConnectionElementId || 0,
    companyId: props.companyId,
    relationalDataMasterId: props.relationalDataMasterId,
    
    // dbusername: props.dbusername,
    // encrypt: props.encrypt,
    // integratedSecurity: props.integratedSecurity,
    // trustServerCertificate: props.trustServerCertificate
    // id: props.dataSubjectElementId || 0,
  };

  // var value = {
  //   ...props
  // }

  return (dispatch) =>
    axios({
      method: "post",
      data: value,
      url: `${DataConnection_URL}/save/relationalDataCredentials`,
      headers: {
        authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        dispatch(SaveDataConnElementRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(SaveDataConnElementSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(SaveDataConnElementError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          // switch (err.response.status) {
          //   case 400:
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;

          //     dispatch(SaveDataSubElementError(err));
          //     break;
          //   case 404:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach(ele => (err = ele));

          //     dispatch(SaveDataSubElementError(err));
          //     break;
          //   case 406:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach(ele => (err = ele));

          //     dispatch(SaveDataSubElementError(err));
          //     break;
          //   case 500:
          //     dispatch(SaveDataSubElementError("Server Error"));
          //     break;
          //   default:
          //     dispatch(SaveDataSubElementError("Unknown Error"));
          //     break;
          // }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

// clear  details
export const ClearFormDetailsActions = {
  CLEAR_FORM_DETAILS: "CLEAR_FORM_DETAILS",
};

export function clearFormData() {
  return (dispatch) => dispatch(clearFormDetails());
}

export function clearFormDetails() {
  return {
    type: ClearFormDetailsActions.CLEAR_FORM_DETAILS,
  };
}

// get  details by id

///////GET Data Source BY ID

export const GetDataSourceElementDetailsActions = {
  FETCH_DATA_SOURCE_ELE_DETAILS: "FETCH_DATA_SOURCE_ELE_DETAILS",
  FETCH_DATA_SOURCE_ELE_DETAILS_STATUS: "FETCH_DATA_SOURCE_ELE_DETAILS_STATUS",
  FETCH_DATA_SOURCE_ELE_DETAILS_FULFILLED: "FETCH_DATA_SOURCE_ELE_DETAILS_FULFILLED",
  FETCH_DATA_SOURCE_ELE_DETAILS_REJECTED: "FETCH_DATA_SOURCE_ELE_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getDataSourceElementDetails(params) {
  return (dispatch) =>
    axios({
      method: "get",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${DataConnection_URL}/getRelationalDatabases`,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getDataSourceElementDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getDataSourceElementDetailsSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getDataSourceElementDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getDataSourceElementDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getDataSourceElementDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getDataSourceElementDetailsError(err));
              break;
            case 500:
              dispatch(getDataSourceElementDetailsError("Server Error"));
              break;
            default:
              dispatch(getDataSourceElementDetailsError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getDataSourceElementDetailsRequest() {
  return {
    type: GetDataSourceElementDetailsActions.FETCH_DATA_SOURCE_ELE_DETAILS,
    isPosting: true,
  };
}
export function getDataSourceElementDetailsError(error) {
  return {
    type: GetDataSourceElementDetailsActions.FETCH_DATA_SOURCE_ELE_DETAILS_REJECTED,
    payload: error,
  };
}

export function getDataSourceElementDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type:
        GetDataSourceElementDetailsActions.FETCH_DATA_SOURCE_ELE_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getDataSourceElementDetailsStatus() {
  return (dispatch) => dispatch(getDataSourceElementDetailsStatusRequest());
}
export function getDataSourceElementDetailsStatusRequest() {
  return {
    type: GetDataSourceElementDetailsActions.FETCH_DATA_SOURCE_ELE_DETAILS_STATUS,
  };
}

export function setDetailsError() {
  return (dispatch) => dispatch(getDataSourceElementDetailsErrorRequest());
}
export function getDataSourceElementDetailsErrorRequest() {
  return {
    type: GetDataSourceElementDetailsActions.SET_EDIT_ERROR,
  };
}


// get connection details by id

///////GET CONNECTION BY ID

export const GetDataConnectionElementDetailsActions = {
  FETCH_DATA_CONNECTION_ELE_DETAILS: "FETCH_DATA_CONNECTION_ELE_DETAILS",
  FETCH_DATA_CONNECTION_ELE_DETAILS_STATUS: "FETCH_DATA_CONNECTION_ELE_DETAILS_STATUS",
  FETCH_DATA_CONNECTION_ELE_DETAILS_FULFILLED: "FETCH_DATA_CONNECTION_ELE_DETAILS_FULFILLED",
  FETCH_DATA_CONNECTION_ELE_DETAILS_REJECTED: "FETCH_DATA_CONNECTION_ELE_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getDataConnectionElementDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${DataConnection_URL}/get/ByRelationalDataCredentialsId?relationalDataCredentialsId=` +
        params.id,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getDataConnectionElementDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getDataConnectionElementDetailsSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getDataConnectionElementDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getDataConnectionElementDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getDataConnectionElementDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getDataConnectionElementDetailsError(err));
              break;
            case 500:
              dispatch(getDataConnectionElementDetailsError("Server Error"));
              break;
            default:
              dispatch(getDataConnectionElementDetailsError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getDataConnectionElementDetailsRequest() {
  return {
    type: GetDataConnectionElementDetailsActions.FETCH_DATA_CONNECTION_ELE_DETAILS,
    isPosting: true,
  };
}
export function getDataConnectionElementDetailsError(error) {
  return {
    type: GetDataConnectionElementDetailsActions.FETCH_DATA_CONNECTION_ELE_DETAILS_REJECTED,
    payload: error,
  };
}

export function getDataConnectionElementDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type:
        GetDataConnectionElementDetailsActions.FETCH_DATA_CONNECTION_ELE_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getDataConnectionElementDetailsStatus() {
  return (dispatch) => dispatch(getDataConnectionElementDetailsStatusRequest());
}
export function getDataConnectionElementDetailsStatusRequest() {
  return {
    type: GetDataConnectionElementDetailsActions.FETCH_DATA_CONNECTION_ELE_DETAILS_STATUS,
  };
}

// export function setDetailsError() {
//   return (dispatch) => dispatch(getDataConnectionElementDetailsErrorRequest());
// }
export function getDataConnectionElementDetailsErrorRequest() {
  return {
    type: GetDataConnectionElementDetailsActions.SET_EDIT_ERROR,
  };
}


///////GET SCAN DAta BY ID

export const GetDataScanElementDetailsActions = {
  FETCH_DATA_SCANS_ELE_DETAILS: "FETCH_DATA_SCANS_ELE_DETAILS",
  FETCH_DATA_SCANS_ELE_DETAILS_STATUS: "FETCH_DATA_SCANS_ELE_DETAILS_STATUS",
  FETCH_DATA_SCANS_ELE_DETAILS_FULFILLED: "FETCH_DATA_SCANS_ELE_DETAILS_FULFILLED",
  FETCH_DATA_SCANS_ELE_DETAILS_REJECTED: "FETCH_DATA_SCANS_ELE_DETAILS_REJECTED",
  SET_EDIT_ERROR: "SET_EDIT_ERROR",
};

export function getDataScanElementDetails(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url:
        `${DataConnection_URL}/get/scan/ByDataScanId?dataScanId=` +
        params.dataScanId,
      headers: {
        transactionId: "1",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getDataScanElementDetailsRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getDataScanElementDetailsSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getDataScanElementDetailsError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getDataScanElementDetailsError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getDataScanElementDetailsError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getDataScanElementDetailsError(err));
              break;
            case 500:
              dispatch(getDataScanElementDetailsError("Server Error"));
              break;
            default:
              dispatch(getDataScanElementDetailsError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getDataScanElementDetailsRequest() {
  return {
    type: GetDataScanElementDetailsActions.FETCH_DATA_SCANS_ELE_DETAILS,
    isPosting: true,
  };
}
export function getDataScanElementDetailsError(error) {
  return {
    type: GetDataScanElementDetailsActions.FETCH_DATA_SCANS_ELE_DETAILS_REJECTED,
    payload: error,
  };
}

export function getDataScanElementDetailsSuccess(props) {
  return (dispatch) => {
    dispatch({
      type:
        GetDataScanElementDetailsActions.FETCH_DATA_SCANS_ELE_DETAILS_FULFILLED,
      payload: props,
    });
  };
}

export function getDataScanElementDetailsStatus() {
  return (dispatch) => dispatch(getDataScanElementDetailsStatusRequest());
}
export function getDataScanElementDetailsStatusRequest() {
  return {
    type: GetDataScanElementDetailsActions.FETCH_DATA_SCANS_ELE_DETAILS_STATUS,
  };
}

// export function setDetailsError() {
//   return (dispatch) => dispatch(getDataScanElementDetailsErrorRequest());
// }
export function getDataScanElementDetailsErrorRequest() {
  return {
    type: GetDataScanElementDetailsActions.SET_EDIT_ERROR,
  };
}


//////////////////////////////////////////////////////////////////////////////////////////
export const UploadexcelActions = {
  CREATE_UPLOAD_EXCEL: "CREATE_UPLOAD_EXCEL",
  CREATE_UPLOAD_EXCEL_SUCCESS: "CREATE_UPLOAD_EXCEL_SUCCESS",
  CREATE_UPLOAD_EXCEL_FAILURE: "CREATE_UPLOAD_EXCEL_FAILURE",
};

export function UploadexcelRequest() {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL,
    isPosting: true,
  };
}

export function UploadexcelSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS,
      payload: props,
    });
  };
}

export function UploadexcelFailure(error) {
  return {
    type: UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE,
    payload: error,
  };
}

export function UploadExcel(props) {
  
  let data = new FormData();
  data.append("hostingTypes", props.Excel);

  return (dispatch) =>
    axios({
      method: "post",
      data: data,
      url:
        `${AssetManagementService_URL}/bulkUploadExcel`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.access_token,
        transactionId: "1",
      },
    })
      .then((response) => {
        dispatch(UploadexcelRequest());
        if (response.status === 200) {
          dispatch(UploadexcelSuccess(response));
        } else {
          
          const error = new Error(response.statusText);
          dispatch(UploadexcelFailure(error));
          throw error;
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              
              dispatch(UploadexcelFailure(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              
              dispatch(UploadexcelFailure(err));
              break;
            case 500:
              dispatch(UploadexcelFailure("Server Error"));
              break;
            default:
              dispatch(UploadexcelFailure("Unknown Error"));
              break;
          }
        } else {
          dispatch(UploadexcelFailure("Internal Server Error"));
          dispatch(clearLogoutData());
        }
      });
}

export const GetActiveDataSubEleActions = {
  FETCH_ACTIVE_DATA_SUBJECT_ELE: "FETCH_ACTIVE_DATA_SUBJECT_ELE",
  FETCH_ACTIVE_DATA_SUBJECT_ELE_STATUS: "FETCH_ACTIVE_DATA_SUBJECT_ELE_STATUS",
  FETCH_ACTIVE_DATA_SUBJECT_ELE_FULFILLED:
    "FETCH_ACTIVE_DATA_SUBJECT_ELE_FULFILLED",
  FETCH_ACTIVE_DATA_SUBJECT_ELE_REJECTED:
    "FETCH_ACTIVE_DATA_SUBJECT_ELE_REJECTED",
};
export function getActiveDataSubEleList(params) {
  return (dispatch) =>
    axios({
      method: "post",
      // data: JSON.stringify( {
      //           ...params
      //         }),
      url: `${SystemService_URL}/Apc_View/Amc_16/getAllDataElementPagination?length=0&sortOrder=desc&sortField=id&booleanfield=true`,
      headers: {
        transactionId: "1",
        "Content-Type": "application/json",
        Authorization: "Bearer " + params.access_token,
      },
    })
      .then((response) => {
        dispatch(getActiveDataSubEleListRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(getActiveDataSubEleListSuccess(response));
          return response;
        } else {
          
          const error = new Error(response.statusText);
          dispatch(getActiveDataSubEleListError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          switch (err.response.status) {
            case 400:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveDataSubEleListError(err));
              break;
            case 404:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveDataSubEleListError(err));
              break;
            case 406:
              map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

              map.forEach((ele) => (err = ele));

              dispatch(getActiveDataSubEleListError(err));
              break;
            case 500:
              dispatch(getActiveDataSubEleListError("Server Error"));
              break;
            default:
              dispatch(getActiveDataSubEleListError("Unknown Error"));
              break;
          }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}

export function getActiveDataSubEleListRequest() {
  return {
    type: GetActiveDataSubEleActions.FETCH_ACTIVE_DATA_SUBJECT_ELE,
    isPosting: true,
  };
}
export function getActiveDataSubEleListError(error) {
  return {
    type: GetActiveDataSubEleActions.FETCH_ACTIVE_DATA_SUBJECT_ELE_REJECTED,
    payload: error,
  };
}

export function getActiveDataSubEleListSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: GetActiveDataSubEleActions.FETCH_ACTIVE_DATA_SUBJECT_ELE_FULFILLED,
      payload: props,
    });
  };
}

export function getActiveDataSubEleListStatus() {
  return (dispatch) => dispatch(getActiveDataSubEleListStatusRequest());
}
export function getActiveDataSubEleListStatusRequest() {
  return {
    type: GetActiveDataSubEleActions.FETCH_ACTIVE_DATA_SUBJECT_ELE_STATUS,
  };
}


// test Conection

export const ValidateDataConnElementActions = {
  VALIDATE_DATA_CONN_ELE: "VALIDATE_DATA_CONN_ELE",
  VALIDATE_DATA_CONN_ELE_STATUS: "VALIDATE_DATA_CONN_ELE_STATUS",
  VALIDATE_DATA_CONN_ELE_SUCCESS: "VALIDATE_DATA_CONN_ELE_SUCCESS",
  VALIDATE_DATA_CONN_ELE_FAILURE: "VALIDATE_DATA_CONN_ELE_FAILURE",
};

export function ValidateDataConnElementRequest() {
  return {
    type: ValidateDataConnElementActions.VALIDATE_DATA_CONN_ELE,
    isPosting: true,
  };
}

export function ValidateDataConnElementSuccess(props) {
  return (dispatch) => {
    dispatch({
      type: ValidateDataConnElementActions.VALIDATE_DATA_CONN_ELE_SUCCESS,
      payload: props,
    });
  };
}

export function ValidateDataConnElementFailure(error) {
  return {
    type: ValidateDataConnElementActions.VALIDATE_DATA_CONN_ELE_FAILURE,
    payload: error,
  };
}

export function ValidateDataConnElementError(error) {
  return {
    type: ValidateDataConnElementActions.VALIDATE_DATA_CONN_ELE_FAILURE,
    payload: error,
  };
}

export function validateDataConnElement(props) {
  console.log("validateDataConnElement props",props)
  var value = {
    url: props.dburl,
    userName: props.dbusername,
    password: props.dbpassword,
    dataStoreVendor: props.dataStoreVendor,
    connectionName: props.connectionname,
    relationalDataCredentialsId: props.dataConnectionElementId || 0,
    companyId: props.companyId,
    relationalDataMasterId: props.relationalDataMasterId,
    
    // dbusername: props.dbusername,
    // encrypt: props.encrypt,
    // integratedSecurity: props.integratedSecurity,
    // trustServerCertificate: props.trustServerCertificate
    // id: props.dataSubjectElementId || 0,
  };

  // var value = {
  //   ...props
  // }

  return (dispatch) =>
    axios({
      method: "post",
      data: value,
      url: `${DataConnection_URL}/databaseConnectionTest`,
      headers: {
        authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        dispatch(ValidateDataConnElementRequest());
        if (response.status >= 200 && response.status < 300) {
          dispatch(ValidateDataConnElementSuccess(response));
          return response;
        } else if (response.status === 401) {
        } else {
          const error = new Error(response.statusText);
          dispatch(ValidateDataConnElementError(error));
          throw error;
        }
      })
      .catch((err) => {
        
        if (err.response) {
          // switch (err.response.status) {
          //   case 400:
          map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          map.forEach((ele) => (err = ele));
          return err;

          //     dispatch(SaveDataSubElementError(err));
          //     break;
          //   case 404:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach(ele => (err = ele));

          //     dispatch(SaveDataSubElementError(err));
          //     break;
          //   case 406:
          //     map = objToStrMap(JSON.parse(JSON.stringify(err.response.data)));

          //     map.forEach(ele => (err = ele));

          //     dispatch(SaveDataSubElementError(err));
          //     break;
          //   case 500:
          //     dispatch(SaveDataSubElementError("Server Error"));
          //     break;
          //   default:
          //     dispatch(SaveDataSubElementError("Unknown Error"));
          //     break;
          // }
        } else {
          // //dispatch(logout());
          dispatch(clearLogoutData());
        }
      });
}