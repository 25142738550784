import {
  saveConsentPointActions,
  getConsentPointDetailsActions,
  ClearFormDetailsActions,
  UploadexcelActions,
  deleteConsentPointActions,  getConsentPointSummaryDataActions,
  getConsentPointGraphDataActions,
  getConsentEntryListActions,
  getConsentPointApiActions,
  getConsentEntryDataActions,
  GetActiveConsentPointActions,
  getPurposesDataActions,
  saveOfflineConsentPointActions,
  getcalculateExpiryDateActions,
  getPurposeByRegistryCodeActions,
  getLawfullBasisActions,
  getIPAddressDataActions
} from "./actions";

const initState = {
  consentSuccessMessage: null,
  consentPointErrorMessage: null,
  consentPointStatus: null,
  consentPointMaster: [],
  consentPointData: null,
  ActiveConsentPointList:null,
  ConsentEntryList:null,
  ConsentEntryPointData:null,
  ConsentGraphData:null,
  collection:null,
  viewInteractionFilter:false,
  consentPointCollectionData: null,
  consentPurposeData:[],
  consentOfflinePointStatus:null,
  consentExpdateData:[],
  purposeRegistryCodeData:[],
  purposeRegistryCodeMsg:null,
  lawfulBasisData:[],
  lawfulBasisMsg:null,
  ipData:[],
  ipMsg:null,

};

export default function ContactList(state = initState, { type, payload }) {
  switch (type) {
    case saveConsentPointActions.SAVE_CONSENT_POINT:
      return {
        ...state,
        isLoading: true,
        consentPointErrorMessage: null,
        modalActive: false
      };
    case saveConsentPointActions.SAVE_CONSENT_POINT_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        consentPointStatus: payload.status,
        consentPointErrorMessage: null,
        consentPointSuccessMessage: "Consent Point added successfully!"
      };

    case saveConsentPointActions.SAVE_CONSENT_POINT_FAILURE:
      return {
        ...state,
        isLoading: false,
        consentPointStatus: null,
        consentPointErrorMessage: payload
      };
    case saveConsentPointActions.SAVE_CONSENT_POINT_STATUS:
      return {
        ...state,
        consentPointStatus: 0,
        consentPointErrorMessage: null
      };
    ///////////////////////////////////////////////////////
    //delete consent Head By id
    

    case deleteConsentPointActions.DELETE_CONSENT_POINT:
      return {
        ...state,
        isLoading: true,
        consentPointErrorMessage: null,
        modalActive: false,
      };
    case deleteConsentPointActions.DELETE_CONSENT_POINT_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        consentPointStatus: payload.status,
        consentPointErrorMessage: null,
        consentSuccessMessage: "Consent Head Deleted successfully!",
      };

    case deleteConsentPointActions.DELETE_CONSENT_POINT_FAILURE:
      return {
        ...state,
        isLoading: false,
        consentPointStatus: null,
        consentPointErrorMessage: payload,
      };
    case deleteConsentPointActions.DELETE_CONSENT_POINT_STATUS:
      return {
        ...state,
        consentPointStatus: 0,
        consentPointErrorMessage: null,
      };

    // GET  BY ID
    case getConsentPointDetailsActions.FETCH_CONSENT_POINT_DETAILS:
      return {
        ...state,
        isLoading: true,
        consentPointErrorMessage: null,
        modalActive: false,
        consentPointData: null
      };
    case getConsentPointDetailsActions.FETCH_CONSENT_POINT_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        consentPointData: payload.data,
        consentPointDataStatus: payload.status,
        consentPointErrorMessage: null  
      };
    case getConsentPointDetailsActions.FETCH_CONSENT_POINT_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        consentPointErrorMessage: payload,
        consentPointData: null
      };
    case getConsentPointDetailsActions.FETCH_CONSENT_POINT_DETAILS_STATUS:
      return {
        ...state,
        consentPointData: null
      };
    // clear ConsentPurpose details
    case ClearFormDetailsActions.CLEAR_FORM_DETAILS:
      return {
        ...state,
        GetConsentPoint: null,
        consentPointStatus: null,
        HeadSuccessMessage: null,
        GetConsentPointStatus: null,
        ActiveConsentPointList:null,
        consentPointData: null
      };
// View  BY ID
case getConsentPointSummaryDataActions.FETCH_CONSENT_POINT_SUMMARY_DATA:
  return {
    ...state,
    isLoading: true,
    consentPointErrorMessage: null,
    modalActive: false,
    consentPointData: null
  };
case getConsentPointSummaryDataActions.FETCH_CONSENT_POINT_SUMMARY_DATA_FULFILLED:
  return {
    ...state,
    loading: true,
    isLoading: false,
    ActiveConsentPointList: payload.data,
    consentPointDataStatus: payload.status,
    consentPointErrorMessage: null  
  };
case getConsentPointSummaryDataActions.FETCH_CONSENT_POINT_DETAILS_REJECTED:
  return {
    ...state,
    isLoading: false,
    consentPointErrorMessage: payload,
    consentPointData: null
  };
case getConsentPointSummaryDataActions.FETCH_CONSENT_POINT_SUMMARY_DATA_STATUS
:
  return {
    ...state,
    consentPointData: null
  };
 
    /*Excel Actions */

    case UploadexcelActions.CREATE_UPLOAD_EXCEL:
      return {
        ...state
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS:
      
      return {
        ...state,
        ItineraryImage: payload.data,
        ItineraryStatus: payload.status
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE:
      // error = payload || { message: payload.message };//2nd one is network or server down errors
      return {
        ...state,
        errorMessage: payload
      };
    /* */
// View  BY Graph
case getConsentPointGraphDataActions.FETCH_CONSENT_POINT_GRAPH:
  return {
    ...state,
    isLoading: true,
    consentPointErrorMessage: null,
    modalActive: false,
    consentPointData: null
  };
case getConsentPointGraphDataActions.FETCH_CONSENT_POINT_GRAPH_FULFILLED:
  return {
    ...state,
    loading: true,
    isLoading: false,
    ConsentGraphData: payload.data,
    consentPointDataStatus: payload.status,
    consentPointErrorMessage: null  
  };
case getConsentPointGraphDataActions.FETCH_CONSENT_POINT_GRAPH_REJECTED:
  return {
    ...state,
    isLoading: false,
    consentPointErrorMessage: payload,
    consentPointData: null
  };
  // View  Entry POint
case getConsentEntryListActions.FETCH_CONSENT_ENTRY_LIST:
  return {
    ...state,
    isLoading: true,
    consentPointErrorMessage: null,
    modalActive: false,
    ConsentEntryList: null
  };
case getConsentEntryListActions.FETCH_CONSENT_ENTRY_LIST_FULFILLED:
  return {
    ...state,
    loading: true,
    isLoading: false,
    ConsentEntryList: payload.data,
    consentPointDataStatus: payload.status,
    viewInteractionFilter:true  
  };
case getConsentEntryListActions.FETCH_CONSENT_ENTRY_LIST_REJECTED:
  return {
    ...state,
    isLoading: false,
    consentPointErrorMessage: payload,
    ConsentEntryList: null
  };

  ///Entry Data
  case getConsentEntryDataActions.FETCH_CONSENT_ENTRY_DATA:
  return {
    ...state,
    isLoading: true,
    consentPointErrorMessage: null,
    modalActive: false,
    ConsentEntryPointData: null
  };
case getConsentEntryDataActions.FETCH_CONSENT_ENTRY_DATA_FULFILLED:
  return {
    ...state,
    loading: true,
    isLoading: false,
    ConsentEntryPointData: payload.data,
    collection:payload,
    consentPointDataStatus: payload.status,
    consentPointErrorMessage: null  
  };
case getConsentEntryDataActions.FETCH_CONSENT_ENTRY_DATA_REJECTED:
  return {
    ...state,
    isLoading: false,
    consentPointErrorMessage: payload,
    ConsentEntryPointData: null
  };
  ///OPEN API
  case getConsentPointApiActions.FETCH_CONSENT_POINT_API:
  return {
    ...state,
    isLoading: true,
    consentPointErrorMessage: null,
    modalActive: false,
    consentPointAPI: null
  };
case getConsentPointApiActions.FETCH_CONSENT_POINT_API_FULFILLED:
  return {
    ...state,
    loading: true,
    isLoading: false,
    consentPointAPI: payload.data,
    consentPointDataStatus: payload.status,
    consentPointErrorMessage: null  
  };
case getConsentPointApiActions.FETCH_CONSENT_POINT_API_REJECTED
:
  return {
    ...state,
    isLoading: false,
    consentPointErrorMessage: payload,
    consentPointAPI: null
  };
  case GetActiveConsentPointActions.FETCH_ACTIVE_CONSENT_POINT:
    return {
      ...state,
      isLoading: true,
      consentPointErrorMessage: null,
      modalActive: false,
      consentPointCollectionData: null
    };
  case GetActiveConsentPointActions.FETCH_ACTIVE_CONSENT_POINT_FULFILLED:
    return {
      ...state,
      loading: true,
      isLoading: false,
      consentPointCollectionData: payload.data,
      consentPointDataStatus: payload.status,
      consentPointErrorMessage: null  
    };
  case GetActiveConsentPointActions.FETCH_ACTIVE_CONSENT_POINT_REJECTED:
    return {
      ...state,
      isLoading: false,
      consentPointErrorMessage: payload,
      consentPointCollectionData: null
    };
  case GetActiveConsentPointActions.FETCH_ACTIVE_CONSENT_POINT_STATUS:
    return {
      ...state,
      consentPointCollectionData: null
    };

    case getPurposesDataActions.FETCH_PURPOSES_DATA:
      return {
        ...state,
        isLoading: true,
        consentPointErrorMessage: null,
        modalActive: false,
        consentPurposeData: null
      };
    case getPurposesDataActions.FETCH_PURPOSES_DATA_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        consentPurposeData: payload.data,
        consentPointDataStatus: payload.status,
        consentPointErrorMessage: null  
      };
    case getPurposesDataActions.FETCH_PURPOSES_DATA_REJECTED
    :
      return {
        ...state,
        isLoading: false,
        consentPointErrorMessage: payload,
        consentPurposeData: null
      };
      case saveOfflineConsentPointActions.SAVE_OFFLINE_CONSENT_POINT:
      return {
        ...state,
        isLoading: true,
        consentPointErrorMessage: null,
        modalActive: false
      };
    case saveOfflineConsentPointActions.SAVE_OFFLINE_CONSENT_POINT_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        consentOfflinePointStatus: payload.status,
        consentPointErrorMessage: null,
        consentPointSuccessMessage: "Consent Point added successfully!"
      };

    case saveOfflineConsentPointActions.SAVE_OFFLINE_CONSENT_POINT_FAILURE:
      return {
        ...state,
        isLoading: false,
        consentOfflinePointStatus: null,
        consentPointErrorMessage: payload
      };
    case saveOfflineConsentPointActions.SAVE_OFFLINE_CONSENT_POINT_STATUS:
      return {
        ...state,
        consentOfflinePointStatus: 0,
        consentPointErrorMessage: null
      };
      case getcalculateExpiryDateActions.FETCH_CALCULATED_EXP_DATA:
      return {
        ...state,
        isLoading: true,
        consentPointErrorMessage: null,
        modalActive: false,
        consentExpdateData: null
      };
    case getcalculateExpiryDateActions.FETCH_CALCULATED_EXP_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        consentExpdateData: payload.data,
        consentPointDataStatus: payload.status,
        consentPointErrorMessage: null  
      };
    case getcalculateExpiryDateActions.FETCH_CALCULATED_EXP_REJECTED:
      return {
        ...state,
        isLoading: false,
        consentPointErrorMessage: payload,
        consentExpdateData: null
      };
      case getPurposeByRegistryCodeActions.FETCH_PURPOSE_BY_REGESTRY_DATA:
      return {
        ...state,
        isLoading: true,
        purposeRegistryCodeMsg: null,
        consentExpdateData: null
      };
    case getPurposeByRegistryCodeActions.FETCH_PURPOSE_BY_REGESTRY_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        purposeRegistryCodeData: payload.data,
        consentPointDataStatus: payload.status,
        purposeRegistryCodeMsg: null  
      };
    case getPurposeByRegistryCodeActions.FETCH_PURPOSE_BY_REGESTRY_REJECTED:
      return {
        ...state,
        isLoading: false,
        purposeRegistryCodeMsg: payload,
        purposeRegistryCodeData: null
      };
      case getLawfullBasisActions.FETCH_LAWFUL_BASIS_API:
      return {
        ...state,
        isLoading: true,
        lawfulBasisMsg: null,
        lawfulBasisData: null
      };
    case getLawfullBasisActions.FETCH_LAWFUL_BASIS_API_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        lawfulBasisData: payload.data,
        lawfulBasisMsg: null  
      };
    case getLawfullBasisActions.FETCH_LAWFUL_BASIS_API_REJECTED:
      return {
        ...state,
        isLoading: false,
        lawfulBasisMsg: payload,
        lawfulBasisData: null
      };
      
      case getIPAddressDataActions.FETCH_IP_ADDRESS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ipData: payload.data,
        ipMsg: null  
      };
    case getIPAddressDataActions.FETCH_IP_ADDRESS_REJECTED:
      return {
        ...state,
        isLoading: false,
        ipMsg: payload,
        ipData: null
      };
    default:
      return state;
  }
}
