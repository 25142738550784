import {
  ClearFormDetailsActions,
  UploadexcelActions,
  GetActiveDataSubEleActions,
  SaveDataConnElementActions,
  GetDataSourceElementDetailsActions,
  GetDataConnectionElementDetailsActions,
  GetDataScanElementDetailsActions,
} from "./actions";

const initState = {
  dataSubEleSuccessMessage: null,
  dataSubEleErrorMessage: null,
  dataSubEleStatus: null,
  dataSubEleMaster: [],
  dataSourceEleData: null,
  ActiveDataSubEleList: null,
  dataConnectionEleData: null,
  dataConnectionEleErrorMessage: null,
  dataConnectionEleDataStatus: null,
  dataScansEleData: null,
  dataScansEleErrorMessage: null,
  dataScansEleDataStatus: null,
};

export default function ContactList(state = initState, { type, payload }) {
  switch (type) {
    case SaveDataConnElementActions.SAVE_DATA_CONN_ELE:
      return {
        ...state,
        isLoading: true,
        dataSubEleErrorMessage: null,
        modalActive: false
      };
    case SaveDataConnElementActions.SAVE_DATA_CONN_ELE_SUCCESS:
      return {
        ...state,
        loading: true,
        isLoading: false,
        dataSubEleStatus: payload.status,
        dataSubEleErrorMessage: null,
        dataSubEleSuccessMessage: "Data Connection added successfully!"
      };

    case SaveDataConnElementActions.SAVE_DATA_CONN_ELE_FAILURE:
      return {
        ...state,
        isLoading: false,
        dataSubEleStatus: null,
        dataSubEleErrorMessage: payload
      };
    case SaveDataConnElementActions.SAVE_DATA_CONN_ELE_STATUS:
      return {
        ...state,
        dataSubEleStatus: 0,
        dataSubEleErrorMessage: null
      };

    ///////////////////////////////////////////////////////
    // GET CONNECTION BY ID
    case GetDataConnectionElementDetailsActions.FETCH_DATA_CONNECTION_ELE_DETAILS:
      return {
        ...state,
        isLoading: true,
        dataConnectionEleErrorMessage: null,
        modalActive: false,
        dataConnectionEleData: null
      };
    case GetDataConnectionElementDetailsActions.FETCH_DATA_CONNECTION_ELE_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        dataConnectionEleData: payload.data,
        dataConnectionEleDataStatus: payload.status,
        dataConnectionEleErrorMessage: null
      };
    case GetDataConnectionElementDetailsActions.FETCH_DATA_CONNECTION_ELE_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        dataConnectionEleErrorMessage: payload,
        dataConnectionEleData: null
      };
    case GetDataConnectionElementDetailsActions.FETCH_DATA_CONNECTION_ELE_DETAILS_STATUS:
      return {
        ...state,
        dataConnectionEleData: null
      };

      ///////////////////////////////////////////////////////
    // GET Scans BY ID
    case GetDataScanElementDetailsActions.FETCH_DATA_SCANS_ELE_DETAILS:
      return {
        ...state,
        isLoading: true,
        dataScansEleErrorMessage: null,
        modalActive: false,
        dataScansEleData: null
      };
    case GetDataScanElementDetailsActions.FETCH_DATA_SCANS_ELE_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        dataScansEleData: payload.data,
        dataScansEleDataStatus: payload.status,
        dataScansEleErrorMessage: null
      };
    case GetDataScanElementDetailsActions.FETCH_DATA_SCANS_ELE_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        dataScansEleErrorMessage: payload,
        dataScansEleData: null
      };
    case GetDataScanElementDetailsActions.FETCH_DATA_SCANS_ELE_DETAILS_STATUS:
      return {
        ...state,
        dataScansEleData: null
      };

    ///////////////////////////////////////////////////////
    // GET  BY ID
    case GetDataSourceElementDetailsActions.FETCH_DATA_SOURCE_ELE_DETAILS:
      return {
        ...state,
        isLoading: true,
        dataSubEleErrorMessage: null,
        modalActive: false,
        dataSourceEleData: null
      };
    case GetDataSourceElementDetailsActions.FETCH_DATA_SOURCE_ELE_DETAILS_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        dataSourceEleData: payload.data,
        dataSourceEleDataStatus: payload.status,
        dataSubEleErrorMessage: null
      };
    case GetDataSourceElementDetailsActions.FETCH_DATA_SOURCE_ELE_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        dataSubEleErrorMessage: payload,
        dataSourceEleData: null
      };
    case GetDataSourceElementDetailsActions.FETCH_DATA_SOURCE_ELE_DETAILS_STATUS:
      return {
        ...state,
        dataSourceEleData: null
      };
    ///Active DATA DISPOSAL
    case GetActiveDataSubEleActions.FETCH_ACTIVE_DATA_SUBJECT_ELE:
      return {
        ...state,
        isLoading: true,
        dataSubEleErrorMessage: null,
        modalActive: false,
        ActiveDataSubEleList: null
      };
    case GetActiveDataSubEleActions.FETCH_ACTIVE_DATA_SUBJECT_ELE_FULFILLED:
      return {
        ...state,
        loading: true,
        isLoading: false,
        ActiveDataSubEleList: payload.data,
        dataSubEleErrorMessage: null
      };
    case GetActiveDataSubEleActions.FETCH_ACTIVE_DATA_SUBJECT_ELE_REJECTED:
      return {
        ...state,
        isLoading: false,
        dataSubEleErrorMessage: payload,
        ActiveDataSubEleList: null
      };
    case GetActiveDataSubEleActions.FETCH_ACTIVE_DATA_SUBJECT_ELE_STATUS:
      return {
        ...state,
        ActiveDataSubEleList: null
      };
    // clear assetType details
    case ClearFormDetailsActions.CLEAR_FORM_DETAILS:
      return {
        ...state,
        GetdataSubEle: null,
        dataSubEleStatus: null,
        dataSubEleSuccessMessage: null,
        GetdataSubEleStatus: null,
        dataSourceEleData: null,
        dataConnectionEleData: null,
        dataScansEleData:null
      };

    /*Excel Actions */

    case UploadexcelActions.CREATE_UPLOAD_EXCEL:
      return {
        ...state
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_SUCCESS:
      
      return {
        ...state,
        ItineraryImage: payload.data,
        ItineraryStatus: payload.status
      };
    case UploadexcelActions.CREATE_UPLOAD_EXCEL_FAILURE:
      // error = payload || { message: payload.message };//2nd one is network or server down errors
      return {
        ...state,
        errorMessage: payload
      };
    /* */

    default:
      return state;
  }
}
